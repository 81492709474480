/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  AccountPage.js
*/

import React, { useContext, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../../Auth';
import { fireStore } from '../../../Fire';
import { doc, onSnapshot } from 'firebase/firestore';
import NavBar from '../../nav-bar/NavBar';
import AccountForm from './components/AccountForm';
import UserReviews from './components/UserReviews';
import styles from './AccountPage.module.css';
import "./AccountPage.css";

export default function AccountPage(props) {
  const user = useContext(AuthContext);
  const [userData, setUserData] = useState(null);
  const [redirect, setRedirect] = useState({
    go: false,
    path: ''
  });

  useEffect(() => {
    if (!user) {
      setRedirect({go: true, path: "/"});
    } else {
      return onSnapshot(doc(fireStore, 'users', user.uid), document => {
        setUserData(document.data());
      })
    }
  }, [user])

  if (redirect.go) return <Navigate to={redirect.path} />

  return <>
    <NavBar />
    <div className={styles.page}>
      <div className={styles.content}>
        <Tabs
          defaultActiveKey="profile"
          className="mb-3 ap-tabs"
        >
          <Tab eventKey="profile" title="Profile">
            <div className={styles.formContainer}>
              <div className={styles.formWrapper}>
                {userData && <AccountForm user={user} userData={userData}/>}
              </div>
            </div>
          </Tab>
          <Tab eventKey="reviews" title="Reviews" className="ap-tab">
            <div className={styles.formContainer}>
              <div className={styles.formWrapper}>
                {userData && <UserReviews user={user} userData={userData}/>}
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  </>
};