/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  ReviewModal.js
*/
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, } from "react-bootstrap";
import { Link } from "react-router-dom";
import { makeTitleFromCamel, sleep } from "../../resources/Functions";
import { RecordVoiceOver, Timeline, ThumbUp, AccountCircle, Groups } from '@mui/icons-material';
import SmallStars from "../stars/small-stars/SmallStars";
import styles from './ReviewModal.module.css';
import { doc, getDoc } from "firebase/firestore";
import { fireStore } from "../../Fire";
import { AuthContext } from "../../Auth";
import appStyles from './../../AppStyles.module.css'
import { frequencies } from "../../resources/ReviewData";
import { institutions } from "../../resources/InstitutionData";

export default function RotationReviewModal(props) {
  const { 
    show, 
    onHide,
    rev,
    rotation,
    targetId,
    onProfile,
    handleDelete
  } = props;
  const user = useContext(AuthContext);
  const [intShow, setIntShow] = useState(show);

  const [target, setTarget] = useState(rotation);
  
  useEffect(() => {
    if (!rotation) {
      (async () => {
        const docRef = doc(fireStore, 'rotations', targetId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) setTarget(docSnap.data());
      })();
    }
  }, [])

  useEffect(() => {
    setIntShow(show);
  }, [show]);

  const handleHide = () => {
    setIntShow(false);
    sleep(500).then(() => onHide());
  };

  return <>
    <Modal
      show={intShow}
      onHide={handleHide}
      centered
    >
      <Modal.Header className={styles.header} closeButton>
        {target && <div>
          <h1 className={styles.h1}>{target.discipline}</h1>
          <h2 className={styles.h2}>{institutions[target.institutionID].name}</h2>
          <h3 className={styles.h3}>{target.location.city}, {target.location.state}</h3>
        </div>}
      </Modal.Header>
      <Modal.Body>
        <div className={styles.sec1}>
          <div className={styles.col1}>
            <div className={styles.authorWrapper}>
              <AccountCircle className={styles.authorIcon} sx={{fontSize: 20}}/>
              <h3 className={styles.author}>{rev.data().authorUsername}</h3>
            </div>
            <p className={styles.date}>{new Date(rev.data().date).toLocaleDateString()}</p>
            <div className={styles.stat}>
              <h4 className={styles.statLabel}>Days Per Week: </h4>
              <p className={styles.statVal}>{rev.data().daysPerWeek}</p>
            </div>
            <div className={styles.stat}>
              <h4 className={styles.statLabel}>Hours per Day: </h4>
              <p className={styles.statVal}>{rev.data().averageHoursPerDay}</p>
            </div>
          </div>
          <div className={styles.col2}>
            <div className={styles.reviewRating}>
              <ThumbUp className={styles.labelIcon} sx={{fontSize: 16}}/>
              <SmallStars val={rev.data().ratings.overallSatisfaction} />
            </div>
            <div className={styles.reviewRating}>
              <Timeline className={styles.labelIcon} sx={{fontSize: 16}}/>
              <SmallStars val={rev.data().ratings.clinicalTraining} />
            </div>
            <div className={styles.reviewRating}>
              <Groups className={styles.labelIcon} sx={{fontSize: 16}}/>
              <SmallStars val={rev.data().ratings.culture} />
            </div>
            <div className={styles.reviewRating}>
              <RecordVoiceOver className={styles.labelIcon} sx={{fontSize: 16}}/>
              <SmallStars val={rev.data().ratings.recLikelihood} />
            </div>
          </div>
        </div>
        <div className={styles.sec1_1}>
          <div className={styles.stat}>
            <h4 className={styles.statLabel}>Will apply for residency:</h4>
            <p className={styles.statVal}>{rev.data().applyForResidency}</p>
          </div>
          <div className={styles.stat}>
            <h4 className={styles.statLabel}>Rotation was required:</h4>
            <p className={styles.statVal}>{rev.data().wasRequired}</p>
          </div>
          <div className={styles.stat}>
            <h4 className={styles.statLabel}>Single site rotation:</h4>
            <p className={styles.statVal}>{rev.data().singleSite}</p>
          </div>
          <div className={styles.stat}>
            <h4 className={styles.statLabel}>Program is competitive:</h4>
            <p className={styles.statVal}>{rev.data().isCompetitive}</p>
          </div>
          <div className={styles.stat}>
            <h4 className={styles.statLabel}>Menial work required:</h4>
            <p className={styles.statVal}>{frequencies[rev.data().menialWork]}</p>
          </div>
        </div>
        <div className={styles.sec2}>
          {rev.data().positives && <div className={styles.flagsContainer}>
            <h4 className={styles.statLabel}>Positives:</h4>
            <div className={styles.flags}>
              {rev.data().positives.map(flag => (
                <div key={flag} className={styles.flag}>{makeTitleFromCamel(flag)}</div>
              ))}
            </div>
          </div>}
          {rev.data().negatives && <div className={styles.flagsContainer}>
            <h4 className={styles.statLabel}>Negatives:</h4>
            <div className={styles.flags}>
              {rev.data().negatives.map(flag => (
                <div key={flag} className={styles.flag+" "+styles.weakness}>{makeTitleFromCamel(flag)}</div>
              ))}
            </div>
          </div>}
        </div>
        <div className={styles.sec3}>
          <h2 className={styles.h2}>General Thoughts</h2>
          <p className={styles.review}>{rev.data().review}</p>
        </div>
      </Modal.Body>
      {(onProfile || rev.data().author === user.uid) && <Modal.Footer>
        {onProfile && <Button
          variant="link"
          className={appStyles.link}
          as={Link}
          to={`/rotations/${targetId}`}
        >See rotation page</Button>}
        <Button
          variant="danger"
          onClick={async () => {
            handleHide();
            await sleep(100);
            handleDelete('rotations', targetId, rev.id);
          }}
        >Delete</Button>
      </Modal.Footer>}
    </Modal>
  </>
};