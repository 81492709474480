/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  AccountForm.js
*/

import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import InfoPopover from '../../../info-popover/InfoPopover';
import { sleep, roundToMidnight } from '../../../../resources/Functions';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import styles from './AccountForm.module.css';
import appStyles from './../../../../AppStyles.module.css';
import { updateProfile } from 'firebase/auth';
import { doc, writeBatch, getDoc } from 'firebase/firestore';
import { fireFuncs, fireStore } from '../../../../Fire';
import { httpsCallable } from 'firebase/functions';

export default function AccountForm(props) {
  const {
    user,
    userData
  } = props;

  const [changed, setChanged] = useState(false);
  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(userData);
  const [errors, setErrors] = useState({
    dob: ""
  });

  /* 
    used to validate all form fields and handle error messaging
    prop: str - state/field prop to validate
    criteria: func - called to check prop
    errorMessage: str - error to be shown if !criteria()
  */
  const validate = (prop, criteria, errorMessage) => {
    const message = criteria() ? '' : errorMessage;
    document.getElementById(prop).setCustomValidity(message);
    setErrors({...errors, [prop]: message});
  }

  const handleChange = prop => {
    return e => {
      setChanged(true);
      if (prop === 'gradYear') {
        if (e.target.value.length <= 4) {
          const currYear = new Date().getFullYear();
          validate(
            prop,
            () => /^\d\d\d\d$/g.test(e.target.value) && 
              // Only accept grad years from 100 years ago and 10 years in the future
              parseInt(e.target.value) <= currYear + 10 &&
              parseInt(e.target.value) >= currYear - 100,
            `Must enter a valid grad year.`
          )
          setState(prev => ({...prev, [prop]: e.target.value}))
        }
      } else if (prop === 'dob') {
        validate(
          prop,
          () => true,
          ''
        )
        setState(prev => ({...prev, [prop]: roundToMidnight(e)}))
      } else {
        setState(prev => ({...prev, [prop]: e.target.value}))
      }
    }
  }

  const handleCheckChange = (prop) => {
    setState({ ...state, [prop]: !state[prop] });
    setChanged(true);
  };

  const checkUsername = async () => {
    const checkUsername = httpsCallable(fireFuncs, 'checkUsername');
    const result = await checkUsername({username: state.username});
    const valid = result.data.valid || state.username === userData.username;
    console.log("RESULT", result);
    validate('username', () => valid, 'Username already exists');
    return valid;
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const form = e.currentTarget;
    const validUsername = await checkUsername();
    const valid = form.checkValidity() && validUsername;
    setValidated(true);
    if (!valid) {
      e.stopPropagation();
    } else {
      // submitting
      setSubmitting(true);
      const batch = writeBatch(fireStore);
      // if username changed, change display name
      if (state.username !== userData.username) {
        await updateProfile(user, {
          displayName: state.username
        }).catch((e) => console.error("Error updating profile", e))
      }
      // create new userDoc
      const userDoc = state;
      const userRef = doc(fireStore, 'users', user.uid);
      batch.set(userRef, userDoc);
      // if mailingList pref changed
      if (state.mailingList !== userData.mailingList) {
        const mailRef = doc(fireStore, 'mailingList', user.uid);
        if (state.mailingList) {
          // add pref to mailingList
          batch.set(mailRef, {
            dateAdded: Date.now(),
            email: state.email,
          });
        } else {
          // delete prefs from mailingList
          batch.delete(mailRef);
        }
      }
      await batch.commit().catch(error => {
        console.log("Error committing user batch", error);
      })
      setSubmitting(false);
      setValidated(false);
    }
  }

  return <>
    <Form
      noValidate
      onSubmit={handleSubmit}
      validated={validated}
    >
      <Form.Group className="mb-3">
        <Form.Label>Username</Form.Label>
        <Form.Control
          required
          value={state.username}
          onChange={handleChange('username')}
          type="text"
          id="username"
        />
        <Form.Control.Feedback type='invalid'>{errors.username}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          required
          value={state.email}
          onChange={handleChange('email')}
          type="text"
          id="email"
          disabled
        />
        <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>School</Form.Label>
        <Form.Control
          value={state.school}
          onChange={handleChange('school')}
          type="text"
          id="email"
          disabled
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check
          className={appStyles.checkBox}
          type="checkbox"
          checked={state.mailingList}
          onChange={() => handleCheckChange("mailingList")}
          label="Mailing list"
        />
        <Form.Text muted>
          We'll give you a heads up when new updates and features are
          available. <br />
          We won't spam you.
        </Form.Text>
      </Form.Group>
      <Form.Group className="mb-3">
        <div className={styles.labelWrapper}>
          <Form.Label>Additional Information</Form.Label>
          <InfoPopover
            title="What's this for"
            body={<>
              This information will be attached to your reviews if present here on your profile. It's helpful for potential 
              rotation seekers to see if they can identify with a review author demographically when considering a rotation 
              or preceptor.
            </>}
            top="3px"
            color="#6c757d"
          />
        </div>
        <Form.Select
          value={state.gender}
          onChange={handleChange('gender')}
        >
          <option value="0">Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Prefer not to say</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Grad Year</Form.Label>
        <Form.Control
          value={state.gradYear}
          onChange={handleChange('gradYear')}
          type="number"
          id="gradYear"
          placeholder="YYYY"
        />
        <Form.Control.Feedback type='invalid'>{errors.gradYear}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Date of Birth</Form.Label>
        <DatePicker
          title="goal-date-picker"
          selected={new Date(state.dob || new Date(1995, 0, 1).getTime())}
          onChange={handleChange('dob')}
          className={
            styles.datePicker + " " + 
            (validated && errors.dob.length > 0 ? styles.datePickerInvalid : "") + " " + 
            (validated && errors.dob.length === 0 ? styles.datePickerValid : "")
          }
          id="dob"
        />
        {validated && errors.dob.length > 0 && <Form.Text style={{color: '#dc3545'}}>{errors.dob}</Form.Text>}
      </Form.Group>
      <Button 
        type="submit" 
        variant="secondary"
        className={appStyles.primaryBtn}
        disabled={!changed || submitting}
      >
        {submitting ? <Spinner animation="border" size="sm"/> : "Save"}
      </Button>
    </Form>
  </>
};