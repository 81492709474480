import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import $ from 'jquery';
import { sleep } from '../../resources/Functions';
import './Alert.css';

export const emptyAlert = {
    show: false,
    confirmationNeeded: false,
    actionNeeded: false,
    title: '',
    message: '',
    variant: 'secondary',
    confirmText: '',
    confirmCallback: () => {},
    cancelCallback: () => {}
}

/*
    Props - (1) if action needed - (2) whether or not action needed
    -----------
    actionNeeded: boolean indicating if confirmation action is required
    confirmationNeeded: boolean indicating if written confirmation is required
    (2) show: boolean indicating if the modal should be shown
    (2) title: title for the alert modal
    (2) message: message for the alert modal
    (2) confirmCallback: function that is called when confirmation button is clicked
    (1) variant: indicates color of confirmation button
    (1) confirmText: text that goes in the confirm button
    (1) cancelCallback: function that is called when action is cancelled
*/
const Alert = props => {
    const { 
        show, 
        title, 
        message, 
        confirmCallback, 
        variant, 
        confirmText, 
        cancelCallback,
        confirmationNeeded,
        actionNeeded,
        confirmCode
    } = props;
    const [confirmField, setConfirmField] = useState('');
    const [intShow, setIntShow] = useState(show)

    // update internal show based on props
    useEffect(() => {
        setIntShow(show);
    }, [show])

    // close internally, wait a bit and then pass info to parent
    const handleClose = () => {
        setIntShow(false);
        sleep(500).then(() => {
            confirmCallback();
        })
    }

    const handleCancel = () => {
        setIntShow(false);
        sleep(500).then(() => {
            cancelCallback();
        })
    }

    useEffect(() => {
        sleep(300).then(() => {
            // Listen for enter key to close modal
            $(document).keyup(event => {
                if (event.which === 13 && show && !confirmationNeeded && !actionNeeded) {
                    handleClose();
                }
            });
        })
    }, [])

    const handleConfirmCodeChange = event => {
        setConfirmField(event.target.value)
    }

    if (confirmationNeeded) {
        return (
            <div data-testid="alert-modal">
                <Modal 
                    centered 
                    show={intShow} 
                    onHide={cancelCallback} 
                    backdrop="static" 
                    keyboard={false}
                    className="alert-modal"
                >
                    <Modal.Header >
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {message}
                        <p className="alert-confirm-code-instructions">
                            Type '{confirmCode}' to delete the group.
                        </p>
                        <input type="text" className="form-control" placeholder={confirmCode} onChange={handleConfirmCodeChange} value={confirmField} />
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button variant="secondary" onClick={() => {
                                setConfirmField('');
                                cancelCallback();
                            }}>
                                Cancel
                            </Button>
                        </div>
                        <div>
                            <Button variant={variant} onClick={handleClose} disabled={confirmField !== confirmCode}>
                                {confirmText}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    } else if (actionNeeded) {
        return (
            <div data-testid="alert-modal">
                <Modal 
                    centered 
                    show={intShow} 
                    onHide={cancelCallback} 
                    backdrop="static" 
                    keyboard={false}
                    className="alert-modal"
                >
                    <Modal.Header >
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{message}</Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button variant="secondary" onClick={handleCancel}>
                                Cancel
                            </Button>
                        </div>
                        <div>
                            <Button variant={variant} onClick={handleClose}>
                                {confirmText}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    } else {
        return (
            <div data-testid="alert-modal" className="alert-wrapper-yeah">
                <Modal 
                    centered 
                    show={intShow} 
                    onHide={handleClose}
                    className="alert-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{message}</Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default Alert
