import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics";

// will use firebase emulator if set to true
var useEmulator = true;

// Set the configuration for your app
// TODO: Replace with your project's config object
const firebaseConfig = {
  apiKey: "AIzaSyATSp2iifJ7SdKRPTybzlBNCwesNYgTGqk",
  authDomain: "med-student-rating.firebaseapp.com",
  projectId: "med-student-rating",
  storageBucket: "med-student-rating.appspot.com",
  messagingSenderId: "997758859706",
  appId: "1:997758859706:web:1a8a634184fcc8f3130583",
  measurementId: "G-GC3NCPP9EC"
};
const app = initializeApp(firebaseConfig);

// Get a reference to the database service
export const fireAuth = getAuth(app);
export const fireStore = getFirestore(app);
export const fireFuncs = getFunctions(app);
export const fireAnalytics = getAnalytics(app);
if (window.location.hostname === "localhost" && useEmulator) {
  connectAuthEmulator(fireAuth, "http://localhost:9099");
  connectFirestoreEmulator(fireStore, 'localhost', 8001);
  connectFunctionsEmulator(fireFuncs, "localhost", 5001);
}
