/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  VerifyEmail.js
*/

import React, { useContext, useEffect, useState } from 'react';
import styles from './VerifyEmail.module.css';
import appStyles from './../../../../../AppStyles.module.css';
import { AuthContext } from '../../../../../Auth';
import { Button, Spinner } from 'react-bootstrap';
import { sendEmailVerification, updateProfile } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { fireFuncs } from '../../../../../Fire';

const DEV = window.location.hostname === 'localhost';
// const DEV = false;

export default function VerifyEmail(props) {
  const {
    handleDone
  } = props;
  const user = useContext(AuthContext);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.emailVerified) {
        handleDone();
      }
    }
  }, [user])

  const sendEmail = () => {
    if (user) {
      setSending(true);
      if (!DEV) {
        const customSendEmailVerification = httpsCallable(fireFuncs, 'sendVerificationEmail');
        customSendEmailVerification().then((result) => {
          console.log("Success", result.data.message);
          setSending(false);
        }).catch(error => {
          console.warn(error.code);
          console.warn(error.message);
          setSending(false);
        })
      }
    }
  }

  return <>
    <div className={styles.main}>
      <h1 className={styles.h1}>Welcome!</h1>
      <p className={styles.message}>We sent you an email to verify your account. Check your spam folder, click the link, and refresh this page to continue.</p>
      <div className={styles.btnWrapper}>
        {!DEV && <Button variant="secondary" onClick={sendEmail} className={appStyles.primaryBtn}>
          {sending ? <Spinner animation="border" size="sm" /> : "Send Again"}
        </Button>}
      </div>
    </div>
  </>
};