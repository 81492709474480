import { useRef, useEffect } from "react";

export const copyStringToClipboard = (str) => {
  // Create new element
  var el = document.createElement("textarea");
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand("copy");
  // Remove temporary element
  document.body.removeChild(el);
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const toNote = (n) => {
  let base = n % 12;
  switch (base) {
    case 0:
      return "C";
    case 1:
      return "Db";
    case 2:
      return "D";
    case 3:
      return "Eb";
    case 4:
      return "E";
    case 5:
      return "F";
    case 6:
      return "Gb";
    case 7:
      return "G";
    case 8:
      return "Ab";
    case 9:
      return "A";
    case 10:
      return "Bb";
    case 11:
      return "B";
    default:
      return "";
  }
};

// used for chords.ts naming conventions
export const formatChord = (str) => {
  let M = / Major */i;
  let m = / Minor */i;
  let hdim = / half-diminished */i;
  let dim = / diminished */i;
  let d = / dominant */i;
  let s = / suspended */i;
  if (M.test(str)) {
    return str.replace(M, "maj");
  } else if (m.test(str)) {
    return str.replace(m, "m");
  } else if (hdim.test(str)) {
    return str.replace(hdim, "ø");
  } else if (dim.test(str)) {
    return str.replace(dim, "dim");
  } else if (d.test(str)) {
    return str.replace(d, "");
  } else if (s.test(str)) {
    return str.replace(s, "sus");
  } else {
    return str;
  }
};

export const keyToString = (key) => {
  let str = key.toString();
  if (str.length === 2) {
    str = "0" + str;
  }
  return str;
};

// https://www.freecodecamp.org/news/how-to-capitalize-words-in-javascript/
export const capitalizeEachLetter = (str) => {
  return str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

const dayIndeces = {
  0: "",
  1: "Sunday 1",
  2: "Monday",
  3: "Tuesday",
  4: "Wednesday",
  5: "Thursday",
  6: "Friday",
  7: "Sabbath",
  8: "Sunday 2",
};

export const iToDay = (i) => {
  return dayIndeces[i];
};

const dayAbrvIndeces = {
  0: "",
  1: "S1",
  2: "M",
  3: "Tu",
  4: "W",
  5: "Th",
  6: "F",
  7: "Sab",
  8: "S2",
};

export const iToDayAbrv = (i) => {
  return dayAbrvIndeces[i];
};

export const makeID = (str) => {
  str = str.toLowerCase();
  return str.replace(/\s/g, "_");
};

export const makeTitle = (str) => {
  str = str.replace(/_/g, " ");
  return capitalizeEachLetter(str);
};

// thanks chatGPT
export const makeTitleFromCamel = (str) => {
  return str
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (char) => char.toUpperCase());
}

export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

const finishedMatch = /^(\d\d):(\d\d) ([AP]M)$/g;
const militaryMatch = /^([01]\d|2[0-3]):([0-5]\d)$/g;
// str is of format 12:30 AM etc
export const toMilitaryTime = (str) => {
  let hours = parseInt(str.replace(finishedMatch, "$1"));
  let minutes = str.replace(finishedMatch, "$2");
  let dayHalf = str.replace(finishedMatch, "$3");
  if (dayHalf === "AM") {
    hours = hours === 12 ? 0 : hours;
  } else {
    // PM
    hours = hours !== 12 ? hours + 12 : hours;
  }
  hours = hours.toString();
  hours = hours.length === 1 ? "0" + hours : hours;
  return hours + ":" + minutes;
};

// str is of format 13:30 etc (military time)
export const toNormalTime = (str) => {
  if (militaryMatch.test(str)) {
    let hours = parseInt(str.replace(militaryMatch, "$1"));
    let minutes = str.replace(militaryMatch, "$2");
    let dayHalf = "";
    if (hours < 12) {
      dayHalf = "AM";
      hours = hours === 0 ? "12" : hours;
    } else {
      dayHalf = "PM";
      hours = hours > 12 ? hours - 12 : hours;
    }
    hours = hours.toString();
    hours = hours.length === 1 ? "0" + hours : hours;
    return hours + ":" + minutes + " " + dayHalf;
  } else {
    return "";
  }
};

export const militaryToMs = (str) => {
  const durationMatch = /^(\d{1,2}):([0-5]\d)$/g;
  if (durationMatch.test(str)) {
    let hours = parseInt(str.replace(durationMatch, "$1"));
    let minutes = parseInt(str.replace(durationMatch, "$2"));
    return minutes * 60000 + hours * 3600000;
  } else {
    return "";
  }
};

export const removeLeadingZero = (str) => {
  let regex = /^(\d+)(:.+)$/g;
  let hour = parseInt(str.replace(regex, "$1"));
  return hour.toString() + str.replace(regex, "$2");
};

export const getUserArray = () => {
  return [
    "Base Camp",
    "Base Camp Staff",
    "Equestrian",
    "Extreme Camp",
    "Wakeboard Camp",
    "Disciple Trek",
    "Survival Camp",
  ];
}

export const getUserObject = (users = {}) => {
  let userArray = Object.values(users);
  let userObj = {};
  getUserArray().forEach((user) => (userObj[user] = userArray.includes(user)));
  return userObj;
}

export const getDayIndexArray = () => {
  return ["1", "2", "3", "4", "5", "6", "7", "8"];
}

export const getDayIndexObject = (days = {}) => {
  let dayArr = Object.values(days);
  let dayObj = {};
  getDayIndexArray().forEach((day) => (dayObj[day] = dayArr.includes(day)));
  return dayObj;
}

/*
  str - str to be truncated
  count - max number of characters to show without being truncated
*/
export const truncateText = (str, count) => {
  if (str.length <= count) {
    return str;
  } else {
    return str.slice(0, count - 1) + "..."
  }
}

// get the font size of flags based on their frequency and the most frequent flag's frequency
export const getFlagSize = (count, flags) => {
  // get the frequency of the most frequent flag
  const maxCount = Math.max(...Object.values(flags));
  // constants
  const minSize = 0.7;
  const maxSize = 1;
  const range = maxSize - minSize;
  const increment = range / maxCount;
  // compute size based on flag's count
  let size = minSize + (count * increment);
  if (size > 1.5) {
    size = 1.5;
  }
  return size.toString() + "rem";
}

/*
  returns the number of decimal places a number has
*/
export const countDecimalPlaces = (number) => {
  if (typeof number !== "number") {
    throw new Error("Invalid input. Please provide a valid number.");
  }
  const decimalMatch = number.toString().match(/\.(\d+)/);

  if (decimalMatch && decimalMatch[1]) {
    return decimalMatch[1].length;
  } else {
    return 0;
  }
}

/*
  returns number type
*/
export const roundAccurately = (number, decimalPlaces = 7) => {
  return Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
}

/*
  returns string that has trailing zeros to match number of decimal places if necessary
*/
export const roundAccuratelyString = (number, decimalPlaces = 7) => {
  console.log("NUMBER", number);
  let rounded = Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
  let roundedCount = countDecimalPlaces(rounded);
  if (roundedCount < decimalPlaces) {
    rounded = rounded.toString();
    if (roundedCount === 0) {
      rounded = rounded + ".";
    }
    for (let i = 0; i < decimalPlaces - roundedCount; i++) {
      rounded = rounded + "0";
    }
  }
  return rounded;
}

// Accepts a date object and returns a date object with the same day, but at midnight
export const roundToMidnight = date => {
  if (date) {
    const rounded = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    return rounded.getTime();
  } else return undefined;
} 

export const camelCaseFromArray = arr => {
  let str = '';
  arr.forEach((s, i) => {
    if (i === 0) {
      str = str + s;
    } else {
      str = str + capitalizeEachLetter(s);
    }
  });
  return str;
}

/**
 * getPostDate
 * 
 * This function takes a value indicating the number of months to push
 * back the post date and returns a js Date object of that future post
 * date.
 * 
 * @param {int} monthsAway    Indicates how many months away the return date should be
 * @returns {Date}            Date on which to post based on number of months
 */
export const getPostDate = monthsAway => {
  // make sure key param is valid
  if (monthsAway < 0 || monthsAway > 3) {
    throw new Error("Key parameter to func getPostDate should be between 0 and 3");
  }

  const currentDate = new Date();
  const futureDate = new Date(currentDate);

  // Calculate the future month by adding 'monthsAway' to the current month
  futureDate.setMonth(currentDate.getMonth() + monthsAway);

  return futureDate;
}

/**
 * getMonthAbrv
 * 
 * Get month abbreviation based on an index
 * 
 * @param {*} index     indicates month to return
 * @returns {string}    Abbreviation of month
 */
export const getMonthAbrv = index => {
  switch(index) {
    case 0:
      return "Jan";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "Jun";
    case 6:
      return "Jul";
    case 7:
      return "Aug";
    case 8:
      return "Sep";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    case 11:
      return "Dec";
  }
}