/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  PreceptorReview.js
*/

import React, { useContext, useEffect, useState } from 'react';
import { doc, getDoc, setDoc, updateDoc, writeBatch } from 'firebase/firestore';
import { fireStore } from '../../../../Fire';
import FlagSelector from '../../../flag-selector/FlagSelector';
import { PreceptorReviewFlags, preceptorTopStrengths } from '../../../../resources/ReviewData';
import { Form, Button, Spinner } from 'react-bootstrap';
import uuid from 'react-uuid';
import { AuthContext } from '../../../../Auth';
import { Section1, Section2, Section3, Section4, Section5, Section6 } from './PreceptorForms';
import styles from './../ReviewPage.module.css';
import appStyles from './../../../../AppStyles.module.css';
import { institutions } from '../../../../resources/InstitutionData';
import { getPostDate } from '../../../../resources/Functions';

export default function PreceptorReview(props) {
  const {
    docID,
    showGuidelines,
    afterSubmit
  } = props;
  const [submitting, setSubmitting] = useState(false);
  const [step, setStep] = useState(1);
  // multiply values by (100 / max) to get slider position
  const [state, setState] = useState({
    flags: [],
    strengths: [],
    weaknesses: [],
    review: '',
    improvement: '',
    expectations: '',
    overallSatisfaction: 0,
    difficultyOfExpectations: 0,
    recommend: 0,
    chooseAgain: '0',
    rotation: 0,
    whenToPost: 0
  });
  const user = useContext(AuthContext);

  const [document, setDocument] = useState(null);

  useEffect(() => {
    (async () => {
      const docRef = doc(fireStore, "preceptors", docID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setDocument(docSnap.data());
      } else {
        console.error("This rotation id does not exist");
      }
    })()
  }, [])

  const incrementStep = () => {
    setStep(prev => prev + 1);
  }

  const decrementStep = () => {
    if (step > 1) setStep(prev => prev - 1);
  }

  const handleSubmit = async (publish = true) => {
    setSubmitting(true);
    const id = uuid();
    const date = Date.now();
    try {
      const batch = writeBatch(fireStore);
      const revRef = doc(fireStore, `preceptors/${docID}/reviews`, id);
      batch.set(revRef, {
        author: user.uid,
        authorUsername: user.displayName,
        // averageHoursPerDay: state.averageHoursPerDay / 8,
        date: date,
        // daysPerWeek: state.daysPerWeek / 14,
        flags: state.flags,
        strengths: state.strengths,
        weaknesses: state.weaknesses,
        published: publish,
        ratings: {
          difficultyOfExpectations: state.difficultyOfExpectations,
          overallSatisfaction: state.overallSatisfaction,
          recLikelihood: state.recommend
        },
        rotation: {
          id: state.rotation,
          discipline: document.rotations[state.rotation].discipline,
          institutionID: document.rotations[state.rotation].institutionID,
        },
        chooseAgain: state.chooseAgain,
        review: state.review,
        improvement: state.improvement,
        expectations: state.expectations,
        type: 'preceptor',
        targetId: docID,
        postOnDate: state.whenToPost === 0 ? date : getPostDate(state.whenToPost).getTime(),
      });
      await batch.commit();
      setSubmitting(false);
      afterSubmit();
    } catch (error) {
      console.error("ERROR SAVING REVIEW");
      console.error(error);
    }
  }

  return <>
    {document && <div className={styles.main}>
      <div className={styles.header}>
        <h1 className={styles.h1}>{document.fName} {document.lName}</h1>
        {document.rotations && 
          <h2 className={styles.h2}>{institutions[Object.values(document.rotations)[0].institutionID].name}</h2>
        }
        {/* <h3 className={styles.h3}>{document.location.city}, {document.location.state}</h3> */}
        <a 
          variant="link" 
          className={appStyles.link}
          onClick={showGuidelines}
        >See our review guidelines</a>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <div className={styles.forms}>
            {step === 1 && <Section1
              state={state}
              setState={setState}
              onSubmit={incrementStep}
              decrementStep={decrementStep}
              doc={document}
              showGuidelines={showGuidelines}
            />}
            {step === 2 && <Section2
              state={state}
              setState={setState}
              onSubmit={incrementStep}
              decrementStep={decrementStep}
              doc={document}
              showGuidelines={showGuidelines}
            />}
            {step === 3 && <Section3
              state={state}
              setState={setState}
              onSubmit={incrementStep}
              decrementStep={decrementStep}
              doc={document}
              showGuidelines={showGuidelines}
            />}
            {step === 4 && <Section4
              state={state}
              setState={setState}
              onSubmit={incrementStep}
              decrementStep={decrementStep}
              doc={document}
              showGuidelines={showGuidelines}
            />}
            {step === 5 && <Section5
              state={state}
              setState={setState}
              onSubmit={incrementStep}
              decrementStep={decrementStep}
              doc={document}
              showGuidelines={showGuidelines}
            />}
            {step === 6 && <Section6
              state={state}
              setState={setState}
              onSubmit={handleSubmit}
              decrementStep={decrementStep}
              submitting={submitting}
              doc={document}
              showGuidelines={showGuidelines}
            />}
          </div>
        </div>
      </div>
    </div>}
  </>
};