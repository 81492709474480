/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  AboutPage.js
*/

import React, { useEffect, useState } from 'react';
import NavBar from './../../nav-bar/NavBar';
import styles from './AboutPage.module.css';
import Footer from '../../footer/Footer';

export default function AboutPage() {

  return <>
    <div className={styles.container}>
      <div className={styles.navWrapper}>
        <NavBar />
      </div>
      <div className={styles.page}>
        <div className={styles.cover}>
          <h1 className={styles.title}>About Us</h1>
        </div>
        <div className={styles.imgContainer}>
          <div className={styles.imgFlex}>
            <div className={styles.imgWrapper}>
              <img className={styles.img} src="https://i.imgur.com/YopueIL.jpg" />
              <h4 className={styles.caption}>Ricky</h4>
            </div>
            <div className={styles.imgWrapper}>
              <img className={styles.img} src="https://i.imgur.com/sxrX0M4.png" />
              <h4 className={styles.caption}>Luke</h4>
            </div>
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            {/* <p className={styles.p+" "+styles.bold}>Our Mission</p> */}
            <p className={styles.p}>
              At inCLINICALS, we understand the transformative role clinical rotations play in shaping the careers of aspiring healthcare professionals, just like you. These experiences can vary greatly, from highly positive to somewhat challenging, and it was our own journey, led by Dr. Ricky, a former medical student, that inspired the creation of this platform.
            </p>
            <p className={styles.p}>
              Our mission is clear: to provide you with a valuable resource for navigating your clinical rotations effectively. We believe having a clear understanding of what to expect during your rotations can greatly influence your choice of programs or the type of residency you aspire to pursue.
            </p>
            <p className={styles.p}>
              At inCLINICALS, we have built a supportive community where healthcare students can openly share their experiences and insights, empowering each other to make informed decisions. Our goal is to ensure that you have the information you need at your fingertips to excel in your professional career.
            </p>
            <p className={styles.p}>
              We've designed inCLINICALS with you in mind, aiming to bring out the best in your journey towards becoming a healthcare professional. We sincerely hope you find our platform useful as you embark on this rewarding path.
            </p>
            <p className={styles.p}>As always, we're here to support you every step of the way.</p>
            <p className={styles.p+" "+styles.bold}>Cheers,</p>
            <p className={styles.p}>Luke and Ricky</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </>
};