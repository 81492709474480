/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Auth.js
*/
import React, { useEffect, useState } from 'react';
import { fireAuth } from './Fire';
import { onAuthStateChanged } from 'firebase/auth';
import Loading from './components/pages/loading/Loading';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children, value }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    // this mounted variable trick solves the 'Can't perform a react state update
    // on an unmounted component...' error. The solution was found at:
    // https://www.debuggr.io/react-update-unmounted-component/
    fireAuth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user)
      } else {
        setCurrentUser(false);
      }
      // setPending(false)
    });
  }, []);

  if (currentUser === null) return <Loading />

  return (
    <AuthContext.Provider
      value={currentUser}
    >
      {children}
    </AuthContext.Provider>
  );
};
