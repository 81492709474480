export const placeholders = {
  rotationReview: `During my clinical rotation in internal medicine at XYZ hospital, the staff were welcoming and inclusive, and I felt like part of the team. The variety of cases and patients provided excellent learning opportunities, and the attending physicians and residents were knowledgeable and approachable.

The morning report was a highlight of the rotation, as it offered insight into interesting cases and provided valuable feedback. Working with the hospitalist team was also a great experience, as it allowed me to learn about the admission and discharge processes.

However, the patient load was sometimes overwhelming, and I struggled to balance the workload with the rotation's demands. Additionally, more structured teaching sessions would have been beneficial.

Overall, my clinical rotation in internal medicine at XYZ hospital was a valuable learning experience, and I would recommend it to other medical students. While there is always room for improvement, the staff's dedication to patient care and their knowledge make it a highly recommended rotation.`,
  preceptorImprovement: `Dr. Smith could focus on providing more structured teaching sessions for students. This could include reviewing specific cases in-depth, offering mini-lectures on relevant topics, or setting aside time for questions and discussion. Additionally, Dr. Smith could seek feedback from the students to understand their learning needs and tailor the rotation accordingly. Encouraging students to take on more responsibility in patient care under supervision is also valuable, as it allows for hands-on learning opportunities. Finally, Dr. Smith could model effective communication with patients and their families, as this is an important skill for medical professionals.`,
  preceptorExpectations: `⁃Be on time
-Expected me to take ownership of patients
⁃Pre-round on my patients
⁃Come up with a plan for each patient
-Be a good team member`,
  preceptorReview: `Dr. Smith provided a lot of clinical exposure and encouraged us to take an active role in patient care. I appreciated that Dr. Smith was available to answer any questions we had and provided feedback on our progress. However, one area for improvement could be to provide more structured teaching sessions. Although I was able to learn a lot through patient encounters, it would have been beneficial to have more guidance in certain areas of internal medicine. Additionally, it would have been helpful to have a clearer understanding of the expectations for the rotation at the beginning of the experience. Overall, I feel that I gained a lot of valuable clinical experience and learned a lot from Dr. Smith. With some minor improvements, this clinical rotation could be even more successful for future students.`
}