import React, { useContext, useEffect, useState } from 'react';
import NavBar from '../../nav-bar/NavBar';
import Banner from './components/Banner';
import styles from "./Homepage.module.css";
import SearchWindow from './components/search-window/SearchWindow';
import { AuthContext } from '../../../Auth';
import { doc, getDoc, increment, setDoc, updateDoc } from 'firebase/firestore';
import { fireStore } from '../../../Fire';
import { sleep } from '../../../resources/Functions';
import Footer from '../../footer/Footer';
import Mission from './components/Mission';
import Features from './components/Features';
import Faq from './components/Faq';

export default function Homepage() {
  const user = useContext(AuthContext);
  const [showBetaModal, setShowBetaModal] = useState(false);

  useEffect(() => {
    if (user) {
      (async () => {
        const userRef = doc(fireStore, 'userVisitCounts', user.uid);
        try {
          await updateDoc(userRef, {
            visitCount: increment(1)
          });
        } catch(error) {
          if (error.code === 'not-found') {
            await setDoc(userRef, {visitCount: 1});
          }
        }
      })();
    }
  }, [user])

  useEffect(() => {
    if (user) {
      (async () => {
        const userRef = doc(fireStore, 'userVisitCounts', user.uid);
        await sleep(1000);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          console.log("COUNT", docSnap.data().visitCount);
          const visitCounts = [5, 10, 15];
          if (visitCounts.includes(docSnap.data().visitCount) && !docSnap.data().formVisited) {
            // removing the beta language temporarily
            // setShowBetaModal(true);
          }
        }
      })();
    }
  }, [user]);

  return <>
    <div className={styles.bannerContainer}>
      <Banner />
      <div className={styles.navWrapper}>
        <NavBar 
          showBetaModal={showBetaModal}
        />
      </div>
    </div>
    <div className={styles.page}>
      <div className={styles.content}>
        <div className={styles.searchWindowWrapper}>
          <SearchWindow />
        </div>
        <Mission />
        <Features />
        <Faq />
      </div>
      <Footer />
    </div>
  </>
}