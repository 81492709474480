/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  PreceptorsView.js
*/

import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Form, Button, Spinner } from 'react-bootstrap';
import { fireStore } from '../../../../../../Fire';
import { stateAbrvs } from '../../../../../../resources/StateAbrvs';
import SmallStars from '../../../../../stars/small-stars/SmallStars';
import { camelCaseFromArray, roundAccurately, roundAccuratelyString } from '../../../../../../resources/Functions';
import styles from './DbView.module.css';
import { institutions } from '../../../../../../resources/InstitutionData';

const Preceptor = props => {
  const {
    key,
    doc
  } = props;

  const [redirect, setRedirect] = useState({
    go: false,
    path: ""
  })

  const handleClick = () => {
    setRedirect({
      go: true,
      path: "/preceptors/" + doc.id
    })
  }

  if (redirect.go) return <Navigate to={redirect.path} />

  let rotations = {};
  Object.values(doc.rotations).forEach(rot => {
    const instName = institutions[rot.institutionID].name
    if (rotations[instName]) {
      rotations[instName].push(rot.discipline)
    } else {
      rotations[instName] = [rot.discipline];
    }
  })

  return <>
    <div key={key} className={styles.docContainer}>
      <button
        className={styles.doc}
        onClick={handleClick}
      >
        <div className={styles.docText}>
          <h1 className={styles.h1}>{doc.fName} {doc.lName}</h1>
          {Object.keys(rotations).map((inst) => (
            <div key={inst} className="ps-rot-group">
              <h2 className={styles.h2}>{inst}</h2>
              {rotations[inst].map((disc) => (
                <h3 key={disc} className={styles.h3+" "+styles.indent}>{disc}</h3>
              ))}
            </div>
          ))}
        </div>
        <div className={styles.starsContainer}>
          {doc.stats && <SmallStars val={roundAccuratelyString((doc.stats.ratings || {}).overallSatisfaction, 1)} variant="single"/>}
        </div>
      </button>
    </div>
  </>
}

export default function PreceptorsView(props) {
  const {
    discs,
    states,
    inst
  } = props;

  const [preceptors, setPreceptors] = useState(null);

  useEffect(() => {
    (async () => {
      const precsRef = collection(fireStore, 'preceptors');
      // const q = discs.length > 0 ? query(precsRef, where('disciplines', 'array-contains-any', discs)) : precsRef;
      let q;
      let fields = [];
      let value = '';
      if (discs.length > 0) {
        fields.push('disciplines');
        value = discs[0];
      }
      if (states.length > 0) {
        fields.push('states');
        value = value + (value.length > 0 ? ";" : "") + states[0];
      }
      if (inst.length > 0) {
        fields.push('institutions');
        value = value + (value.length > 0 ? ";" : "") + inst;
      }
      const field = camelCaseFromArray(fields);
      q = value.length > 0 ? query(precsRef, where(field, 'array-contains', value)) : precsRef;
      const querySnapshot = await getDocs(q);
      setPreceptors(querySnapshot.docs);
    })();
  }, [discs, states, inst]);  

  return <>
    <div>
      <div className={styles.container}>
        {!preceptors && <div className={styles.loadingWrapper}>
          <Spinner animation="grow" variant="secondary" size="sm"/>
          <Spinner animation="grow" variant="secondary" size="sm"/>
          <Spinner animation="grow" variant="secondary" size="sm"/>  
        </div>}
        {preceptors && <div className={styles.docsContainer}>
          {preceptors.map((doc) => (
            <Preceptor key={doc.id} doc={doc.data()} />
          ))}  
        </div>}
        {(preceptors && (preceptors || []).length === 0) && <div>
          {props.children}  
        </div>}
      </div>
    </div>
  </>
};