/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  InfoPopover.js
*/
import React from 'react';
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import "./InfoPopover.css";

export default function InfoPopover(props) {
  const {
    title,
    body,
    top,
    color
  } = props;

  return <>
    <OverlayTrigger
      trigger="click"
      placement='bottom'
      overlay={
        <Popover className="ip-question-popover">
          <Popover.Header as="h3">{title}</Popover.Header>
          <Popover.Body>
            {body}
          </Popover.Body>
        </Popover>
      }
    >
      <Button 
        className="ip-question-btn" 
        style={{
          top: top,
          borderColor: color
        }} 
        variant="secondary"
      >
        <i className="bi-question" style={{color: color}}/>
      </Button>
    </OverlayTrigger>
  </>
};