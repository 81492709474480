/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  SmallStars.js
*/

import React, { useEffect, useState } from 'react';
import Star from '../Star';
import styles from './SmallStars.module.css';

export default function SmallStars(props) {
  const {
    val,
    variant
  } = props;

  const color1 = '#e2cf00';
  const color2 = '#eae5cd';
  const scale = 1;
  const stars = [1, 2, 3, 4, 5]

  if (variant === "single") {
    return <>
      <div className={styles.singleContainer}>
        <Star color={color1} scale={scale} />
        <div className={styles.starCount}>{val}</div>
      </div>
    </>
  }

  return <>
    <div className={styles.container}>
      {stars.map((star) => (
        <Star key={star} color={val >= star ? color1 : color2} scale={scale} />
      ))}
    </div>
  </>
};