/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  PromptPage.js
*/

import { doc, getDoc, query, where, getDocs, collection } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { fireStore } from '../../../../Fire';
import NavBar from '../../../nav-bar/NavBar';
import { Link } from 'react-router-dom';
import { institutions } from './../../../../resources/InstitutionData';
import styles from './PromptPage.module.css';
import appStyles from './../../../../AppStyles.module.css';

export default function PromptPage(props) {
  const location = useLocation();
  const ids = (location.state || {}).ids;
  console.log("IDS", ids);
  const lastReview = (location.state || {}).lastReview;

  // if preceptors is empty, increment. If rotation is empty, increment.
  // if empty reaches 2, redirect to last review if it exists
  const [empty, setEmpty] = useState({
    rotation: false,
    preceptors: false,
  });
  const [rotation, setRotation] = useState(null);
  const [preceptors, setPreceptors] = useState(null);
  const [otherPreceps, setOtherPreceps] = useState(null);
  const [redirect, setRedirect] = useState({
    go: false,
    path: '',
    state: {}
  })

  useEffect(() => {
    console.log("PRECEPS", preceptors);
  }, [preceptors])

  // get information on provided rotation and preceptor ids
  useEffect(() => {
    if (ids) {
      (async () => {
        if (!ids.rotation.reviewed) {
          const rotRef = doc(fireStore, 'rotations', ids.rotation.id);
          const rotSnap = await getDoc(rotRef);
          if (!rotSnap.exists()) {
            console.log("Setting empty under rotation");
            setEmpty(prev => ({...prev, preceptors: true}));
          }
          setRotation(rotSnap.data());
        }
      })()
    } else {
      handleRedirect();
    }
  }, [ids]);

  // look for other preceptors who have worked this rotation
  useEffect(() => {
    if (ids) {
      (async () => {
        const precepsRef = collection(fireStore, 'preceptors');
        const q = query(precepsRef, where(`rotations.${ids.rotation.id}`, '!=', null));
        const querySnapshot = await getDocs(q);
        let arr = [];
        querySnapshot.forEach(snap => {
          // don't include preceptors that have been reviewed in this prompt cycle
          if (!(ids.preceptors || []).includes(snap.id)) {
            arr.push(snap.data());
          }
        })
        if (arr.length === 0) {
          console.log("Setting empty under precep");
          setEmpty(prev => ({...prev, rotation: true}));
        }
        setPreceptors(arr);
      })();
    }
  }, [ids])

  useEffect(() => {
    console.log("EMPTY COUNT", empty);
    if (empty.rotation && empty.preceptors) {
      setRedirect({
        go: true,
        path: lastReview ? `/${lastReview.type}/${lastReview.id}` : '/'
      })
    }
  }, [empty])

  const handleRedirect = () => {
    if (lastReview) {
      setRedirect({
        go: true,
        path: `/${lastReview.type}/${lastReview.id}`,
        replace: true
      })
    } else {
      setRedirect({
        go: true,
        path: '/create',
        replace: true
      })
    }
  }

  const handleRotationClick = () => {
    let idsPassed = {
      preceptors: ids.preceptors,
      rotation: {
        id: ids.rotation.id,
        reviewed: true
      }
    }
    setRedirect({
      go: true,
      path: `/review/${ids.rotation.id}`,
      state: {ids: idsPassed, type: 'rotations'}
    })
  }

  const handlePreceptorClick = (id) => {
    return () => {
      // let otherPreceps = [...ids.preceptors];
      // const index = otherPreceps.indexOf(id);
      // if (index > -1) {
      //   otherPreceps.splice(index, 1);
      // }
      let idsPassed = {
        preceptors: [...(ids.preceptors || []), id],
        rotation: ids.rotation
      }
      setRedirect({
        go: true,
        path: `/review/${id}`,
        state: {type: 'preceptors', ids: idsPassed}
      })
    }
  }

  if (redirect.go) return <Navigate to={redirect.path} state={redirect.state} replace={redirect.replace}/>

  return <>
    <NavBar />
    <div className={styles.page}>
      <div className={styles.container}>
        {ids && <div className={styles.headerWrapper}>
          <div className={styles.header}>
            <h1 className={appStyles.h1}>Thanks!</h1>
            {((rotation || preceptors) && !ids.rotation.reviewed) && <p className={appStyles.question}>
              Would you like to review your {rotation ? 'rotation' : ''}
              {rotation && preceptors ? ' or' : ''}
              {(lastReview && preceptors) ? ' other' : ''}
              {preceptors ? ' preceptors' : ''}?
            </p>}
            {ids.rotation.reviewed && <p className={appStyles.question}>
              Would you like to review preceptors for your rotation?
            </p>}
          </div>
        </div>}
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            {rotation && <div>
              <h2 className={styles.btnTitle}>Review Rotation:</h2>
              <button
                className={styles.btn+" "+styles.rotation}
                onClick={handleRotationClick}
              >
                <div className={styles.btnData}>
                  <p className={styles.btnDataH1}>{rotation.discipline}</p>
                  <p className={styles.btnDataH2}>{institutions[rotation.institutionID].name}</p>
                </div>
              </button>
            </div>}
            {preceptors && <div>
              {preceptors.length !== 0 && <div>
                <h2 className={styles.btnTitle}>Review Preceptors:</h2>
                {preceptors.map(precep => (
                  <button
                    key={precep.id}
                    className={styles.btn+" "+styles.preceptors}
                    onClick={handlePreceptorClick(precep.id)}
                  >
                    <div className={styles.btnData}>
                      <p className={styles.btnDataH1}>{precep.fName} {precep.lName}</p>
                    </div>
                  </button>
                ))}
              </div>}
            </div>}
          </div>
        </div>
      </div>
      <div className={styles.noThanks}>
        <Link
          className={appStyles.link}
          to={lastReview ? `/${lastReview.type}/${lastReview.id}` : `/rotations/${ids.rotation.id}`}
        >No thanks</Link>
      </div>
    </div>
  </>
};