/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  ContactPage.js
*/

import React, { useEffect, useState } from 'react';
import NavBar from '../../nav-bar/NavBar';
import styles from './ContactPage.module.css';
import { Button, Form, Spinner } from 'react-bootstrap';
import appStyles from './../../../AppStyles.module.css';
import Footer from '../../footer/Footer';
import FaqModal from '../../faq-modal/FaqModal';
import GuidelinesModal from '../review-page/components/GuidelinesModal';
import TermsConditionsModal from '../../terms-conditions-modal/TermsConditionsModal';
import PrivacyPolicyModal from '../../privacy-policy-modal/PrivacyPolicyModal';
import { Link } from 'react-router-dom';
import ContactForm from './components/ContactForm';

export default function ContactPage(props) {
  const [submitted, setSubmitted] = useState(false);
  const [modal, setModal] = useState({
    faq: {show: false},
    revGuide: {show: false},
    terms: {show: false},
    privacy: {show: false}
  })

  return <>
    <div className={styles.container}>
      <div className={styles.navWrapper}>
        <NavBar />
      </div>
      <div className={styles.page}>
        <div className={styles.cover}>
          <h1 className={styles.title}>Contact Us</h1>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            {!submitted && <div className={styles.formSection}>
              <ContactForm 
                setSubmitted={setSubmitted}
              />
            </div>}
            {submitted && <div className={styles.formSection}>
              <h2 className={styles.h2}>Thank You for Contacting inCLINICALS!</h2>
              <p className={styles.p}>
                Your message has been successfully sent to our support team. We appreciate your inquiry and will get back to you as soon as possible.
              </p>
              <p className={styles.p}>
                In the meantime, feel free to explore more on inCLINICALS and take advantage of our resources. If you have any further questions or require immediate assistance, please don't hesitate to reach out to us at <a className={appStyles.link} href="mailto:support@inclinicals.com" >support@inclinicals.com</a>.
              </p>
              <p className={styles.p}>
                Thank you for choosing inCLINICALS, and we look forward to assisting you further.
              </p>
              <p className={styles.p}>
                The inCLINICALS Team
              </p>
            </div>}
            <div className={styles.resourcesSection}>
              <h2 className={styles.resTitle}>Additional Resources</h2>
              <div className={styles.resBtns}>
                <button 
                  className={styles.resBtn+" "+appStyles.primaryBtn}
                  onClick={() => setModal(prev => ({...prev, faq: {show: true}}))}
                >FAQ</button>
                <button 
                  className={styles.resBtn+" "+appStyles.primaryBtn}
                  onClick={() => setModal(prev => ({...prev, revGuide: {show: true}}))}
                >Review Guidelines</button>
                <Button 
                  variant="secondary"
                  className={styles.resBtn+" "+appStyles.primaryBtn}
                  as={Link}
                  to="/about"
                >About Us</Button>
                <button 
                  className={styles.resBtn+" "+appStyles.primaryBtn}
                  onClick={() => setModal(prev => ({...prev, terms: {show: true}}))}
                >Terms of Use</button>
                <button 
                  className={styles.resBtn+" "+appStyles.primaryBtn}
                  onClick={() => setModal(prev => ({...prev, privacy: {show: true}}))}
                >Privacy Policy</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    {modal.faq.show && <FaqModal
      show={modal.faq.show}
      onHide={() => setModal(prev => ({...prev, faq: {show: false}}))}
    />}
    {modal.revGuide.show && <GuidelinesModal
      show={modal.revGuide.show}
      onHide={() => setModal(prev => ({...prev, revGuide: {show: false}}))}
    />}
    {modal.terms.show && <TermsConditionsModal
      show={modal.terms.show}
      onHide={() => setModal(prev => ({...prev, terms: {show: false}}))}
    />}
    {modal.privacy.show && <PrivacyPolicyModal
      show={modal.privacy.show}
      onHide={() => setModal(prev => ({...prev, privacy: {show: false}}))}
    />}
  </>
};