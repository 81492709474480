/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Reviews.js
*/

import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { fireStore } from '../../Fire';
import { query, orderBy, limit, collection, getDocs, deleteDoc, doc, where, or, FieldValue } from 'firebase/firestore';
import RotationReviewModal from '../review-modal/RotationReviewModal';
import RotationReviewBlock from './RotationReviewBlock';
import PreceptorReviewBlock from './PreceptorReviewBlock';
import PreceptorReviewModal from '../review-modal/PreceptorReviewModal';
import styles from "./Reviews.module.css";
import { sleep } from '../../resources/Functions';
import Alert from '../alert/Alert';


export default function Reviews(props) {
  const {
    document,
    openReviewModal,
    type                // 'rotations' or 'preceptors'
  } = props;
  const [revCap, setRevCap] = useState(3);
  const [reviews, setReviews] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState({
    go: false,
    path: ""
  })
  const [modal, setModal] = useState({
    review: {
      show: false
    }
  })
  // used to show Alert modals on page
  const [alert, setAlert] = useState({ show: false });
  // const history = useHistory();

  /*
    TECH DEBT
    The get more button doesn't show the spinner when it's clicked the last time
    because then revCap is greater than the revCount so the spinner isn't shown
    even if we're still loading those extra few reviews
  */
  useEffect(() => {
    (async () => {
      setLoading(true);
      const rvsRef = collection(fireStore, `${type}/${document.id}/reviews`);
      const q = query(rvsRef, where("postOnDate", "<", Date.now()), orderBy("postOnDate", "desc"), limit(revCap));
      const querySnapshot = await getDocs(q);
      setReviews(querySnapshot);
      setLoading(false);
    })();
  }, [revCap, refresh]);

  const handleReviewClick = () => {
    // history.push(`/${type}/` + docID);
    setRedirect({
      go: true,
      path: "/review/" + document.id,
      state: {
        type: type
      }
    })
  }

  const deleteReview = async (collection, targetId, revId) => {
    await deleteDoc(doc(fireStore, `${collection}/${targetId}/reviews`, revId))
      .then(() => setRefresh(prev => !prev))
      .catch(error => console.error("Error deleting review", error));
  }

  const handleDelete = (collection, targetId, revId) => {
    setAlert({
      show: true,
      title: 'Confirmation Needed',
      message: 'Are you sure you want to delete this review? This action cannot be undone',
      confirmCallback: () => {
        setAlert({show: false});
        deleteReview(collection, targetId, revId);
      },
      actionNeeded: true,
      confirmText: 'Delete Review',
      variant: 'danger',
      cancelCallback: () => setAlert({show: false})
    })
  }

  const reviewElements = [];
  if (reviews) {
    reviews.forEach((doc, i) => {
      reviewElements.push(type === 'rotations' ? 
        <RotationReviewBlock
          key={doc.id} 
          rev={doc}
          rotation={document}
          i={i}
          showModal={(rev) => {
            console.log("showing rotation review block")
            setModal(prev => ({...prev, review: {
              show: true, 
              rev: rev,
              type: 'rotation',
              targetId: doc.data().targetId
            }}))
          }}
        /> :
        <PreceptorReviewBlock
          key={doc.id} 
          rev={doc}
          preceptor={document}
          i={i}
          showModal={(rev) => setModal(prev => ({...prev, review: {
            show: true, 
            rev: rev,
            type: 'preceptor',
            targetId: doc.data().targetId
          }}))}
        />
      )
    })
  }

  if (redirect.go) return <Navigate to={redirect.path} state={redirect.state} />;

  return <>
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>
          Reviews <span className={styles.titleCount}>({document.reviewCount !== undefined ? document.reviewCount : "0"})</span>
        </h2>
        <Button
          variant="secondary"
          onClick={handleReviewClick}
          className={styles.writeButton + (type === 'preceptors' ? " "+styles.precep : "")}
        ><i className="bi-pencil"/> Write a Review</Button>
      </div>
      <p className={styles.notice}>Some reviews may have delayed posting and are not shown.</p>
      {reviews && <div className={styles.reviewsContainer}>
        {reviewElements}
      </div>}
      <div className={styles.moreButtonWrapper}>
        {revCap < document.reviewCount &&
          <Button
            variant="secondary"
            className={styles.writeButton + (type === 'preceptors' ? " "+styles.precep : "")}
            onClick={() => setRevCap(prev => prev + 3)}
          >{loading ? 
            <Spinner animation="border" size='sm' /> :
            "Get More"
          }</Button>
        }
      </div>
    </div>
    {(type === 'rotations' && modal.review.show) && <RotationReviewModal 
      show={modal.review.show}
      rev={modal.review.rev}
      rotation={document}
      onHide={() => setModal(prev => ({...prev, review: {show: false}}))}
      targetId={modal.review.targetId}
      handleDelete={handleDelete}
    />}
    {(type === 'preceptors' && modal.review.show) && <PreceptorReviewModal 
      show={modal.review.show}
      rev={modal.review.rev}
      preceptor={document}
      onHide={() => setModal(prev => ({...prev, review: {show: false}}))}
      targetId={modal.review.targetId}
      handleDelete={handleDelete}
    />}
    {alert.show && <Alert {...alert} />}
  </>
}