export const schools = {
  "000": {
    name: "TEST",
    email: "@gmail.com",
    website: "https://google.com",
    id: "000",
  },
  "001": {
    name: "Loma Linda University School of Medicine",
    email: "@students.llu.edu",
    website: "https://medicine.llu.edu",
    id: "001",
  },
  "002": {
    name: "University of California Riverside School of Medicine",
    email: "@medsch.ucr.edu",
    website: "https://medschool.ucr.edu",
    id: "002",
  },
  "003": {
    name: "University of California San Diego School of Medicine",
    email: "@mail.ucsd.edu",
    website: "https://medschool.ucsd.edu/",
    id: "003",
  },
  "004": {
    name: "California University of Science and Medicine",
    email: "@calmedu.org",
    website: "https://www.cusm.org",
    id: "004",
  },
  "005": {
    name: "University of California Irvine Schol of Medicine",
    email: "@hs.uci.edu",
    website: "https://www.som.uci.edu",
    id: "005",
  },
  "006": {
    name: "Univiersity of Southern California Keck School of Medicine",
    email: "@usc.edu",
    website: "https://keck.usc.edu",
    id: "006",
  },
  "007": {
    name: "Western Universty of Health Sciences College of Osteopathic Medicine",
    email: "@westernu.edu",
    website: "https://www.westernu.edu/osteopathic/",
    id: "007",
  },
  "008": {
    name: "University of California Los Angeles David Geffen School of Medicine",
    email: "@mednet.ucla.edu",
    website: "https://medschool.ucla.edu",
    id: "008",
  },
  "009": {
    name: "Charles R. Drew University of Medicine and Science",
    email: "@cdrewu.edu",
    website: "https://www.cdrewu.edu",
    id: "009",
  },
  "010": {
    name: "Kaiser Permanent Tyson School of Medicine",
    email: "@medschool.kp.org",
    website: "https://medschool.kp.org",
    id: "010",
  },
  "011": {
    name: "University of Florida College of Medicine",
    email: "@ufl.edu",
    website: "https://med.ufl.edu",
    id: "011",
  },
  "012": {
    name: "USF Health Morsani College of Medicine",
    email: "@usf.edu",
    website: "https://health.usf.edu/medicine",
    id: "012",
  },
  "013": {
    name: "Lake Erie College of Osteopathic Medicine",
    email: "@lec.edu",
    website: "https://lecom.edu",
    id: "013",
  },
  "014": {
    name: "University of Central Florida College of Medicine",
    email: "@ucf.edu",
    website: "https://med.ucf.edu",
    id: "014",
  },
  "015": {
    name: "Florida State University College of medicine",
    email: "@med.fsu.edu",
    website: "https://med.fsu.edu",
    id: "015",
  },
  "016": {
    name: "Nova Southeastern University",
    email: "@mynsu.nova.edu",
    website: "https://www.nova.edu/",
    id: "016",
  },
  "017": {
    name: "University of South Florida",
    email: "@usf.edu",
    website: "https://www.usf.edu/",
    id: "017",
  },
  "018": {
    name: "Kansas City University",
    email: "@kansascity.edu",
    website: "https://kansascity.edu/",
    id: "018",
  },
  "019": {
    name: "Ross University",
    email: "@mail.rossmed.edu",
    website: "https://medical.rossu.edu/",
    id: "019"
  },
  "020": {
    name: "AdventHealth",
    email: "@adventhealth.com",
    website: "https://adventhealth.com",
    id: "020"
  },
  "021": {
    name: "Philadelphia College of Osteopathic Medicine",
    email: "@pcom.edu",
    website: "https://www.pcom.edu/",
    id: "021"
  },
  "022": {
    name: "Thomas Jefferson University",
    email: "@jefferson.edu",
    website: "https://www.jefferson.edu/",
    id: "022"
  },
};

export const disciplines = {
  "001": {
    name: "Internal Medicine",
    abbrv: "im",
  },
  "002": {
    name: "Obstetrics and Gynecology",
    abbrv: "obgyn",
  },
  "003": {
    name: "Ophthalmology",
    abbrv: "optho",
  },
  "004": {
    name: "Family Medicine",
    abbrv: "fm",
  },
  "005": {
    name: "General Surgery",
    abbrv: "gen surg",
  },
  "006": {
    name: "Emergency Medicine",
    abbrv: "em",
  },
  "007": {
    name: "Pediatrics",
    abbrv: "peds",
  },
  "008": {
    name: "Psychiatry",
    abbrv: "psych",
  },
  "009": {
    name: "Anesthesia",
    abbrv: "anesth",
  },
  "010": {
    name: "Neurology",
    abbrv: "neuro",
  },
  "011": {
    name: "Radiology",
    abbrv: "rad"
  },
  "012": {
    name: "Dermatology",
    abbrv: "derm"
  },
  "013": {
    name: "Plastic Surgery",
    abbrv: "plastic"
  },
  "014": {
    name: "Pathology",
    abbrv: "path"
  },
  "015": {
    name: "Orthopedic Surgery",
    abbrv: "ortho"
  },
  "016": {
    name: "Ear Nose Throat",
    abbrv: "ent"
  }
}

export const institutions = {
  "0001": {
    name: "Loma Linda Medical Center",
    location: {
      city: "Loma Linda",
      state: "CA"
    },
    website: "https://lluh.org/",
    id: "0001",
  },
  "0002": {
    name: "Kettering Medical Center",
    location: {
      city: "Kettering",
      state: "OH"
    },
    website: "https://ketteringhealth.org/",
    id: "0002",
  },
  "0003": {
    name: "Adventist Health White Memorial",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://www.adventisthealth.org/white-memorial/",
    id: "0003",
  },
  "0004": {
    name: "Eisenhower Health Medical Center",
    location: {
      city: "Ranch Mirage",
      state: "CA"
    },
    website: "https://eisenhowerhealth.org/",
    id: "0004",
  },
  "0005": {
    name: "Kasier Permanent Fontana Medical Center",
    location: {
      city: "Fontana",
      state: "CA"
    },
    website: "https://healthy.kaiserpermanente.org/southern-california/facilities/fontana-medical-center-100127",
    id: "0005",
  },
  "0006": {
    name: "Riverside Community Hospital",
    location: {
      city: "Riverside",
      state: "CA"
    },
    website: "https://riversidecommunityhospital.com/",
    id: "0006",
  },
  "0007": {
    name: "Riverside University Health System Medical Center",
    location: {
      city: "Riverside",
      state: "CA"
    },
    website: "https://www.ruhealth.org/",
    id: "0007",
  },
  "0008": {
    name: "UCI Medical Center",
    location: {
      city: "Orange",
      state: "CA"
    },
    website: "https://www.ucihealth.org/locations/orange/uci-medical-center",
    id: "0008",
  },
  "0009": {
    name: "VA Long Beach",
    location: {
      city: "Long Beach",
      state: "CA"
    },
    website: "https://www.va.gov/long-beach-health-care/",
    id: "0009",
  },
  "0010": {
    name: "Long Beach Memorial Medical Center",
    location: {
      city: "Long Beach",
      state: "CA"
    },
    website: "https://www.memorialcare.org/locations/long-beach-medical-center",
    id: "0010",
  },
  "0011": {
    name: "Children's Health of Orange County",
    location: {
      city: "Orange",
      state: "CA"
    },
    website: "https://www.choc.org/locations/choc-childrens-main-campus-orange/?utm_source=gmb&utm_medium=main-campus&utm_content=hospital&utm_campaign=Website",
    id: "0011",
  },
  "0012": {
    name: "Harbor-UCLA Medical Center",
    location: {
      city: "West Carson",
      state: "CA"
    },
    website: "https://dhs.lacounty.gov/harbor-ucla-medical-center/",
    id: "0012",
  },
  "0013": {
    name: "Ronald Reagan UCLA Medical Center",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://www.uclahealth.org/hospitals/reagan",
    id: "0013",
  },
  "0014": {
    name: "Cedar Sinai Medical Center",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://www.cedars-sinai.org/locations/cedars-sinai-main-campus-89.html",
    id: "0014",
  },
  "0015": {
    name: "White Memorial Medical Center",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://www.welcometowhitememorial.com/",
    id: "0015",
  },
  "0016": {
    name: "Dignity Health - St. Bernardine's Medical Center",
    location: {
      city: "San Bernardino",
      state: "CA"
    },
    website: "https://www.dignityhealth.org/socal/locations/stbernardinemedical",
    id: "0016",
  },
  "0017": {
    name: "Naval Regional Medical Center",
    location: {
      city: "San Diego",
      state: "CA"
    },
    website: "https://sandiego.tricare.mil/",
    id: "0017",
  },
  "0018": {
    name: "Arrowhead Regional Medical Center",
    location: {
      city: "Colton",
      state: "CA"
    },
    website: "https://www.arrowheadregional.org/",
    id: "0018",
  },
  "0019": {
    name: "Prime Healthcare Foundation",
    location: {
      city: "Ontario",
      state: "CA"
    },
    website: "https://www.primehealthcare.com/Giving-Advocacy/Prime-Healthcare-Foundation.aspx",
    id: "0019",
  },
  "0020": {
    name: "LAC + USC Medical Center",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://dhs.lacounty.gov/lacusc/",
    id: "0020",
  },
  "0021": {
    name: "PIH Health Hospital - Downey",
    location: {
      city: "Downey",
      state: "CA"
    },
    website: "https://www.pihhealth.org/find-a-location/locations-profile/pih-health-hospital-downey/",
    id: "0021",
  },
  "0022": {
    name: "College Medical Center",
    location: {
      city: "Long Beach",
      state: "CA"
    },
    website: "https://www.collegemedicalcenter.com/",
    id: "0022",
  },
  "0023": {
    name: "Garfield Medical Center",
    location: {
      city: "Monterey Park",
      state: "CA"
    },
    website: "https://www.garfieldmedicalcenter.com/",
    id: "0023",
  },
  "0024": {
    name: "Good Samaritan Regional Medical Center",
    location: {
      city: "Corvallis",
      state: "OR"
    },
    website: "https://www.samhealth.org/find-a-location/g/good-samaritan-regional-medical-center",
    id: "0024",
  },
  "0025": {
    name: "Mercy Medical Center",
    location: {
      city: "Roseburg",
      state: "OR"
    },
    website: "https://chimercyhealth.com/",
    id: "0025",
  },
  "0026": {
    name: "Asante Rogue Regional Medical Center",
    location: {
      city: "Medford",
      state: "OR"
    },
    website: "https://www.asante.org/Locations/location-detail/rogue-regional-medical-center/",
    id: "0026",
  },
  "0027": {
    name: "St. Charles Medical Center - Bend",
    location: {
      city: "Bend",
      state: "OR"
    },
    website: "https://www.stcharleshealthcare.org/locations/st-charles-bend",
    id: "0027",
  },
  "0028": {
    name: "Legacy Health System - Portland",
    location: {
      city: "Portland",
      state: "OR"
    },
    website: "https://www.legacyhealth.org/",
    id: "0028",
  },
  "0029": {
    name: "Downey Medical Center",
    location: {
      city: "Downey",
      state: "CA"
    },
    website: "https://medschool.kp.org/education/clinical-training-sites/downey-medical-center",
    id: "0029",
  },
  "0030": {
    name: "Los Angeles Medical Center",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://medschool.kp.org/education/clinical-training-sites/los-angeles-medical-center",
    id: "0030",
  },
  "0031": {
    name: "Panorama City Medical Center",
    location: {
      city: "Panorama",
      state: "CA"
    },
    website: "https://medschool.kp.org/education/clinical-training-sites/panorama-city-medical-center",
    id: "0031",
  },
  "0032": {
    name: "San Bernardino County Medical Center",
    location: {
      city: "Fontana",
      state: "CA"
    },
    website: "https://medschool.kp.org/education/clinical-training-sites/san-bernardino-county-medical-center",
    id: "0032",
  },
  "0033": {
    name: "The South Bay Medical Center",
    location: {
      city: "Harbor City",
      state: "CA"
    },
    website: "https://medschool.kp.org/education/clinical-training-sites/south-bay-medical-center",
    id: "0033",
  },
  "0034": {
    name: "West Los Angeles Medical Center",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://medschool.kp.org/education/clinical-training-sites/west-los-angeles-medical-center",
    id: "0034",
  },
  "0035": {
    name: "Halifax Health Medical Center",
    location: {
      city: "Daytona Beach",
      state: "CA"
    },
    website: "https://halifaxhealth.org/",
    id: "0035",
  },
  "0036": {
    name: "Sacred Heart",
    location: {
      city: "Pensacola",
      state: "FL"
    },
    website: "N/A",
    id: "0036",
  },
  "0037": {
    name: "Tallahassee Memorial Cancer Center",
    location: {
      city: "Tallahassee",
      state: "FL"
    },
    website: "https://www.tmh.org/location/tallahassee-memorial-cancer-center",
    id: "0037",
  },
  "0038": {
    name: "UF Health Shands Hospital",
    location: {
      city: "Gainesville",
      state: "FL"
    },
    website: "https://ufhealth.org/shands-university-florida",
    id: "0038",
  },
  "0039": {
    name: "UF Health Jacksonville",
    location: {
      city: "Jacksonville",
      state: "FL"
    },
    website: "https://ufhealthjax.org/",
    id: "0039",
  },
  "0040": {
    name: "UF Health Shands Cancer Hospital",
    location: {
      city: "Gainesville",
      state: "FL"
    },
    website: "https://ufhealth.org/shands-cancer-hospital-uf",
    id: "0040",
  },
  "0041": {
    name: "UF Health Critical Care Medicine",
    location: {
      city: "Gainesville",
      state: "FL"
    },
    website: "https://ufhealth.org/specialty/critical-care-medicine",
    id: "0041",
  },
  "0042": {
    name: "Loma Linda Surgical Hospital",
    location: {
      city: "Redlands",
      state: "CA"
    },
    website: "https://lluh.org/locations/loma-linda-university-surgical-hospital",
    id: "0042",
  },
  "0043": {
    name: "Loma Linda VA Hospital",
    location: {
      city: "Loma Linda",
      state: "CA"
    },
    website: "https://www.va.gov/loma-linda-health-care/",
    id: "0043",
  },
  "0044": {
    name: "Adventist Health St. Helena",
    location: {
      city: "St. Helena",
      state: "CA"
    },
    website: "https://www.adventisthealth.org/st-helena/",
    id: "0044",
  },
  "0045": {
    name: "Adventist Health Ukiah",
    location: {
      city: "Ukiah",
      state: "CA"
    },
    website: "https://www.adventisthealth.org/ukiah-valley/",
    id: "0045",
  },
  "0046": {
    name: "Glendale Adventist Medical Center",
    location: {
      city: "Glendale",
      state: "CA"
    },
    website: "https://info.adventisthealth.org/scn/glendale/cardiology/surgical",
    id: "0046",
  },
  "0047": {
    name: "Hollywood Presbyterian Medical Center",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://www.hollywoodpresbyterian.com/",
    id: "0047",
  },
  "0048": {
    name: "PIH Whittier Hospital",
    location: {
      city: "Whittier",
      state: "CA"
    },
    website: "https://www.pihhealth.org/find-a-location/locations-profile/pih-health-whittier-hospital/",
    id: "0048",
  },
  "0049": {
    name: "Saddleback Memorial",
    location: {
      city: "Laguna Hills",
      state: "CA"
    },
    website: "https://www.memorialcare.org/locations/saddleback-medical-center",
    id: "0049",
  },
  "0050": {
    name: "Rady Children's Hospital of San Diego",
    location: {
      city: "San Diego",
      state: "CA"
    },
    website: "https://www.rchsd.org/",
    id: "0050",
  },
  "0051": {
    name: "UC San Diego Health",
    location: {
      city: "San Diego",
      state: "CA"
    },
    website: "https://health.ucsd.edu/",
    id: "0051",
  },
  "0052": {
    name: "Keck Hospital of USC",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://www.keckmedicine.org/about/keck-hospital-of-usc/",
    id: "0052",
  },
  "0053": {
    name: "USC Norris Cancer Hospital",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://uscnorriscancer.usc.edu/",
    id: "0053",
  },
  "0054": {
    name: "Advanced Pain Specialists of Southern California",
    location: {
      city: "Multiple Locations",
      state: "CA"
    },
    website: "https://advpainspecialists.com/",
    id: "0054",
  },
  "0055": {
    name: "Brentview Medical",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://brentviewmedical.com/",
    id: "0055",
  },
  "0056": {
    name: "California Hospital Medical Center-Dignity Health",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://www.dignityhealth.org/socal/locations/californiahospital",
    id: "0056",
  },
  "0057": {
    name: "Cedars-Sinai Medical Center",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://www.cedars-sinai.org/",
    id: "0057",
  },
  "0058": {
    name: "Children’s Hospital Los Angeles",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://www.chla.org/",
    id: "0058",
  },
  "0059": {
    name: "Harbor-UCLA Medical Center",
    location: {
      city: "Torrance",
      state: "CA"
    },
    website: "https://dhs.lacounty.gov/harbor-ucla-medical-center/",
    id: "0059",
  },
  "0060": {
    name: "Hubert H. Humphrey Comprehensive Health Center",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "http://dhs.lacounty.gov/wps/portal/dhs/humphrey",
    id: "0060",
  },
  "0061": {
    name: "Joshua Medical Center",
    location: {
      city: "La Palma",
      state: "CA"
    },
    website: "https://www.joshuamedical.com/",
    id: "0061",
  },
  "0062": {
    name: "Kheir Center",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://www.lakheir.org/",
    id: "0062",
  },
  "0063": {
    name: "Long Beach Kids – Solomon Laktineh, MD",
    location: {
      city: "Long Beach",
      state: "CA"
    },
    website: "https://longbeachkids.com/",
    id: "0063",
  },
  "0064": {
    name: "Northeast Valley Health Corporation",
    location: {
      city: "Multiple Locations",
      state: "CA"
    },
    website: "https://nevhc.org/",
    id: "0064",
  },
  "0065": {
    name: "Pacific Coast Orthopaedic Institute",
    location: {
      city: "Fountain Valley",
      state: "CA"
    },
    website: "https://www.pcorthopaedics.com/",
    id: "0065",
  },
  "0066": {
    name: "San Joaquin Community Hospital dba Adventist Health Bakersfield",
    location: {
      city: "Bakersfield",
      state: "CA"
    },
    website: "https://www.adventisthealth.org/",
    id: "0066",
  },
  "0067": {
    name: "Spine and Sports Specialties Medical Group",
    location: {
      city: "Campbell",
      state: "CA"
    },
    website: "https://ssmedgroup.com/home",
    id: "0067",
  },
  "0068": {
    name: "St. Francis Medical center",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://stfran.com/",
    id: "0068",
  },
  "0069": {
    name: "St. John’s Regional Medical Center",
    location: {
      city: "Oxnard",
      state: "CA"
    },
    website: "https://www.dignityhealth.org/central-coast/locations/stjohnsregional",
    id: "0069",
  },
  "0070": {
    name: "St. John’s Regional Medical Center and Pleasant Valley Hospital/St. John's Hospital Camarillo",
    location: {
      city: "Oxnard",
      state: "CA"
    },
    website: "https://www.dignityhealth.org/central-coast/locations/pleasantvalley",
    id: "0070",
  },
  "0071": {
    name: "TotalCare Walk-In Clinic",
    location: {
      city: "Multiple Locations",
      state: "CA"
    },
    website: "https://totalcarenow.com/",
    id: "0071",
  },
  "0072": {
    name: "Tri City Medical Group",
    location: {
      city: "Oceanside",
      state: "CA"
    },
    website: "https://www.tricitymed.org/",
    id: "0072",
  },
  "0073": {
    name: "United Care Family Medical Center",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://www.unitedcaremedical.com/",
    id: "0073",
  },
  "0074": {
    name: "Venice Family Clinic",
    location: {
      city: "Multiple Locations",
      state: "CA"
    },
    website: "https://venicefamilyclinic.org/",
    id: "0074",
  },
  "0075": {
    name: "Ventura County Medical Center",
    location: {
      city: "Ventura",
      state: "CA"
    },
    website: "https://hospitals.vchca.org/",
    id: "0075",
  },
  "0076": {
    name: "Wallace Medical Group",
    location: {
      city: "Beverly Hills",
      state: "CA"
    },
    website: "https://www.wallaceskinandbodyinstitute.com/",
    id: "0076",
  },
  "0077": {
    name: "Watts Health Center",
    location: {
      city: "Los Angeles",
      state: "CA"
    },
    website: "https://www.wattshealth.org/",
    id: "0077",
  },
  "0078": {
    name: "West Coast Center for Urology",
    location: {
      city: "Multiple Locations",
      state: "West Coast"
    },
    website: "https://www.wcuro.com/",
    id: "0078",
  },
  "0079": {
    name: "UCLA Health Santa Clarita Primary & Specialty Care",
    location: {
      city: "Valencia",
      state: "CA"
    },
    website: "https://www.uclahealth.org/locations/search?s=santa+clarita",
    id: "0079",
  },
  "0080": {
    name: "Olive View-UCLA Medical Center",
    location: {
      city: "Sylmar",
      state: "CA"
    },
    website: "https://dhs.lacounty.gov/oliveview/",
    id: "0080",
  },
  "0081": {
    name: "Doheny Eye Center UCLA Arcadia",
    location: {
      city: "Arcadia",
      state: "CA"
    },
    website: "https://www.uclahealth.org/locations/doheny-eye-center-ucla-arcadia",
    id: "0081",
  },
  "0082": {
    name: "UCLA Family Health Center",
    location: {
      city: "Santa Monica",
      state: "CA"
    },
    website: "https://www.uclahealth.org/locations/family-health-center",
    id: "0082",
  },
  "0083": {
    name: "UCLA Santa Monica Medical Center",
    location: {
      city: "Santa Monica",
      state: "CA"
    },
    website: "https://www.uclahealth.org/hospitals/santa-monica",
    id: "0083",
  },
  "0084": {
    name: "UCLA Center for East-West Medicine",
    location: {
      city: "Santa Monica",
      state: "CA"
    },
    website: "https://www.uclahealth.org/locations/center-east-west-medicine",
    id: "0084",
  },
  "0085": {
    name: "Tampa General Hospital",
    location: {
      city: "Tampa",
      state: "FL"
    },
    website: "https://www.tgh.org/",
    id: "0085",
  },
  "0086": {
    name: "Center For Advanced Medical Learning And Simulation",
    location: {
      city: "Tampa",
      state: "FL"
    },
    website: "https://camls-us.org/",
    id: "0086",
  },
  "0087": {
    name: "Carol and Frank Morsani Center for Advanced Healthcare",
    location: {
      city: "Tampa",
      state: "FL"
    },
    website: "https://health.usf.edu/medicine/internalmedicine/digestive/morsanicenter",
    id: "0087",
  },
  "0088": {
    name: "James A. Haley Veteran's Hospital",
    location: {
      city: "Tampa",
      state: "FL"
    },
    website: "https://www.va.gov/tampa-health-care/locations/james-a-haley-veterans-hospital/",
    id: "0088",
  },
  "0089": {
    name: "All Children's Hospital",
    location: {
      city: "St. Petersburg",
      state: "FL"
    },
    website: "https://www.hopkinsallchildrens.org/",
    id: "0089",
  },
  "0090": {
    name: "H. Lee Moffit Cancer Center and Research Institute",
    location: {
      city: "Tampa",
      state: "FL"
    },
    website: "https://moffitt.org/",
    id: "0090",
  },
  "0091": {
    name: "South Tampa Center for Advanced Healthcare",
    location: {
      city: "Tampa",
      state: "FL"
    },
    website: "http://www.health.usf.edu/",
    id: "0091",
  },
  "0092": {
    name: "Bay Pines Veteran's Hospital",
    location: {
      city: "Bay Pines",
      state: "FL"
    },
    website: "https://www.va.gov/bay-pines-health-care/",
    id: "0092",
  },
  "0093": {
    name: "Pepin Heart Hospital & Dr. Kiran C. Patel Research Institute at UCH Community Hospital",
    location: {
      city: "Tampa",
      state: "FL"
    },
    website: "https://www.adventhealth.com/hospital/adventhealth-tampa/pepin-heart-institute-and-dr-kiran-c-patel-research-institute",
    id: "0093",
  },
  "0094": {
    name: "Shriner's Hospital for Children",
    location: {
      city: "Multiple Locations",
      state: "FL"
    },
    website: "https://www.shrinerschildrens.org/en",
    id: "0094",
  },
  "0095": {
    name: "USF Eye Institute and ENT Center",
    location: {
      city: "Tampa",
      state: "FL"
    },
    website: "https://health.usf.edu/medicine/ent",
    id: "0095",
  },
  "0096": {
    name: "USF Psychiatry Center",
    location: {
      city: "Tampa",
      state: "FL"
    },
    website: "https://health.usf.edu/care/psychiatry",
    id: "0096",
  },
  "0097": {
    name: "USF Dialysis Center",
    location: {
      city: "Tampa",
      state: "FL"
    },
    website: "https://www.healthcare4ppl.com/dialysis/florida/tampa/usf-dialysis-center-102636.html",
    id: "0097",
  },
  "0098": {
    name: "17 Davis Pediatric Ambulatory Care Center",
    location: {
      city: "Tampa",
      state: "FL"
    },
    website: "https://health.usf.edu/care/pediatrics",
    id: "0098",
  },
  "0099": {
    name: "Genesis Clinic",
    location: {
      city: "Tampa",
      state: "FL"
    },
    website: "https://mygenesismedical.com/",
    id: "0099",
  },
  "0100": {
    name: "University Diagnostic Institute",
    location: {
      city: "Tampa",
      state: "FL"
    },
    website: "http://www.universitydiagnostic.com/",
    id: "0100",
  },
  "0101": {
    name: "Harbourside Faculty Practice Center - University Diagnostic Institute",
    location: {
      city: "Tampa",
      state: "FL"
    },
    website: "http://www.universitydiagnostic.com/",
    id: "0101",
  },
  "0102": {
    name: "Outpatient Centers",
    location: {
      city: "Tampa",
      state: "FL"
    },
    website: "https://health.usf.edu/medicine/pediatrics/residency/outpatient-facilities",
    id: "0102",
  },
  "0103": {
    name: "AdventHealth",
    location: {
      city: "Tampa",
      state: "FL"
    },
    website: "https://www.adventhealth.com/",
    id: "0103",
  },
  "0104": {
    name: "Ascension St. Vincent’s",
    location: {
      city: "Jacksonville",
      state: "FL"
    },
    website: "https://healthcare.ascension.org/locations/florida/fljac/jacksonville-ascension-st-vincents-riverside",
    id: "0104",
  },
  "0105": {
    name: "Baycare Health System",
    location: {
      city: "Tampa",
      state: "FL"
    },
    website: "http://www.baycare.org/",
    id: "0105",
  },
  "0106": {
    name: "Baptist Health System",
    location: {
      city: "Multiple Locations",
      state: "FL"
    },
    website: "https://baptisthealth.net/",
    id: "0106",
  },
  "0107": {
    name: "Bradenton Physicians Medical Center",
    location: {
      city: "Bradenton",
      state: "FL"
    },
    website: "https://mymaxdoc.com/",
    id: "0107",
  },
  "0108": {
    name: "Brandon Hospital",
    location: {
      city: "Brandon",
      state: "FL"
    },
    website: "https://www.hcafloridahealthcare.com/for-medical-professionals/physician-and-employee-information/brandon-hospital",
    id: "0108",
  },
  "0109": {
    name: "Florida Hospital-Hartland",
    location: {
      city: "Sebring",
      state: "FL"
    },
    website: "https://www.adventhealth.com/hospital/adventhealth-sebring",
    id: "0109",
  },
  "0110": {
    name: "Keralty Hospital",
    location: {
      city: "Miami",
      state: "FL"
    },
    website: "https://keraltyhospital.com/",
    id: "0110",
  },
  "0111": {
    name: "Larkin Community Health",
    location: {
      city: "South Miami",
      state: "FL"
    },
    website: "https://larkinhealth.com/en/",
    id: "0111",
  },
  "0112": {
    name: "Manatee Memorial Hospital",
    location: {
      city: "Bradenton",
      state: "FL"
    },
    website: "https://www.manateememorial.com/",
    id: "0112",
  },
  "0113": {
    name: "Palm Springs General Hospital",
    location: {
      city: "Hialeah",
      state: "FL"
    },
    website: "https://larkinhealth.com/en/locations/larkin-hospital-palm-springs/",
    id: "0113",
  },
  "0114": {
    name: "South Bay Hospital",
    location: {
      city: "Sun City Center",
      state: "FL"
    },
    website: "https://www.hospitalmanagement.net/hospitals/south-bay-hospital/",
    id: "0114",
  },
  "0115": {
    name: "Bay Pines VA Healthcare System",
    location: {
      city: "Bay Pines",
      state: "FL"
    },
    website: "https://med.ucf.edu/academics/md-program/clinical-partners/bay-pines/",
    id: "0115",
  },
  "0116": {
    name: "Flagler Health",
    location: {
      city: "St. Augustine",
      state: "FL"
    },
    website: "https://www.flaglerhealth.org/",
    id: "0116",
  },
  "0117": {
    name: "NCH Healthcare System",
    location: {
      city: "Naples",
      state: "CA"
    },
    website: "https://nchmd.org/",
    id: "0117",
  },
  "0118": {
    name: "Nemours Foundation",
    location: {
      city: "Jacksonville",
      state: "FL"
    },
    website: "https://www.nemours.org/",
    id: "0118",
  },
  "0119": {
    name: "North Florida Regional Medical Center",
    location: {
      city: "Gainesville",
      state: "FL"
    },
    website: "https://www.hcafloridahealthcare.com/locations/north-florida-hospital",
    id: "0119",
  },
  "0120": {
    name: "Ocala Regional Medical Center",
    location: {
      city: "Ocala",
      state: "FL"
    },
    website: "https://www.hcafloridahealthcare.com/locations/ocala-hospital",
    id: "0120",
  },
  "0121": {
    name: "Orlando Veterans Affairs Medical Center",
    location: {
      city: "Orlando",
      state: "FL"
    },
    website: "https://www.va.gov/orlando-health-care/",
    id: "0121",
  },
  "0122": {
    name: "Osceola Regional Medical Center",
    location: {
      city: "Kissimmee",
      state: "FL"
    },
    website: "https://osceolaregional.com/",
    id: "0122",
  },
  "0123": {
    name: "UCF Lake Nona Hospital",
    location: {
      city: "Orlando",
      state: "FL"
    },
    website: "https://ucflakenonamedicalcenter.com/",
    id: "0123",
  },
  "0124": {
    name: "Central Florida Behavioral Hospital",
    location: {
      city: "Orlando",
      state: "FL"
    },
    website: "https://centralfloridabehavioral.com/",
    id: "0124",
  },
  "0125": {
    name: "Lakeside Behavioral Healthcare",
    location: {
      city: "Lakeside",
      state: "FL"
    },
    website: "https://med.ucf.edu/academics/md-program/clinical-partners/lakeside-behavioral-healthcare/",
    id: "0125",
  },
  "0126": {
    name: "Life Care Center of Altamonte Springs",
    location: {
      city: "Altamonte Springs",
      state: "FL"
    },
    website: "https://lcca.com/locations/fl/altamonte-springs/",
    id: "0126",
  },
  "0127": {
    name: "Pasadena Villas",
    location: {
      city: "Orlando",
      state: "FL"
    },
    website: "http://www.pasadenavilla.com/",
    id: "0127",
  },
  "0128": {
    name: "University Behavioral Center",
    location: {
      city: "Orlando",
      state: "FL"
    },
    website: "https://universitybehavioral.com/",
    id: "0128",
  },
  "0129": {
    name: "Orange County Health Department",
    location: {
      city: "Orange",
      state: "CA"
    },
    website: "https://www.orangecountync.gov/204/Health-Department",
    id: "0129",
  },
  "0130": {
    name: "Osceola Council on Aging",
    location: {
      city: "Osceola",
      state: "FL"
    },
    website: "https://osceolagenerations.org/",
    id: "0130",
  },
  "0131": {
    name: "Osceola County Health Department",
    location: {
      city: "Osceola",
      state: "FL"
    },
    website: "https://osceola.floridahealth.gov/",
    id: "0131",
  },
  "0132": {
    name: "Healthcare Network",
    location: {
      city: "Immokalee",
      state: "FL"
    },
    website: "https://healthcareswfl.org/",
    id: "0132",
  },
  "0133": {
    name: "Lee Health Systems",
    location: {
      city: "Fort Myers",
      state: "FL"
    },
    website: "https://www.leehealth.org/",
    id: "0133",
  },
  "0134": {
    name: "AdventHealth South",
    location: {
      city: "Orlando",
      state: "FL"
    },
    website: "https://www.adventhealth.com/hospital/adventhealth-orlando/our-location",
    id: "0134",
  },
  "0135": {
    name: "AdventHealth Winter Park",
    location: {
      city: "Winter Park",
      state: "FL"
    },
    website: "https://www.adventhealth.com/adventhealth-graduate-medical-education/family-medicine-allopathic-residency",
    id: "0135",
  },
  "0136": {
    name: "AdventHealth East",
    location: {
      city: "Orlando",
      state: "FL"
    },
    website: "https://www.adventhealth.com/hospital/adventhealth-east-orlando",
    id: "0136"
  },
  "0137": {
    name: "Orlando Regional Medical Center",
    location: {
      city: "Orlando",
      state: "FL"
    },
    website: "https://www.orlandohealth.com/facilities/orlando-regional-medical-center",
    id: "0137"
  }
}