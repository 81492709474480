/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  CreateAccount.js
*/
import React, { useState, useEffect } from "react";
import { Form, Modal, Button, Spinner } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { fireAuth, fireFuncs, fireStore } from "../../../../../Fire";
import { getAuth, createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from "firebase/auth";
import { sleep } from "../../../../../resources/Functions";
import "./CreateAccount.css";
import { collection, doc, getDocs, setDoc, where, query, writeBatch, getDoc } from "firebase/firestore";
import { schools } from './../../../../../resources/InstitutionData';
import styles from './CreateAccount.module.css';
import appStyles from './../../../../../AppStyles.module.css';
import SchoolsModal from "./components/SchoolsModal";
import AddSchoolForm from "./components/AddSchoolForm";
import { httpsCallable } from "firebase/functions";
import TermsConditionsModal from "../../../../terms-conditions-modal/TermsConditionsModal";
import PrivacyPolicyModal from "../../../../privacy-policy-modal/PrivacyPolicyModal";
import UsernamePrivacyModal from "./components/UsernamePrivacyModal";

const DEV = window.location.hostname === 'localhost';

const CreateAccount = (props) => {
  const { 
    handleSwitch,       // func - used to switch to log in
    handleClose,        // func - called when closing modal
    handleDone,
    setMode
  } = props;
  const allowCreateAccount = true;           // when false, user will be asked to create an account in the mobile app and then log in here
  // state vars
  // used to display loading spinner in done button
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({
    email: "",
    username: "",
    mailingList: true,
    pw1: "",
    pw2: "",
    agreement: false,
  });
  const [errors, setErrors] = useState({
    email: "",
    username: "Please enter a username.",
    pw1: "",
    pw2: "",
    agreement: "This box is required."
  });
  const [modal, setModal] = useState({
    schools: {show: false},
    addSchool: {show: false},
    terms: {show: false},
    privacy: {show: false},
    unprivacy: {show: false},
  })

  useEffect(() => {
    if (fireAuth.currentUser == null && allowCreateAccount) {
      document.getElementById("email").focus();
    }
  }, []);

  const validate = (prop, criteria, errorMessage) => {
    const message = criteria() ? "" : errorMessage;
    document.getElementById(prop).setCustomValidity(message);
    setErrors({ ...errors, [prop]: message });
    return criteria();
  };

  const multiValidate = (params) => {
    if (!Array.isArray(params)) {
      console.error(
        "multiValidate() Must be passsed an array of parameter objects"
      );
    }
    let newErrors = {};
    params.every(obj => {
      if (!obj.criteria()) {
        newErrors[obj.prop] = obj.errorMessage;
        validate(obj.prop, () => false, obj.errorMessage);
        return true;
      }
      validate(obj.prop, () => true, obj.errorMessage);
      return true;
    })
    setErrors(prev => ({...prev, ...newErrors}));
  };

  const validateFuncs = {
    email: (val) =>
      multiValidate([
        {
          prop: "email",
          criteria: () => /.+@.+\..+/.test(val),
          errorMessage: "Please enter a valid email."
        },
        // {
        //   prop: "email",
        //   criteria: () => (emails.includes(val.replace(/.*(@.*)/g, "$1")) || emailExceptions.includes(val)),
        //   errorMessage: "Sorry, you must have an email address with one of our supported schools."
        // }
      ]),
    username: (val) =>
      validate('username', () => true, 'Please enter a username.'),
    pw1: (val) =>
      multiValidate([
        {
          prop: "pw1",
          criteria: () => 12 <= val.length && val.length <= 30,
          errorMessage: "Please enter a valid password.",
        },
        {
          prop: "pw2",
          criteria: () => val === state.pw2,
          errorMessage: "Passwords do not match.",
        },
      ]),
    pw2: (val) =>
      validate("pw2", () => val === state.pw1, "Passwords do not match."),
    agreement: (val) => validate("agreement", () => val, "This box is required."),
  };

  const handleTextChange = (prop) => {
    return (event) => {
      let val = event.target.value;
      validateFuncs[prop](val);
      setState({ ...state, [prop]: val });
    };
  };

  const handleCheckChange = (prop) => {
    if (prop === 'agreement') {
      validateFuncs[prop](!state[prop]);
      setState({ ...state, [prop]: !state[prop]})
    } else {
      setState({ ...state, [prop]: !state[prop] });
    }
  };

  const checkUsername = async () => {
    const checkUsername = httpsCallable(fireFuncs, 'checkUsername');
    const result = await checkUsername({username: state.username.toLowerCase()});
    const valid = result.data.valid;
    console.log("RESULT", result);
    validate('username', () => valid, 'Username already exists');
    return valid;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setLoading(true);
    multiValidate([
      {
        prop: "email",
        criteria: () => /.+@.+\..+/.test(state.email),
        errorMessage: "Please enter a valid email",
      },
      {
        prop: 'username',
        criteria: () => state.username.length > 0, 
        errorMessage: "Please enter a username."
      },
      {
        prop: "pw1",
        criteria: () => 12 <= state.pw1.length && state.pw1.length <= 30,
        errorMessage: "Please enter a valid password.",
      },
      {
        prop: "pw2",
        criteria: () => state.pw1 === state.pw2,
        errorMessage: "Passwords do not match.",
      },
      {
        prop: "pw2",
        criteria: () => state.pw2 === state.pw1,
        errorMessage: "Passwords do not match",
      },
      {
        prop: "agreement",
        criteria: () => state.agreement,
        errorMessage: "This box is required."
      }
    ]);
    const validUsername = await checkUsername();
    const valid = form.checkValidity() && validUsername;
    console.log("valid", valid);
    if (!valid) {
      event.stopPropagation();
      setLoading(false);
    } else {
      // triggers spinner in done button
      const username = state.username.toLowerCase();
      createUserWithEmailAndPassword(fireAuth, state.email, state.pw1)
        .then(async (userCredential) => {
          updateProfile(fireAuth.currentUser, {
            displayName: username
          }).catch((e) => {
            console.error(e);
          });
          // verify email address
          if (!DEV) {
            // const customSendEmailVerification = httpsCallable(fireFuncs, 'sendVerificationEmail');
            // customSendEmailVerification().then(result => {
            //   console.log("Success", result.data.message);
            // })
          }
          // set up user data
          const uid = fireAuth.currentUser.uid;
          const currDate = Date.now();
          const userDoc = {
            email: state.email,
            school: (Object.values(schools).find(obj => {
              return obj.email === state.email.replace(/.*(@.*)/g, "$1")
            }) || {}).name || "TEST - no name",
            username: username,
            dateAdded: currDate,
            uid: uid
          };
          const batch = writeBatch(fireStore);
          const userRef = doc(fireStore, 'users', uid);
          // set user doc in collection users
          batch.set(userRef, {...userDoc, mailingList: state.mailingList});
          // set mailingList preferences in collection mailingList
          if (state.mailingList) {
            const mailRef = doc(fireStore, 'mailingList', uid);
            batch.set(mailRef, {
              dateAdded: currDate,
              email: state.email
            });
          }
          // commit batch
          await batch.commit().catch(error => {
            console.log("Error committing user batch", error);
          });
          setLoading(false);
          if (!DEV) {
            // If requiring verified email, uncomment this code
            // setMode('verify');
            handleDone();
          } else {
            handleDone();
          }
        })
        .catch((e) => {
          // email is already in use
          console.warn(e.code);
          if (e.code === "auth/email-already-in-use") {
            validate("email", () => false, "An account with this email already exists.");
            setLoading(false);
          }
          console.error(e);
        })
    }
    setValidated(true);
  };

  const openModal = (prop) => {
    return (e) => {
      e.stopPropagation();
      setModal(prev => ({...prev, [prop]: {show: true}}));
    }
  }

  let content = (
    <>
      <h1 className={styles.title}>Create Account</h1>
      <Form.Group className="mb-3">
        {/* <Form.Label>Email</Form.Label> */}
        <Form.Control
          id="email"
          required
          type="email"
          placeholder="Email"
          value={state.email}
          onChange={handleTextChange("email")}
        />
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">
          {errors.email}
        </Form.Control.Feedback>
        {/* <Form.Text muted>You must have an email domain from a <a className={styles.schoolsModalLink}onClick={openModal('schools')}>supported medical school.</a></Form.Text> */}
        <Form.Text muted>Email domains from a <a className={styles.schoolsModalLink}onClick={openModal('schools')}>supported medical school</a> are preferred.</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3">
        {/* <Form.Label>Username</Form.Label> */}
        <Form.Control
          id="username"
          required
          type="text"
          value={state.username}
          onChange={handleTextChange('username')}
          placeholder="Username"
        />
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">
          {errors.username}
        </Form.Control.Feedback>
        <Form.Text muted>Your privacy is our #1 priority. <a className={styles.schoolsModalLink}onClick={openModal('unprivacy')}>Protect it</a></Form.Text>
      </Form.Group>
      <Form.Group className="mb-3">
        {/* <Form.Label>Password</Form.Label> */}
        <Form.Control
          id="pw1"
          required
          type="password"
          placeholder="Password"
          value={state.pw1}
          onChange={handleTextChange("pw1")}
        />
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">
          {errors.pw1}
        </Form.Control.Feedback>
        <Form.Text muted>
          Password must be 12-30 characters in length.
        </Form.Text>
      </Form.Group>
      <Form.Group className="mb-3">
        {/* <Form.Label>Confirm Password</Form.Label> */}
        <Form.Control
          id="pw2"
          required
          type="password"
          placeholder="Re-enter password"
          value={state.pw2}
          onChange={handleTextChange("pw2")}
        />
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">
          {errors.pw2}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check
          type="checkbox"
          checked={state.mailingList}
          onChange={() => handleCheckChange("mailingList")}
          label="Add me to your mailing list"
          className={appStyles.checkBox}
        />
        <Form.Text muted>
          We'll give you a heads up when new updates and features are
          available. <br />
          We won't spam you.
        </Form.Text>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check
          type="checkbox"
          checked={state.agreement}
          onChange={() => handleCheckChange("agreement")}
          id="agreement"
          label={<>
            I agree to the <a 
              className={appStyles.link} 
              onClick={() => setModal(prev => ({...prev, terms: {show: true}}))}
            >Terms of Use</a> and <a 
              className={appStyles.link} 
              onClick={() => setModal(prev => ({...prev, privacy: {show: true}}))}
            >Privacy Policy</a>.
          </>}
          className={appStyles.checkBox}
        />
        <Form.Control.Feedback type="invalid">
          {errors.agreement}
        </Form.Control.Feedback>
      </Form.Group>
      <div>
        <div>
          <Button 
            className={styles.doneBtn+" "+appStyles.primaryBtn}
            variant="secondary" 
            type="submit" 
            disabled={loading}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <>Done</>
            )}
          </Button>
        </div>
        <div>
          Already have an account? <Button 
            className={styles.liBtn+" "+appStyles.link}
            variant="link" 
            onClick={handleSwitch}
          >
            Log In
          </Button>
        </div>
      </div>
    </>
  );

  if (!allowCreateAccount) {
    content = (<>
      <Modal.Body>
        <p className="ca-user-notice">
          Please create an account in the mobile app and then return here to log in 
          and gain editor access.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleSwitch}>
          Log In
        </Button>
        <Button 
          variant="secondary" 
          className={appStyles.primaryBtn}
          type="button" 
          onClick={handleClose}
        >
          Done
        </Button>
      </Modal.Footer>
    </>)
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {content}
      </Form>
      {modal.schools.show && <SchoolsModal
        show={modal.schools.show}
        onHide={() => setModal(prev => ({...prev, schools: {show: false}}))}
        openForm={() => setModal(prev => ({...prev, schools: {show: false}, addSchool: {show: true}}))}
      />}
      {modal.addSchool.show && <AddSchoolForm
        show={modal.addSchool.show}
        onHide={() => setModal(prev => ({...prev, addSchool: {show: false}}))}
      />}
      {modal.terms.show && <TermsConditionsModal
        show={modal.terms.show}
        onHide={() => setModal(prev => ({...prev, terms: {show: false}}))}
      />}
      {modal.privacy.show && <PrivacyPolicyModal
        show={modal.privacy.show}
        onHide={() => setModal(prev => ({...prev, privacy: {show: false}}))}
      />}
      {modal.unprivacy.show && <UsernamePrivacyModal
        show={modal.unprivacy.show}
        onHide={() => setModal(prev => ({...prev, unprivacy: {show: false}}))}
      />}
    </>
  );
};

export default CreateAccount;
