/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Mission.js
*/

import React, { useEffect, useState } from 'react';
import styles from './Mission.module.css';

export default function Mission(props) {
  const {

  } = props;

  return <>
    <div className={styles.container}>
      <div className={styles.main}>
        <h2 className={styles.title}>Transforming Clinical Education</h2>
        <h3 className={styles.subtitle}>Be Informed, Make an Impact, and Excel in Your Clinical Rotations.</h3>
        <p className={styles.description}>
          Welcome to inClinicals, the groundbreaking app that empowers medical students to make informed decisions and leave lasting impressions during their clinical rotations. With inClinicals, you gain access to invaluable insights and reviews shared by fellow students, enabling you to hold preceptors accountable and make informed choices. By harnessing this knowledge, you can maximize your learning opportunities, excel in your rotations, and leave a positive and lasting impression. Join us in revolutionizing clinical education and take control of your future today.
        </p>
      </div>
    </div>
  </>
};