import React, { useEffect, useState } from 'react';
import styles from "./FlagSelector.module.css";

export default function FlagSelector(props) {
  const {
    className,    // str - main div className
    flags,        // obj - keys are db flag values and values are display strings
    selected,     // arr - contains keys from flags obj that are selected
    setState,     // func - sets state of parent page. make sure to pass a function that changes an obj 
                  //        that has 'flags' as a key
    type,          // str - "Rotation" or "Preceptor"
    color,        // str - "Red" or undefined
    prop
  } = props;
  const [flag, setFlag] = useState(null);

  useEffect(() => {
    if (flag) {
      setState(prev => {
        let arr = prev[prop];
        if (arr.includes(flag)) {
          arr = arr.filter(val => val !== flag);
        } else {
          arr.push(flag);
        }
        return {
          ...prev,
          [prop]: arr
        }
      })
      setFlag(null);
    }
  }, [flag])

  return <>
    <div className={styles.container+" "+className}>
      <div className={styles.flagsFlex}>
        {Object.keys(flags).map((key, i) => (
          <button 
            className={styles.flagContainer+" "+styles[color]}
            type="button"
            onClick={() => setFlag(key)}
            key={key}
          >
            <div className={styles.flagText}>{flags[key]}</div>
            <div className={styles.flagCheck+" "+(selected.includes(key) ? styles.checked : "")}>
              <i className="bi-check" />
            </div>
          </button>
        ))}
      </div>
    </div>
  </>
}