/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  SuggestionSearch.js
*/

import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { sleep } from './../../resources/Functions';
import styles from './SuggestionSearch.module.css';

export default function SuggestionSearch(props) {
  const {
    options,
    value,
    onChange,
    onClick,
    noOptionsText,
    placeholder,
    id,
    required
  } = props;
  const [show, setShow] = useState(false);
  const [intValue, setIntValue] = useState(value);
  const [selected, setSelected] = useState('');

  let found = 0;
  const filteredOptions = Object.values(options).filter(opt => {
    if (value === '') return false;
    if ((new RegExp(value, "i")).test(opt.name)) {
      found += 1;
      return true;
    }
  })

  useEffect(() => {
    setIntValue(value);
    if (value.length > 0) {
      if (value !== selected) {
        setShow(true);
      } else {
        setSelected('');
      }
    } else {
      setShow(false);
    }
  }, [value])

  useEffect(() => {
    onChange({target: {value: intValue}});
  }, [intValue])

  const handleClick = (name) => {
    return () => {
      setSelected(name);
      setShow(false);
      setIntValue(name);
      onClick(name);
    }
  }

  const handleChange = e => {
    setIntValue(e.target.value);
  }

  const handleBlur = async () => {
    // TECH DEBT - if the click-in click-out motion takes longer than 50 ms, then setting selected won't work
    await sleep(100);
    setShow(false);
  }

  const handleFocus = () => {
    if (value.length > 0 && value !== selected) {
      setShow(true);
    }
  }

  return <>
    <Form.Control
      required={required}
      value={intValue}
      onChange={handleChange}
      type="text"
      id={id}
      placeholder={placeholder}
      autocomplete="off"
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
    <div className={styles.suggestionsWrapper+" "+(show ? '' : 'd-none')}>
      <div className={styles.suggestions}>
        {filteredOptions.length === 0 &&
          <div className={styles.suggestionBtn+" "+styles.empty}>{noOptionsText}</div>
        }
        {filteredOptions.map((opt, i) => {
          if (i <= 5) return (
            <div>
              <button
                type="button"
                className={styles.suggestionBtn}
                onMouseDown={handleClick(opt.name)}
              >
                {opt.name.replace(new RegExp(value, "gi"), "$&")}
              </button>
            </div>
          )
        })}
      </div>
    </div>
  </>
};