/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Banner.js
*/

import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import styles from './Banner.module.css';
import appStyles from './../../../../AppStyles.module.css';
import { Link } from 'react-router-dom';

export default function Banner(props) {
  const {

  } = props;

  return <>
    <div className={styles.banner}>
      <div className={styles.content}>
        <img className={styles.brand} src="/branding/brand.svg"/>
        <h2 className={styles.slogan}>Enhance your clinical experience</h2>
        <div className={styles.btnWrapper}>
          <Button as={Link} to='/create' variant="secondary" state={{type: 'write'}} className={appStyles.primaryBtn}>Write a Review</Button>
        </div>
      </div>
    </div>
  </>
};