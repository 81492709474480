/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Star.js
*/

import React, { useEffect, useState } from 'react';
import styles from './Star.module.css';

export default function Star(props) {
  const {
    color,
    scale
  } = props;
  const sizing = {
    a: {
      margin: 5 * scale,
      borderRight: 10 * scale,
      borderBottom: 7 * scale,
      borderLeft: 10 * scale,
    },
    b: {
      borderBottom: 8 * scale,
      borderLeft: 3 * scale,
      borderRight: 3 * scale,
      top: 4.5 * scale,
      left: 6.5 * scale,
    },
    c: {
      top: 0.3 * scale,
      left: 10.5 * scale,
      borderRight: 10 * scale,
      borderBottom: 7 * scale,
      borderLeft: 10 * scale,
    }
  }

  return <>
    <div className={styles.starWrapper}>
      <div className={styles.star} style={{
        margin: `${sizing.a.margin}px 0`,
        borderRight: `${sizing.a.borderRight}px solid transparent`,
        borderBottom: `${sizing.a.borderBottom}px solid ${color}`,
        borderLeft: `${sizing.a.borderLeft}px solid transparent`,
      }}>
        <div className={styles.star1} style={{
          borderBottom: `${sizing.b.borderBottom}px solid ${color}`,
          borderLeft: `${sizing.b.borderLeft}px solid transparent`,
          borderRight: `${sizing.b.borderRight}px solid transparent`,
          top: `-${sizing.b.top}px`,
          left: `-${sizing.b.left}px`,
        }}></div>
        <div className={styles.star2} style={{
          color: color,
          top: `${sizing.c.top}px`,
          left: `-${sizing.c.left}px`,
          borderRight: `${sizing.c.borderRight}px solid transparent`,
          borderBottom: `${sizing.c.borderBottom}px solid ${color}`,
          borderLeft: `${sizing.c.borderLeft}px solid transparent`,
        }}></div>
      </div>
      
    </div>
  </>
}