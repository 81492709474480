/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  UsernamePrivacyModal.js
*/
import React, { useEffect, useState } from "react";
import { Modal, } from "react-bootstrap";
import { sleep } from "../../../../../../resources/Functions";
import styles from './UsernamePrivacyModal.module.css';
import appStyles from './../../../../../../AppStyles.module.css';

export default function UsernamePrivacyModal(props) {
  const { show, onHide } = props;
  const [intShow, setIntShow] = useState(show);

  useEffect(() => {
    setIntShow(show);
  }, [show]);

  const handleHide = () => {
    setIntShow(false);
    sleep(500).then(() => onHide());
  };

  return <>
    <Modal
      show={intShow}
      onHide={handleHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className={styles.header}>
            <img className={styles.brand} src="/branding/brand.svg" />
            <h1 className={appStyles.h1}>Choosing a Username</h1>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ol className={styles.list}>
          <li className={styles.li}>
            <span className={styles.bold}>Use a Pseudonym:</span> Select a username that is entirely unrelated to your real name or any identifiable information. Avoid using nicknames or handles that are commonly associated with you.
          </li>
          <li className={styles.li}>
            <span className={styles.bold}>Avoid Identifying Details:</span> Ensure that your chosen username does not contain any clues about your identity, such as your school, graduation year, or specialty.
          </li>
          {/* <li className={styles.li}>
            <span className={styles.bold}>Think Long-Term:</span> Consider the potential consequences of your username choice on your future career. Remember that residency programs and employers may review your online presence.
          </li> */}
          <li className={styles.li}>
            <span className={styles.bold}>Maintain Consistency:</span> Use the same pseudonym or username consistently across the platform to avoid accidentally revealing your identity.
          </li>
          <li className={styles.li}>
            <span className={styles.bold}>Regularly Review and Update:</span> Periodically review your account settings and information to ensure that your privacy remains protected. If you inadvertently share any identifiable information, take steps to remove or change it.
          </li>
          {/* uncomment this when we actually have the feature to report bad faith comments */}
          {/* <li className={styles.li}>
            <span className={styles.bold}>Report Misuse:</span> If you come across any reviews or usernames that violate privacy guidelines or engage in inappropriate behavior, report them to the app administrators or moderators for review and action.
          </li> */}
        </ol>
        <p>By following these privacy guidelines, you can provide valuable feedback while safeguarding your personal information and professional reputation.</p>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  </>
};