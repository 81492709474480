/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  PreceptorReviewBlock.js
*/

import React, { useEffect, useState } from 'react';
import SmallStars from '../stars/small-stars/SmallStars';
import { Timeline, RecordVoiceOver, AccountCircle, ThumbUp, ContentPasteSearch } from '@mui/icons-material';
import { getMonthAbrv, truncateText } from '../../resources/Functions';
import styles from './Reviews.module.css';
import appStyles from './../../AppStyles.module.css';

export default function PreceptorReviewBlock(props) {
  const { 
    rev, 
    preceptor,
    i,
    showModal
  } = props;

  const [showMore, setShowMore] = useState(false);

  const handleShow = e => {
    e.preventDefault();
    showModal(rev);
  }

  let date = rev.data().date;
  // if postOnDate is after date and date is after Nov 7 when feature was introduced,
  // then change publish date to postOnDate.
  if (date < rev.data().postOnDate && date > new Date("November 7, 2023 12:00:00 PM").getTime()) {
    date = rev.data().postOnDate;
    const dateInst = new Date(date);
    date = `${getMonthAbrv(dateInst.getMonth())} ${dateInst.getFullYear()} (Delayed post)`
  } else {
    const dateInst = new Date(date);
    date = `${getMonthAbrv(dateInst.getMonth())} ${dateInst.getFullYear()}`
  }

  return (
    <div key={i} className={styles.reviewContainer}>
      <div className={styles.reviewHeader}>
        <div className={styles.reviewAuthorContainer}>
          <div className={styles.reviewAuthorWrapper}>
            <AccountCircle className={styles.authorIcon} sx={{fontSize: 20}}/>
            <h3 className={styles.reviewAuthor}>{rev.data().authorUsername}</h3>
          </div>
          <p className={styles.reviewDate}>{date}</p>
        </div>
        <div className={styles.reviewRatings}>
          <div className={styles.reviewRating}>
            <ThumbUp className={styles.labelIcon} sx={{fontSize: 16}}/>
            <SmallStars val={rev.data().ratings.overallSatisfaction} />
          </div>
          <div className={styles.reviewRating}>
            <ContentPasteSearch className={styles.labelIcon} sx={{fontSize: 16}}/>
            <SmallStars val={rev.data().ratings.difficultyOfExpectations} />
          </div>
          <div className={styles.reviewRating}>
            <RecordVoiceOver className={styles.labelIcon} sx={{fontSize: 16}}/>
            <SmallStars val={rev.data().ratings.recLikelihood} />
          </div>
        </div>
      </div>
      <div className={styles.textContainer}>
        <p className={styles.text}>
          {showMore && rev.data().review}
          {!showMore && truncateText(rev.data().review, 115)}
        </p>
        <a href="" className={appStyles.link} onClick={handleShow}>{showMore ? "Show Less" : "Show More"}</a>
      </div>
    </div>
  )
};