import React, { Component } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import PrivateRoute from './PrivateRoute';
import Homepage from './components/pages/homepage/Homepage';
import RotationPage, { rotationPageLoader } from './components/pages/rotation-page/RotationPage';
import PreceptorPage, { preceptorPageLoader } from './components/pages/preceptor-page/PreceptorPage';
import ReviewPage, { reviewPageLoader } from './components/pages/review-page/ReviewPage';
import Error from "./components/pages/error/Error";
import { AuthProvider } from "./Auth";
import AuthPage from './components/pages/auth-page/AuthPage';
import CreateTarget from './components/pages/create-target/CreateTarget';
import AccountPage from './components/pages/account-page/AccountPage';
import PromptPage from './components/pages/create-target/prompt-page/PromptPage';
import AboutPage from './components/pages/about-page/AboutPage';
import ContactPage from './components/pages/contact-page/ContactPage';
import DevPage from './components/pages/dev-page/DevPage';
// import PrivateRoute from './PrivateRoute';

export default function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Homepage />
    },
    {
      path: "/rotations/:rotID",
      element: <RotationPage />,
      loader: rotationPageLoader
    },
    {
      path: "/preceptors/:precID",
      element: <PreceptorPage />,
      loader: preceptorPageLoader
    },
    {
      path: "/review/:docID",
      element: <ReviewPage />,
      loader: reviewPageLoader
    },
    {
      path: "/auth",
      element: <AuthPage />
    },
    {
      path: '/create',
      element: <CreateTarget />,
    },
    {
      path: '/create/prompt',
      element: <PromptPage />,
    },
    {
      path: '/account',
      element: <AccountPage />,
    },
    {
      path: '/about',
      element: <AboutPage />,
    },
    {
      path: '/contact',
      element: <ContactPage />,
    },
    // {
    //   path: '/dev',
    //   element: <DevPage />,
    // }
  ])

  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  )
}
