export const frequencies = {
  1: 'Minimal',
  2: 'Some',
  3: 'Average',
  4: 'Frequently',
  5: 'Excessive'
}

export const RotationReviewPositives = {
  supportiveCommunity: "Supportive community",
  strongLearningPlans: "Strong Learning Plans & Goals",
  uninterruptedInternet: "Uninterrupted Internet",
  diverseDemographic: "Diverse Demographic",
  diversePathologies: "Diverse Pathologies",
  friendlyTeam: "Friendly Team",
  safeEnvironment: "Safe Environment",
  presentationRequired: "End of Rotation Presentation Required",
  reasonableExpectations: "Reasonable Expectations",
  foodProvided: "Food Provided",
  housingProvided: "Housing Provided",
  parkingProvided: "Parking Provided",
  wellnessPromoted: "Wellness Promoted",
}

export const RotationReviewNegatives = {
  poorInternetSignal: "Poor Internet Signal",
  overworkedTeam: "Overworked Team",
  unresponsiveLeadership: "Unresponsive Leadership",
  unsupportedResidents: "Unsupported Residents",
  punitiveTactics: "Punitive Tactics",
  discriminatory: "Discriminatory",
  hostileEnvironment: "Hostile Environment",
  expensiveAccommodations: "Expensive Accommodations",
  inaccessibleStaff: "Inaccessible Staff",
}

export const PreceptorReviewFlags = {
  handsOnParticipation: "Hands-On Participation",
  requiresReading: "Requires Reading",
  constructiveFeedback: "Provides Constructive Feedback",
  engagesWithStudents: "Engages With Students",
  reasonableExpectations: "Has Reasonable Expectations",
  fairEvaluations: "Performs Thoughtful and Fair Evaluations",
  encouragesProblemSolving: "Encourages Problem Solving",
}

export const preceptorTopStrengths = {
  detailOriented: "Detail-Oriented",
  kind: "Kind",
  respectful: "Respectful",
  funny: "Funny",
  accessible: "Accessible",
  knowledgeable: "Knowledgeable",
  communicative: "Communicative",
  approachable: "Approachable",
  available: "Available",
  supportive: "Supportive",
  knowledgeImparter: "Knowledge Imparter",
  excellentInterpersonalSkills: "Excellent Interpersonal Skills",
  professional: "Professional",
  explainsConceptsEasily: "Explains Concepts Easily",
  activeListener: "Active Listener",
  lifelongLearner: "Life-long Learner",
  goodTimeManagement: "Good Time Management",
  understanding: "Understanding",
  encouragesAutonomy: "Encourages Autonomy",
}

export const preceptorWeaknesses = {
  impatient: "Impatient",
  unforgiving: "Unforgiving",
  intimidating: "Intimidating",
  selfish: "Selfish",
  burnedOut: "Burned Out",
  unsympathetic: "Unsympathetic",
  arrogant: "Arrogant",
  poorBedsideManners: "Poor Bedside Manners",
  neglectful: "Neglectful",
  insensitive: "Insensitive",
  lazy: "Lazy",
  inconsistent: "Inconsistent",
  pessimistic: "Pessimistic",
  passiveAggressive: "Passive Aggressive",
  egocentric: "Egocentric",
  noSupport: "Provides Little or No Support",
}