import React, { useState, useEffect } from 'react';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { fireStore } from '../../../Fire';
import { Button } from 'react-bootstrap';
import { Link, Navigate, useLoaderData } from 'react-router-dom';
import NavBar from '../../nav-bar/NavBar';
import ReviewModal from '../../review-modal/RotationReviewModal';
import styles from "./../rotation-page/RotationPage.module.css";
import appStyles from './../../../AppStyles.module.css';
import SmallStars from '../../stars/small-stars/SmallStars';
import { RecordVoiceOver, ContentPasteSearch, ThumbUp, Web, LocalOffer } from '@mui/icons-material';
import Reviews from "./../../reviews/Reviews";
import { makeTitleFromCamel, getFlagSize } from '../../../resources/Functions';
import { institutions } from '../../../resources/InstitutionData';

const tempPrecepFlags = {
  interruptedInternet: 3,
  competitiveAdmission: 2,
  friendlyTeam: 10,
  reasonableExpectations: 100,
  narrowResidencyChoice: 50
}
const tempPrecepStrengths = {
  kind: 10,
  respected: 5,
  funny: 2,
  accessible: 20,
  knowledgeable: 23,
  available: 15
}

export async function preceptorPageLoader({ params }) {
  const {precID} = params;
  return {
    precID: precID
  }
}

export default function PreceptorPage(props) {
  const data = useLoaderData();
  const { precID } = data;
  const [precep, setPrecep] = useState(null);
  const [pInstitutions, setPInstitutions] = useState(null);
  const [shownInstKey, setShownInstKey] = useState(0);
  const [redirect, setRedirect] = useState({go: false, path: ""});
  const [modal, setModal] = useState({
    review: {
      show: false
    }
  });

  useEffect(() => {
    // (async () => {
    //   const docRef = doc(fireStore, "preceptors", precID);
    //   const docSnap = await getDoc(docRef);

    //   if (docSnap.exists()) {
    //     setPrecep(docSnap.data());
    //   } else {
    //     console.error("This preceptor id does not exist");
    //     setRedirect({go: true, path: "/"})
    //   }
    // })()
    const unsub = onSnapshot(doc(fireStore, 'preceptors', precID), (doc) => {
      if (doc.exists()) {
        if (doc.data().rotations) {
          let insts = [];
          Object.values(doc.data().rotations).forEach(rot => {
            const instName = institutions[rot.institutionID].name;
            if (!insts.includes(instName)) {
              insts.push(instName);
            }
          });
          setPInstitutions(insts);
        }
        setPrecep(doc.data())
      } else {
        console.error("This preceptor id does not exist");
        setRedirect({go: true, path: "/"})
      }
    })
    return unsub;
  }, []);
  
  useEffect(() => {
    if (pInstitutions) {
      const interval = setInterval(() => {
        setShownInstKey(prev => {
          if (prev === pInstitutions.length - 1) {
            return 0;
          } else return prev + 1;
        });
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [pInstitutions])

  const openReviewModal = () => {
    setModal(prev => ({
      ...prev,
      review: {show: true}
    }))
  }

  if (redirect.go) return <Navigate to={redirect.path} />

  const stats = ((precep || {}).stats || {});
  const ratings = ((stats || {}).ratings || {});
  let flags = {};
  const numberFlagsShown = 5;
  if (stats.flags) {
    let keysArr = Object.keys(stats.flags)
      .sort((a, b) => stats.flags[b] - stats.flags[a])
      .filter((_, i) => i < numberFlagsShown);
    keysArr.forEach(key => flags[key] = stats.flags[key]);
  }
  let strengths = {};
  const numberStrengthsShown = 5;
  if (stats.strengths) {
    let keysArr = Object.keys(stats.strengths)
      .sort((a, b) => stats.strengths[b] - stats.strengths[a])
      .filter((_, i) => i < numberStrengthsShown);
    keysArr.forEach(key => strengths[key] = stats.strengths[key]);
  }
  let weaknesses = {};
  const numberWeaknessesShown = 3;
  if (stats.weaknesses) {
    let keysArr = Object.keys(stats.weaknesses)
      .sort((a, b) => stats.weaknesses[b] - stats.weaknesses[a])
      .filter((_, i) => i < numberWeaknessesShown);
    keysArr.forEach(key => weaknesses[key] = stats.weaknesses[key]);
  }

  return <>
    <div className={styles.container}>
      <div className={styles.navWrapper}>
        <NavBar />
      </div>
      {precep && <div className={styles.page}>
        <div className={styles.cover+" "+styles.precep}>
          <h1 className={styles.title}>{precep.fName} {precep.lName}</h1>
          {pInstitutions && <div className={styles.instWrapper}>
            {pInstitutions.map((inst, i) => (
              <h2 
                key={inst} 
                className={
                  styles.institution+" "+styles.precep+" "+(shownInstKey === i ? styles.shown : "")
                }
              >{pInstitutions[i]}</h2>
            ))}
          </div>}
          {/* <h3 className={styles.location}>{precep.location.city}, {precep.location.state}</h3> */}
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <div className={styles.data}>
              <div className={styles.pair}>
                <p className={styles.labelWrapper}>
                  <span className={styles.label}>NPI: </span>
                  <a className={styles.value+" "+appStyles.link} target="_blank" href={`https://npiregistry.cms.hhs.gov/provider-view/${precep.npi}`}>{precep.npi}</a>
                </p>
              </div>
              {ratings.overallSatisfaction !== undefined && <div className={styles.pair+" "+styles.edges}>
                <div className={styles.flexLabelWrapper}>
                  <ThumbUp className={styles.labelIcon} sx={{fontSize: 16}}/>
                  <h4 className={styles.label}>Overall Satisfaction: </h4>
                </div>
                <SmallStars val={ratings.overallSatisfaction} />
              </div>}
              {ratings.difficultyOfExpectations !== undefined && <div className={styles.pair+" "+styles.edges}>
                <div className={styles.flexLabelWrapper}>
                  <ContentPasteSearch className={styles.labelIcon} sx={{fontSize: 16}}/>
                  <h4 className={styles.label}>Reasonable Expectations: </h4>
                </div>
                <SmallStars val={ratings.difficultyOfExpectations} />
              </div>}
              {ratings.recLikelihood !== undefined && <div className={styles.pair+" "+styles.edges}>
                <div className={styles.flexLabelWrapper}>
                  <RecordVoiceOver className={styles.labelIcon} sx={{fontSize: 16}}/>
                  <h4 className={styles.label}>Likelihood to Recommend: </h4>
                </div>
                <SmallStars val={ratings.recLikelihood} />
              </div>}
              {stats.chooseAgain !== undefined && <div className={styles.pair}>
                <p className={styles.labelWrapper}>
                  <span className={styles.label}>Would choose again: </span>
                  <span className={styles.value}>{stats.chooseAgain}% said yes</span>
                </p>
              </div>}
              {stats.flags && <div className={styles.flagsContainer}>
                <div className={styles.flexLabelWrapper}>
                  <LocalOffer className={styles.labelIcon} sx={{fontSize: 16}}/>
                  <h4 className={styles.label}>Tags:</h4>
                </div>
                <div className={styles.flags}>
                  {Object.keys(flags).map(flag => (
                    <div
                      key={flag}
                      className={styles.flag}
                      style={{
                        fontSize: getFlagSize(flags[flag], flags)
                      }}
                    >
                      {makeTitleFromCamel(flag)} ({flags[flag]})
                    </div>
                  ))}
                </div>
              </div>}
              {stats.strengths && <div className={styles.flagsContainer}>
                <div className={styles.flexLabelWrapper}>
                  <LocalOffer className={styles.labelIcon} sx={{fontSize: 16}}/>
                  <h4 className={styles.label}>Strengths:</h4>
                </div>
                <div className={styles.flags}>
                  {Object.keys(strengths).map(flag => (
                    <div
                      key={flag}
                      className={styles.flag+" "+styles.strength}
                      style={{
                        fontSize: getFlagSize(strengths[flag], strengths)
                      }}
                    >
                      {makeTitleFromCamel(flag)} ({strengths[flag]})
                    </div>
                  ))}
                </div>
              </div>}
              {stats.weaknesses && <div className={styles.flagsContainer}>
                <div className={styles.flexLabelWrapper}>
                  <LocalOffer className={styles.labelIcon} sx={{fontSize: 16}}/>
                  <h4 className={styles.label}>Weaknesses:</h4>
                </div>
                <div className={styles.flags}>
                  {Object.keys(weaknesses).map(flag => (
                    <div
                      key={flag}
                      className={styles.flag+" "+styles.weakness}
                      style={{
                        fontSize: getFlagSize(weaknesses[flag], weaknesses)
                      }}
                    >
                      {makeTitleFromCamel(flag)} ({weaknesses[flag]})
                    </div>
                  ))}
                </div>
              </div>}
              {/* <div className={styles.pair}>
                <Web className={styles.labelIcon} sx={{fontSize: 16}}/>
                <div className={styles.value}>
                  <a target="_blank" href={precep.website}>Institution Website</a>
                </div>
              </div> */}
            </div>
            <div className={styles.reviews}>
              <Reviews document={precep} openReviewModal={openReviewModal} type={"preceptors"}/>
            </div>
          </div>
        </div>
      </div>}
    </div>
    {modal.review.show && <ReviewModal 
      show={modal.review.show}
      onHide={() => setModal(prev => ({
        ...prev,
        review: {show: false}
      }))}
      docId={precID}
    />}
  </>
}
