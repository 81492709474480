/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  LogIn.js
*/
import React, { useState, useEffect, useContext } from "react";
import { Form, Modal, Button, Spinner } from "react-bootstrap";
import { fireAuth } from "../../../../../Fire";
import Alert, { emptyAlert } from "../../../../alert/Alert";
import ForgotPassword from "./forgot-password/ForgotPassword";
import { Navigate } from "react-router-dom";
import { sleep } from "../../../../../resources/Functions";
import { AuthContext } from "../../../../../Auth";
import "./LogIn.css";
import { signInWithEmailAndPassword } from "firebase/auth";
import styles from './LogIn.module.css';
import appStyles from './../../../../../AppStyles.module.css';

const LogIn = (props) => {
  const { 
    handleSwitch,         // func - handle switching to create account
    handleDone
  } = props;
  const user = useContext(AuthContext);

  // boolean - used for showing validation result
  const [validated, setValidated] = useState(false);
  // obj - holds values for input fields
  const [state, setState] = useState({
    email: "",
    pw: "",
  });
  // obj - holds values for errors from validation
  const [errors, setErrors] = useState({
    email: "Please enter a valid email.",
    pw: "Please enter your password.",
  });
  // boolean - manages showing reset password modal
  const [showPwResetModal, setShowPwResetModal] = useState(false);
  // obj - manages alert modal
  const [alert, setAlert] = useState({ emptyAlert });
  // obj - manages redirecting programmatically
  const [redirect, setRedirect] = useState({
    done: false,
    path: "",
  });
  // boolean - used for showing spinner in the submit button
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) {
      document.getElementById("email").focus();
    }
  }, []);

  /* 
    used to validate all form fields and handle error messaging
    prop: str - state/field prop to validate
    criteria: func - called to check prop
    errorMessage: str - error to be shown if !criteria()
  */
  const validate = (prop, criteria, errorMessage) => {
    const message = criteria() ? "" : errorMessage;
    document.getElementById(prop).setCustomValidity(message);
    setErrors({ ...errors, [prop]: message });
  };

  // handle changes for all input fields
  const handleChange = (prop) => {
    return (event) => {
      if (prop === "email") {
        validate(
          "email",
          () => /.+@.+\..+/.test(event.target.value),
          "Please enter a valid email."
        );
      } else if (prop === "pw") {
        validate("pw", () => true, "");
      }
      setState({ ...state, [prop]: event.target.value });
    };
  };

  // log in user after submitting email and password
  const handleLogin = (event) => {
    // prevent default submit options and start our own
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setLoading(true);
      // log in user
      signInWithEmailAndPassword(fireAuth, state.email, state.pw)
        .then((user) => {
          // wait a half second and then redirect to done path
          sleep(500).then(() => {
            handleDone();
          });
        })
        .catch((error) => {
          setLoading(false);
          if (error.code === "auth/user-not-found") {
            validate(
              "email",
              () => false,
              "A user with this email does not exist."
            );
          } else if (error.code === "auth/wrong-password") {
            validate("pw", () => false, "This password is incorrect.");
          }
        });
    }
    setValidated(true);
  };

  if (redirect.done) {
    return <Navigate to={redirect.path} />;
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleLogin}>
        <h1 className={styles.title}>Log In</h1>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            id="email"
            required
            type="email"
            placeholder="Email"
            value={state.email}
            onChange={handleChange("email")}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            id="pw"
            required
            type="password"
            placeholder="Password"
            value={state.pw1}
            onChange={handleChange("pw")}
          />
          <Form.Control.Feedback type="invalid">
            {errors.pw}
          </Form.Control.Feedback>
        </Form.Group>
        <div className={styles.btnContainer}>
          <div>
            <Button 
              className={styles.doneBtn+" "+appStyles.primaryBtn}
              variant="secondary" 
              type="submit" 
              disabled={loading}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>Done</>
              )}
            </Button>
          </div>
          <div>
            <Button
              className={"log-in-create-account-link "+styles.fpBtn+" "+appStyles.link}
              type="button"
              variant="link"
              onClick={() => {
                setShowPwResetModal(true);
              }}
            >
              Forgot Password?
            </Button>
          </div>
          <div>
            <Button
              className={styles.caBtn+" "+appStyles.link}
              variant="link"
              onClick={handleSwitch}
              type="button"
            >
              Create Account
            </Button>
          </div>
        </div>
      </Form>
      <ForgotPassword
        show={showPwResetModal}
        handleClose={() => setShowPwResetModal(false)}
        handleDone={() => {
          setShowPwResetModal(false);
          setAlert({
            show: true,
            title: "Email sent",
            message: "A password reset email has been sent. Be sure to check your spam folder.",
            confirmCallback: () => {
              window.location.reload();
            },
          });
        }}
      />
      {alert.show && (
        <Alert
          show={alert.show}
          title={alert.title}
          message={alert.message}
          confirmCallback={alert.confirmCallback}
        />
      )}
    </>
  );
};

export default LogIn;
