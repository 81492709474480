/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  PrivacyPolicyModal.js
*/
import React, { useEffect, useState } from "react";
import { Modal, } from "react-bootstrap";
import { sleep } from "../../resources/Functions";
import { privacyPolicy } from "./PrivacyPolicyContent";
import faq from './../faq-modal/FaqModal.module.css';
import uuid from "react-uuid";

export default function PrivacyPolicyModal(props) {
  const { show, onHide } = props;
  const [intShow, setIntShow] = useState(show);

  useEffect(() => {
    setIntShow(show);
  }, [show]);

  const handleHide = () => {
    setIntShow(false);
    sleep(500).then(() => onHide());
  };

  return <>
    <Modal
      show={intShow}
      onHide={handleHide}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div><img className={faq.brand} src="/branding/brand.svg" /></div> 
          Privacy Policy
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Thank you for using inClinicals. This privacy policy explains how we collect, use, and protect the information you provide to us when you use our web app.</p>
        {privacyPolicy.map(s => (
          <div key={s.title} className={faq.group}>
            <h3 className={faq.question}>{s.title}</h3>
            {s.content.map(p => (
              <p key={uuid()} className={faq.answer}>{p}</p>
            ))}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  </>
};