/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  SearchWindow.js
*/

import React, { useEffect, useState } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import PreceptorsView from './components/PreceptorsView';
import RotationsView from './components/RotationsView';
import { Link } from 'react-router-dom';
import styles from './SearchWindow.module.css';
import appStyles from './../../../../../AppStyles.module.css';
import SearchBar from './components/SearchBar';
import { capitalizeEachLetter } from '../../../../../resources/Functions';

const NoResultsMessage = props => {
  const { type } = props;
  return (
    <div className={styles.emptyWrapper}>
      <div className={styles.empty}>
        <h3 className={styles.emptyTitle}>No {capitalizeEachLetter(type)} Found</h3>
        <p className={styles.text}>
          Sorry, there are no {type} that match these parameters. Think there should be? <Link className={appStyles.link} to="/create" state={{type: type}}>Create a new one</Link>
        </p>
      </div>
    </div>
  )
}

export default function SearchWindow(props) {
  const {

  } = props;

  // 'rotations' or 'preceptors'
  const [tab, setTab] = useState('rotations');
  const [discs, setDiscs] = useState([]);
  const [states, setStates] = useState([]);
  const [inst, setInst] = useState('');

  const handleTabClick = val => {
    return () => setTab(val);
  }

  return <>
    <div className={styles.container}>
      <div className={styles.header}>
        {/* <h2 className={styles.title}>Reviews</h2> */}
        <div className={styles.tabs}>
          <button 
            className={styles.tab+" "+(tab === 'rotations' ? styles.active : "")} 
            onClick={handleTabClick('rotations')}
          >Rotations</button>
          <button 
            className={styles.tab+" "+(tab === 'preceptors' ? styles.active : "")} 
            onClick={handleTabClick('preceptors')}
          >Preceptors</button>
          <div className={styles.tabCover} />
        </div>
        <div className={styles.searchWrapper}>
          <SearchBar 
            onDiscChange={setDiscs}
            onStatesChange={setStates}
            onInstChange={setInst}
          />
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          {tab === 'rotations' && 
            <RotationsView discs={discs} states={states} inst={inst}>
              <NoResultsMessage type={tab} />
            </RotationsView>
          }
          {tab === 'preceptors' && 
            <PreceptorsView discs={discs} states={states} inst={inst}>
              <NoResultsMessage type={tab} />
            </PreceptorsView>
          }
        </div>
        <div className={styles.bottomText}>
          Don't see your {tab === 'rotations' ? "rotation" : "preceptor"}? {
            <Link 
              className={appStyles.link}
              to="/create"
              state={{type: tab}}
            >Create a new one</Link>
          }
        </div>
      </div>
    </div>
  </>
};