/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  UserReviews.js
*/

import { collectionGroup, getDocs, orderBy, query, where, limit, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { fireStore } from '../../../../Fire';
import { Button, Spinner } from 'react-bootstrap';
import PreceptorReviewBlock from '../../../reviews/PreceptorReviewBlock';
import RotationReviewBlock from '../../../reviews/RotationReviewBlock';
import RotationReviewModal from '../../../review-modal/RotationReviewModal';
import PreceptorReviewModal from '../../../review-modal/PreceptorReviewModal';
import { sleep } from '../../../../resources/Functions';
import Alert from '../../../alert/Alert';
import appStyles from './../../../../AppStyles.module.css';
import styles from './UserReviews.module.css';

export default function UserReviews(props) {
  const {
    user,
    userData
  } = props;

  const [reviews, setReviews] = useState(null);
  const [revCap, setRevCap] = useState(3);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modal, setModal] = useState({
    review: {
      show: false
    }
  });
  // used to show Alert modals on page
  const [alert, setAlert] = useState({ show: false });

  useEffect(() => {
    (async () => {
      const revs = query(collectionGroup(fireStore, 'reviews'), where('author', '==', user.uid), orderBy("date", "desc"), limit(revCap));
      const querySnapshot = await getDocs(revs);
      let arr = [];
      querySnapshot.forEach((doc) => {
        arr.push(doc)
      })
      console.log("REVIEWS", arr);
      setReviews(arr);
    })();
  }, [user, revCap, refresh])

  const deleteReview = async (collection, targetId, revId) => {
    await deleteDoc(doc(fireStore, `${collection}/${targetId}/reviews`, revId))
      .then(() => setRefresh(prev => !prev))
      .catch(error => console.error("Error deleting review", error));
  }

  const handleDelete = (collection, targetId, revId) => {
    setAlert({
      show: true,
      title: 'Confirmation Needed',
      message: 'Are you sure you want to delete this review? This action cannot be undone',
      confirmCallback: () => {
        setAlert({show: false});
        deleteReview(collection, targetId, revId);
      },
      actionNeeded: true,
      confirmText: 'Delete Review',
      variant: 'danger',
      cancelCallback: () => setAlert({show: false})
    })
  }

  let reviewElements = [];
  if (reviews) {
    reviews.forEach((doc, i) => {
      reviewElements.push(doc.data().type === 'rotation' ?
        <RotationReviewBlock
          key={doc.id}
          rev={doc}
          i={i}
          showModal={(rev) => {
            setModal(prev => ({...prev, review: {
              show: true, 
              rev: rev, 
              type: 'rotation',
              targetId: doc.data().targetId
            }}))
          }}
        /> :
        <PreceptorReviewBlock
          key={doc.id}
          rev={doc}
          i={i}
          showModal={(rev) => {
            setModal(prev => ({...prev, review: {
              show: true, 
              rev: rev, 
              type: 'preceptor',
              targetId: doc.data().targetId
            }}))
          }}
          targetId={doc.data().targetId}
        />
      )
    })
  }

  return <>
    <h2 className={styles.title}>
      Reviews {(userData.reviewCount !== undefined) && 
        <span className={styles.titleCount}>({userData.reviewCount})</span>
      }
    </h2>
    {reviews && <div>
      {reviewElements}  
    </div>}
    <div className={''}>
      {revCap < userData.reviewCount &&
        <Button
          variant="secondary"
          className={appStyles.primaryBtn}
          onClick={() => setRevCap(prev => prev + 3)}
        >{loading ? 
          <Spinner animation="border" size='sm' /> :
          "Get More"
        }</Button>
      }
    </div>
    {(modal.review.type === 'rotation' && modal.review.show) && <RotationReviewModal 
      show={modal.review.show}
      rev={modal.review.rev}
      onHide={() => setModal(prev => ({...prev, review: {show: false}}))}
      targetId={modal.review.targetId}
      onProfile
      handleDelete={handleDelete}
    />}
    {(modal.review.type === 'preceptor' && modal.review.show) && <PreceptorReviewModal 
      show={modal.review.show}
      rev={modal.review.rev}
      onHide={() => setModal(prev => ({...prev, review: {show: false}}))}
      targetId={modal.review.targetId}
      onProfile
      handleDelete={handleDelete}
    />}
    {alert.show && <Alert {...alert} />}
  </>
};