/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Footer.js
*/

import React, { useEffect, useState } from 'react';
import styles from './Footer.module.css';
import appStyles from './../../AppStyles.module.css';
import { Link } from 'react-router-dom';
import FaqModal from '../faq-modal/FaqModal';

export default function Footer(props) {
  const {

  } = props;

  const [modal, setModal] = useState({
    faq: {show: false}
  })

  return <>
    <div className={styles.container}>
      <img className={styles.brand} src="/branding/brand.svg" />
      <a 
        className={appStyles.link+" "+styles.item}
        onClick={() => setModal(prev => ({...prev, faq: {show: true}}))}
      >Read our FAQ</a>
      <a 
        className={appStyles.item+" "+appStyles.link}
        href="https://www.instagram.com/inclinicals/"
        target="_blank"
      >
        <img className={styles.igLogo} src="/icons/instagram.png" />
        inClinicals
      </a>
    </div>
    {modal.faq.show && <FaqModal
      show={modal.faq.show}
      onHide={() => setModal(prev => ({...prev, faq: {show: false}}))}
    />}
  </>
};