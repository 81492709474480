/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  SearchBar.js
*/

import React, { useEffect, useState } from 'react';
import { Form, InputGroup, Button, Tabs, Tab } from 'react-bootstrap';
import { disciplines, institutions } from '../../../../../../resources/InstitutionData';
import { stateAbrvs } from '../../../../../../resources/StateAbrvs';
import SuggestionSearch from '../../../../../suggestion-search/SuggestionSearch';
import InstitutionsModal from '../../../../create-target/components/InstitutionsModal';
import InstFormModal from '../../../../create-target/components/InstFormModal';
import styles from './SearchBar.module.css';
import appStyles from './../../../../../../AppStyles.module.css';
import { truncateText } from '../../../../../../resources/Functions';

const sortedInst = Object.values(institutions).sort((a, b) => {
  if (a.name > b.name) {
    return 1;
  } else if (a.name < b.name) {
    return -1;
  } else return 0;
});

export default function SearchBar(props) {
  const {
    onDiscChange,
    onStatesChange,
    onInstChange
  } = props;

  const [discs, setDiscs] = useState([]);
  const [states, setStates] = useState([]);
  const [inst, setInst] = useState('');
  const [checksOpen, setChecksOpen] = useState(false);
  const [modal, setModal] = useState({
    institutions: {show: false},
    instForm: {show: false}
  })

  useEffect(() => {
    onDiscChange(discs);
  }, [discs])

  useEffect(() => {
    onStatesChange(states);
  }, [states])

  const handleCheckChange = name => {
    return () => {
      if (discs.includes(name)) {
        // remove from array
        console.log(`removing ${name} from array`)
        setDiscs(prev => {
          let arr = [...prev];
          arr.splice(arr.indexOf(name), 1);
          return arr;
        })
      } else {
        // add to array
        console.log(`adding ${name} to array`)
        setDiscs(prev => ([...prev, name]))
      }
    }
  }

  const handleDiscCheckChangeExclusive = (name) => {
    return () => {
      if (discs.includes(name)) {
        setDiscs([]);
      } else {
        setDiscs([name]);
      }
      // setChecksOpen(false);
    }
  }

  const handleStateCheckChangeExclusive = (name) => {
    return () => {
      if (states.includes(name)) {
        setStates([]);
      } else {
        setStates([name]);
      }
      // setChecksOpen(false);
    }
  }

  const openModal = (modal) => {
    return () => {
      setModal(prev => ({...prev, [modal]: {show: true}}));
    }
  }

  const handleSearchSubmit = (str) => {
    return (e) => {
      if (e) e.preventDefault();
      document.getElementById('inst').blur();
      if (str) {
        onInstChange(str);
      } else {
        onInstChange(inst);
      }
    }
  }

  let stateSet = new Set();
  Object.values(institutions).forEach(inst => {
    stateSet.add(inst.location.state);
  })
  let statesArr = [];
  stateSet.forEach(value => statesArr.push(value));
  statesArr.sort((a, b) => {
    if (a > b) {
      return 1;
    } else if (a < b) {
      return -1;
    } else return 0;
  })
  let discArr = Object.values(disciplines).sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    } else return 0;
  })

  return <>
    <Form
      noValidate
      onSubmit={handleSearchSubmit()}
    >
      <InputGroup className={styles.inputGroup}>
        {/* <Form.Control
          placeholder="Search"
          value={value}
          onChange={onChange}
        /> */}
        <SuggestionSearch
          required
          options={sortedInst}
          value={inst}
          onChange={(e) => setInst(e.target.value)}
          onClick={(name) => handleSearchSubmit(name)()}
          noOptionsText={
            <>Sorry, we don't support this institution. <a className={appStyles.link} onMouseDown={openModal('institutions')}>See supported institutions</a></>
          }
          placeholder="Search by Institutions"
          id="inst"
        />
        <Button 
          variant="secondary"
          id="button-addon2"
          className={styles.filterButton}
          onClick={() => setChecksOpen(prev => !prev)}
        >
          <i className="bi-funnel-fill" />
        </Button>
        <div className={styles.anchor+" "+(checksOpen ? '' : styles.hidden)}>
          <div className={styles.filterOptions+" "+(checksOpen ? '' : styles.hidden)}>
            <Tabs
              defaultActiveKey="disc"
              className={"ap-tabs"+" "+styles.tabs}
            >
              <Tab eventKey="disc" title="Disciplines" className={styles.tab}>
                {discArr.map(disc => (
                  <Form.Check
                    type="checkbox"
                    checked={discs.includes(disc.name)}
                    onChange={handleDiscCheckChangeExclusive(disc.name)}
                    label={disc.name}
                  />
                ))}
              </Tab>
              <Tab eventKey="states" title="States" className={styles.tab}>
                {statesArr.map(state => (
                  <Form.Check
                    type="checkbox"
                    checked={states.includes(state)}
                    onChange={handleStateCheckChangeExclusive(state)}
                    label={stateAbrvs[state] || state}
                  />
                ))}
              </Tab>
            </Tabs>
          </div>
        </div>
      </InputGroup>
    </Form>
    <div className={styles.filters}>
      {inst.length > 0 && <div className={styles.filter}>
        <div>
          Institution: {truncateText(inst, 10)}
        </div>
        <button 
          type="button" 
          className={styles.filterBtn}
          onClick={() => {
            setInst('');
            onInstChange('');
          }}
        >
          <i className="bi-x" />
        </button>
      </div>}
      {discs.length > 0 && <div className={styles.filter}>
        <div>
          Discipline: {truncateText(discs[0], 10)}
        </div>
        <button 
          type="button" 
          className={styles.filterBtn}
          onClick={() => setDiscs([])}
        >
          <i className="bi-x" />
        </button>
      </div>}
      {states.length > 0 && <div className={styles.filter}>
        <div>
          State: {states[0]}
        </div>
        <button 
          type="button" 
          className={styles.filterBtn}
          onClick={() => setStates([])}
        >
          <i className="bi-x" />
        </button>
      </div>}
    </div>
    {modal.institutions.show && <InstitutionsModal
      show={modal.institutions.show}
      onHide={() => setModal(prev => ({...prev, institutions: {show: false}}))}
      openForm={() => setModal(prev => ({...prev, institutions: {show: false}, instForm: {show: true}}))}
    />}
    {modal.instForm.show && <InstFormModal
      show={modal.instForm.show}
      onHide={() => setModal(prev => ({...prev, instForm: {show: false}}))}
    />}
  </>
};