/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Features.js
*/

import React, { useEffect, useState } from 'react';
import StarIcon from '@mui/icons-material/Star';
import InsightsIcon from '@mui/icons-material/Insights';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import ScheduleIcon from '@mui/icons-material/Schedule';
import styles from './Features.module.css';

const features = [
  {
    icon: <div className={styles.stars}>
      <StarIcon className={styles.starIcon} />
      <StarIcon className={styles.starIcon} />
      <StarIcon className={styles.starIcon} />
      <StarIcon className={styles.starIcon} />
      <StarIcon className={styles.starIcon} />
    </div>,
    title: <>
      Rotation Rating
    </>,
    description: <>
      Rate and provide feedback on your clinical rotations and preceptors. Evaluate teaching quality, organization, workload, and more.
    </>
  },
  {
    icon: <InsightsIcon className={styles.icon} />,
    title: <>
      Comprehensive Criteria
    </>,
    description: <>
      Rate rotations based on mentorship, patient exposure, learning opportunities, communication, and professionalism.
    </>
  },
  {
    icon: <LocalOfferIcon className={styles.icon} />,
    title: <>
      Tagging System
    </>,
    description: <>
      Enhance reviews with descriptive tags like supportive environment, challenging workload, hands-on experience, and teamwork.
    </>
  },
  {
    icon: <PersonOffIcon className={styles.icon} />,
    title: <>
      Anonymous Reviews
    </>,
    description: <>
      Share experiences openly with anonymous reviews, fostering transparency and authenticity.
    </>
  },
  {
    icon: <LeaderboardIcon className={styles.icon} />,
    title: <>
      Aggregated Reviews
    </>,
    description: <>
      Access average ratings and read individual experiences from fellow students to make informed decisions.
    </>
  },
  // {
  //   icon: <ScheduleIcon className={styles.icon} />,
  //   title: <>
  //     Delayed Posting
  //   </>,
  //   description: <>
  //     Choose when your review will post, maintaining discretion and anonymity.
  //   </>
  // },
]

export default function Features(props) {
  const {

  } = props;

  return <>
    <div className={styles.main}>
      <h2 className={styles.title}>inClinicals Features</h2>
      <div className={styles.features}>
        {features.map(f => (
          <div key={f.title} className={styles.feature}>
            {f.icon}
            <h3 className={styles.featureTitle}>{f.title}</h3>
            <p className={styles.featureDesc}>{f.description}</p>
          </div>
        ))}
      </div>
    </div>
  </>
};