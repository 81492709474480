/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  PreceptorForms.js
*/

import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import FlagSelector from '../../../flag-selector/FlagSelector';
import { PreceptorReviewFlags, preceptorTopStrengths, preceptorWeaknesses, RotationReviewFlags } from '../../../../resources/ReviewData';
import { placeholders } from '../resources/Placeholders';
import BinaryInput from '../../../binary-input/BinaryInput';
import StarsForm from '../../../stars/stars-form/StarsForm';
import styles from './../ReviewPage.module.css';
import appStyles from './../../../../AppStyles.module.css';
import { Link } from 'react-router-dom';
import { institutions } from '../../../../resources/InstitutionData';

const Footer = props => {
  const {
    decrementStep,
    step,
    submitting,
  } = props;
  return <div className={styles.footer}>
    <div className={styles.btnWrapper}>
      <Button
        onClick={decrementStep}
        className={styles.stepButton+" "+styles.precep}
        variant="secondary"
        disabled={step === 1}
      >Prev</Button>
      <Button
        type="submit"
        className={styles.stepButton+" "+styles.precep}
        variant="secondary"
      >
        {submitting && <Spinner animation="border" size="sm" />}
        {!submitting && <>{step === 6 ? "Submit" : "Next"}</>}
      </Button>
    </div>
    <div className={styles.stepWrapper}>
      <p className={styles.stepText}>Step {step} of 6</p>
    </div>
  </div>
}

export const Section1 = props => {
  const {
    state,
    setState,
    onSubmit,
    decrementStep,
    doc,
    showGuidelines
  } = props;

  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({
    rotation: 'This question is required.'
  });

  const handleChange = prop => {
    return e => {
      validate(prop, () => e.target.value !== '0', 'This question is required');
      setState(prev => ({...prev, [prop]: e.target.value}))
    }
  }

  const validateAll = () => {
    validate('rotation', () => state.rotation > 0, "This question is required.");
  }

  const validate = (prop, criteria, errorMessage) => {
    const message = criteria() ? "" : errorMessage;
    try {
      document.getElementById(prop).setCustomValidity(message);
    } catch (error) {
      console.warn("No input to set validity for.")
    }
    setErrors(prev => ({ ...prev, [prop]: message }));
    return criteria();
  };

  const formIsValid = () => {
    let valid = state.rotation !== 0;
    return valid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const valid = form.checkValidity() && formIsValid();
    validateAll();
    setValidated(true);
    if (valid) {
      onSubmit();
    } else {}
  }

  return <>
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.formContent}>
        <div className={styles.questions}>
          <div className={styles.questionSec}>
            <Form.Group className="mb-3">
              <Form.Label>Which rotation did you have this preceptor for?</Form.Label>
              <Form.Select
                value={state.rotation}
                onChange={handleChange('rotation')}
                id="rotation"
              >
                <option value="0">Select Rotation</option>
                {Object.values(doc.rotations).map(rot => (
                  <option value={rot.id}>{rot.discipline} - {institutions[rot.institutionID].name}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.rotation}
              </Form.Control.Feedback>
              <Form.Text>
                Don't see your rotation? <Link 
                  className={appStyles.link} 
                  to="/create" 
                  state={{npi: doc.npi, type: 'preceptors'}}
                >Create a new one</Link>
              </Form.Text>
            </Form.Group>
            <h4 className={styles.questionTitle}>Select all that apply to Dr. {doc.lName}:</h4>
            <FlagSelector
              flags={PreceptorReviewFlags}
              type="Rotation"
              selected={state.flags}
              setState={setState}
              prop='flags'
            />
          </div>
        </div>
        <Footer decrementStep={decrementStep} step={1}/>
      </div>
    </Form>
  </>
}

export const Section2 = props => {
  const {
    state,
    setState,
    onSubmit,
    decrementStep,
    doc,
    showGuidelines
  } = props;

  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (e) => {
    onSubmit();
  }

  return <>
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.formContent}>
        <div className={styles.questions}>
          <div className={styles.questionSec}>
            <h4 className={styles.questionTitle}>Select all of Dr. {doc.lName}' strengths:</h4>
            <FlagSelector
              flags={preceptorTopStrengths}
              type="Rotation"
              selected={state.strengths}
              setState={setState}
              prop='strengths'
            />
          </div>
        </div>
        <Footer decrementStep={decrementStep} step={2}/>
      </div>
    </Form>
  </>
}

export const Section3 = props => {
  const {
    state,
    setState,
    onSubmit,
    decrementStep,
    doc,
    showGuidelines
  } = props;

  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (e) => {
    onSubmit();
  }

  return <>
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.formContent}>
        <div className={styles.questions}>
          <div className={styles.questionSec}>
            <h4 className={styles.questionTitle}>Select all of Dr. {doc.lName}' top weaknesses:</h4>
            <FlagSelector
              flags={preceptorWeaknesses}
              type="Rotation"
              color='red'
              selected={state.weaknesses}
              setState={setState}
              prop='weaknesses'
            />
          </div>
        </div>
        <Footer decrementStep={decrementStep} step={3}/>
      </div>
    </Form>
  </>
}

export const Section4 = props => {
  const {
    state,
    setState,
    onSubmit,
    decrementStep,
    doc,
    showGuidelines
  } = props;
  const [validated, setValidated] = useState(false);

  const handleChange = (prop) => {
    return (e) => {
      const str = e.target.value;
      if (prop === 'improvement') {
        if (str.length <= 250) {
          setState(prev => ({...prev, [prop]: str}));
        }
      } else if (prop === 'expectations') {
        if (str.length <= 250) {
          setState(prev => ({...prev, [prop]: str}));
        }
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    onSubmit();
  }

  return <>
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.formContent}>
        <div className={styles.questions}>
          <div className={styles.questionSec}>
            <Form.Group className="mb-3">
              <Form.Label>How could Dr. {doc.lName} improve?</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={state.improvement}
                onChange={handleChange('improvement')}
                placeholder={placeholders.preceptorImprovement}
              />
              <Form.Text>{state.improvement.length}/250</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>What are Dr. {doc.lName}'s general expectations?</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={state.expectations}
                onChange={handleChange('expectations')}
                placeholder={placeholders.preceptorExpectations}
              />
              <Form.Text>{state.expectations.length}/250</Form.Text>
            </Form.Group>
          </div>
        </div>
        <Footer decrementStep={decrementStep} step={4} />
      </div>
    </Form>
  </>
}

export const Section5 = props => {
  const {
    state,
    setState,
    onSubmit,
    decrementStep,
    showGuidelines
  } = props;
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    onSubmit();
  }

  return <>
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.formContent}>
        <div className={styles.questions}>
          <div className={styles.questionSec}>
            <Form.Group className="mb-3">
              <Form.Label>What are your general thoughts?</Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                value={state.review}
                onChange={e => setState(prev => ({...prev, review: e.target.value}))}
                placeholder={placeholders.preceptorReview}
              />
              <Form.Text>This will be shown on the preview of your review.</Form.Text>
            </Form.Group>
          </div>
        </div>
        <Footer decrementStep={decrementStep} step={5} />
      </div>
    </Form>
  </>
}

export const Section6 = props => {
  const {
    state,
    setState,
    onSubmit,
    decrementStep,
    submitting,
    doc,
    showGuidelines
  } = props;

  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({
    overallSatisfaction: '',
    difficultyOfExpectations: '',
    recommend: '',
    chooseAgain: '',
  })

  const formIsValid = () => {
    let valid = state.overallSatisfaction !== 0 && 
      state.clinicalTraining !== 0 && 
      state.recommend !== 0 &&
      state.chooseAgain !== '0';
    return valid;
  }

  const handleStarChange = prop => {
    return (val) => {
      validate(prop, () => 0 < val && val <= 5, 'Must select a rating')
      setState(prev => ({...prev, [prop]: val}))
    };
  }

  const handleChange = prop => {
    return e => {
      validate(prop, () => e.target.value !== '0', 'This question is required');
      setState(prev => ({...prev, [prop]: e.target.value}))
    }
  }

  const validateAll = () => {
    validate('overallSatisfaction', () => state.overallSatisfaction > 0, 'Must select a rating');
    validate('difficultyOfExpectations', () => state.difficultyOfExpectations > 0, 'Must select a rating');
    validate('recommend', () => state.recommend > 0, 'Must select a rating');
    validate('chooseAgain', () => state.chooseAgain !== '0', 'This question is required.')
  }

  const validate = (prop, criteria, errorMessage) => {
    const message = criteria() ? "" : errorMessage;
    try {
      document.getElementById(prop).setCustomValidity(message);
    } catch (error) {
      console.warn("No input to set validity for.")
    }
    setErrors(prev => ({ ...prev, [prop]: message }));
    return criteria();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const valid = form.checkValidity() && formIsValid();
    validateAll();
    setValidated(true);
    if (valid) {
      onSubmit(true);
    } else {
    }
  }

  return <>
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.formContent}>
        <div className={styles.questions}>
          <div>
            <h4 className={styles.questionTitle}>Rate the following out of 5:</h4>
            <Form.Group className="mb-3">
              <Form.Label>Overall Satisfaction:</Form.Label>
              <StarsForm
                value={state.overallSatisfaction}
                onChange={handleStarChange('overallSatisfaction')}
              />
              {validated && <Form.Text className={styles.errorText}>
                {errors.overallSatisfaction}
              </Form.Text>}
              <Form.Text>The overall rating for this preceptor is based on this question.</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Difficulty of Expectations:</Form.Label>
              <StarsForm
                value={state.difficultyOfExpectations}
                onChange={handleStarChange('difficultyOfExpectations')}
                id='difficultyOfExpectations'
              />
              {validated && <Form.Text className={styles.errorText}>
                {errors.difficultyOfExpectations}
              </Form.Text>}
              <Form.Control.Feedback type="invalid">
                {errors.overallSatisfaction}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Likelihood to Recommend:</Form.Label>
              <StarsForm
                value={state.recommend}
                onChange={handleStarChange('recommend')}
                id='recommend'
              />
              {validated && <Form.Text className={styles.errorText}>
                {errors.recommend}
              </Form.Text>}
              <Form.Control.Feedback type="invalid">
                {errors.overallSatisfaction}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Would you choose this preceptor again?</Form.Label>
              <BinaryInput
                value={state.chooseAgain}
                onChange={handleChange('chooseAgain')}
                options={[
                  {
                    label: "Yes",
                    value: 'yes',
                    color: '#3f8070',
                    textColor: 'white'
                  },
                  {
                    label: "No",
                    value: 'no',
                    color: '#d32e2e',
                    textColor: 'white'
                  }
                ]}
                id="chooseAgain"
              />
              {validated && <Form.Text className={styles.errorText}>
                {errors.chooseAgain}
              </Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Post Review:</Form.Label>
              <Form.Select
                value={state.whenToPost}
                onChange={handleChange('whenToPost')}
                id="whenToPost"
              >
                <option value={0}>Immediately</option>
                <option value={1}>1 month from now</option>
                <option value={2}>2 months from now</option>
                <option value={3}>3 months from now</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>
        <Footer decrementStep={decrementStep} step={6} submitting={submitting}/>
      </div>
    </Form>
  </>
}