import React, { useState, useEffect } from 'react';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { fireStore } from '../../../Fire';
import { Button } from 'react-bootstrap';
import { Link, Navigate, useLoaderData } from 'react-router-dom';
import NavBar from '../../nav-bar/NavBar';
import Reviews from '../../reviews/Reviews';
import ReviewModal from '../../review-modal/RotationReviewModal';
import styles from "./RotationPage.module.css";
import SmallStars from '../../stars/small-stars/SmallStars';
import { RecordVoiceOver, Timeline, ThumbUp, Web, CalendarMonth, QueryBuilder, LocalOffer, Groups } from '@mui/icons-material';
import { makeTitleFromCamel, getFlagSize } from '../../../resources/Functions';
import { frequencies } from '../../../resources/ReviewData';
import { institutions } from '../../../resources/InstitutionData';

const tempRotationFlags = {
  interruptedInternet: 3,
  competitiveAdmission: 2,
  friendlyTeam: 10,
  reasonableExpectations: 100,
  narrowResidencyChoice: 50
}
const tempPrecepStrengths = {
  kind: 10,
  respected: 5,
  funny: 2,
  accessible: 20,
  knowledgeable: 23,
  available: 15
}

export async function rotationPageLoader({ params }) {
  const {rotID} = params;
  return {
    rotID: rotID
  }
}

export default function RotationPage(props) {
  const data = useLoaderData();
  const { rotID } = data;
  const [rotation, setRotation] = useState(null);
  const [redirect, setRedirect] = useState({go: false, path: ""});
  const [modal, setModal] = useState({
    review: {
      show: false
    }
  });

  useEffect(() => {
    // (async () => {
    //   const docRef = doc(fireStore, "rotations", rotID);
    //   const docSnap = await getDoc(docRef);

    //   if (docSnap.exists()) {
    //     setRotation(docSnap.data());
    //   } else {
    //     console.error("This rotation id does not exist");
    //     setRedirect({go: true, path: "/"})
    //   }
    // })()
    const unsub = onSnapshot(doc(fireStore, 'rotations', rotID), (doc) => {
      if (doc.exists()) {
        setRotation(doc.data())
      } else {
        console.error("This rotation id does not exist");
        setRedirect({go: true, path: "/"})
      }
    })
    return unsub;
  }, [])

  const openReviewModal = () => {
    setModal(prev => ({
      ...prev,
      review: {show: true}
    }))
  }

  if (redirect.go) return <Navigate to={redirect.path} />

  const stats = ((rotation || {}).stats || {});
  const ratings = ((stats || {}).ratings || {});
  let positives = {};
  const numberPositivesShown = 5;
  if (stats.positives) {
    let keysArr = Object.keys(stats.positives)
      .sort((a, b) => stats.positives[b] - stats.positives[a])
      .filter((_, i) => i < numberPositivesShown);
    keysArr.forEach(key => positives[key] = stats.positives[key]);
  }
  let negatives = {};
  const numberNegativesShown = 3;
  if (stats.negatives) {
    let keysArr = Object.keys(stats.negatives)
      .sort((a, b) => stats.negatives[b] - stats.negatives[a])
      .filter((_, i) => i < numberNegativesShown);
    keysArr.forEach(key => negatives[key] = stats.negatives[key]);
  }

  return <>
    <div className={styles.container}>
      <div className={styles.navWrapper}>
        <NavBar />
      </div>
      {rotation && <div className={styles.page}>
        <div className={styles.cover}>
          <h1 className={styles.title}>{rotation.discipline}</h1>
          <h2 className={styles.institution}>{institutions[rotation.institutionID].name}</h2>
          <h3 className={styles.location}>{rotation.location.city}, {rotation.location.state}</h3>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <div className={styles.data}>
              {ratings.overallSatisfaction !== undefined && <div className={styles.pair+" "+styles.edges}>
                <div className={styles.flexLabelWrapper}>
                  <ThumbUp className={styles.labelIcon} sx={{fontSize: 16}}/>
                  <h4 className={styles.label}>Overall Satisfaction: </h4>
                </div>
                <SmallStars val={ratings.overallSatisfaction} />
              </div>}
              {ratings.clinicalTraining !== undefined && <div className={styles.pair+" "+styles.edges}>
                <div className={styles.flexLabelWrapper}>
                  <Timeline className={styles.labelIcon} sx={{fontSize: 16}}/>
                  <h4 className={styles.label}>Clinical Training: </h4>
                </div>
                <SmallStars val={ratings.clinicalTraining} />
              </div>}
              {ratings.culture !== undefined && <div className={styles.pair+" "+styles.edges}>
                <div className={styles.flexLabelWrapper}>
                  <Groups className={styles.labelIcon} sx={{fontSize: 16}}/>
                  <h4 className={styles.label}>Culture: </h4>
                </div>
                <SmallStars val={ratings.culture} />
              </div>}
              {ratings.recLikelihood !== undefined && <div className={styles.pair+" "+styles.edges}>
                <div className={styles.flexLabelWrapper}>
                  <RecordVoiceOver className={styles.labelIcon} sx={{fontSize: 16}}/>
                  <h4 className={styles.label}>Likelihood to Recommend: </h4>
                </div>
                <SmallStars val={ratings.recLikelihood} />
              </div>}
              <div className={styles.pair}>
                <Web className={styles.labelIcon} sx={{fontSize: 16}}/>
                <div className={styles.value}>
                  <a target="_blank" href={rotation.website}>Institution Website</a>
                </div>
              </div>
              {stats.daysPerWeek && <div className={styles.pair}>
                <p className={styles.labelWrapper}>
                  <CalendarMonth className={styles.labelIcon} sx={{fontSize: 16}}/>
                  <span className={styles.label}>Average days per week: </span>
                  <span className={styles.value}>{stats.daysPerWeek}</span>
                </p>
              </div>}
              {stats.averageHoursPerDay && <div className={styles.pair}>
                <p className={styles.labelWrapper}>
                  <QueryBuilder className={styles.labelIcon} sx={{fontSize: 16}}/>
                  <span className={styles.label}>Average hours per day: </span>
                  <span className={styles.value}>{stats.averageHoursPerDay}</span>
                </p>
              </div>}
              {stats.positives && <div className={styles.flagsContainer}>
                <div className={styles.flexLabelWrapper}>
                  <LocalOffer className={styles.labelIcon} sx={{fontSize: 16}}/>
                  <h4 className={styles.label}>Positives:</h4>
                </div>
                <div className={styles.flags}>
                  {Object.keys(positives)
                    .map(flag => (
                      <div
                        key={flag}
                        className={styles.flag}
                        style={{
                          fontSize: getFlagSize(positives[flag], positives)
                        }}
                      >
                        {makeTitleFromCamel(flag)} ({positives[flag]})
                      </div>
                    ))}
                </div>
              </div>}
              {stats.negatives && <div className={styles.flagsContainer}>
                <div className={styles.flexLabelWrapper}>
                  <LocalOffer className={styles.labelIcon} sx={{fontSize: 16}}/>
                  <h4 className={styles.label}>Negatives:</h4>
                </div>
                <div className={styles.flags}>
                  {Object.keys(negatives)
                    .map(flag => (
                      <div
                        key={flag}
                        className={styles.flag+" "+styles.weakness}
                        style={{
                          fontSize: getFlagSize(negatives[flag], negatives)
                        }}
                      >
                        {makeTitleFromCamel(flag)} ({negatives[flag]})
                      </div>
                    ))}
                </div>
              </div>}
              {stats.wasRequired !== undefined && <div className={styles.pair}>
                <p className={styles.labelWrapper}>
                  <span className={styles.label}>Rotation was required: </span>
                  <span className={styles.value}>{stats.wasRequired}% said yes</span>
                </p>
              </div>}
              {stats.singleSite !== undefined && <div className={styles.pair}>
                <p className={styles.labelWrapper}>
                  <span className={styles.label}>Single site rotation: </span>
                  <span className={styles.value}>{stats.singleSite}% said yes</span>
                </p>
              </div>}
              {stats.isCompetitive !== undefined && <div className={styles.pair}>
                <p className={styles.labelWrapper}>
                  <span className={styles.label}>Considered competitive: </span>
                  <span className={styles.value}>{stats.isCompetitive}% said yes</span>
                </p>
              </div>}
              {stats.applyForResidency !== undefined && <div className={styles.pair}>
                <p className={styles.labelWrapper}>
                  <span className={styles.label}>Will apply here for residency: </span>
                  <span className={styles.value}>{stats.applyForResidency}% said yes</span>
                </p>
              </div>}
              {stats.menialWork !== undefined && <div className={styles.pair}>
                <p className={styles.labelWrapper}>
                  <span className={styles.label}>How much menial work was required: </span>
                  <span className={styles.value}>{frequencies[stats.menialWork]}</span>
                </p>
              </div>}
            </div>
            <div className={styles.reviews}>
              <Reviews document={rotation} openReviewModal={openReviewModal} type={"rotations"}/>
            </div>
          </div>
        </div>
      </div>}
    </div>
    {modal.review.show && <ReviewModal 
      show={modal.review.show}
      onHide={() => setModal(prev => ({
        ...prev,
        review: {show: false}
      }))}
      docId={rotID}
    />}
  </>
}
