/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  RotationForms.js
*/

import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import FlagSelector from '../../../flag-selector/FlagSelector';
import { RotationReviewPositives, RotationReviewNegatives, frequencies } from '../../../../resources/ReviewData';
import { placeholders } from '../resources/Placeholders';
import StarsForm from '../../../stars/stars-form/StarsForm';
import styles from './../ReviewPage.module.css';
import BinaryInput from '../../../binary-input/BinaryInput';

const Footer = props => {
  const {
    decrementStep,
    step,
    submitting
  } = props;
  return <div className={styles.footer}>
    <div className={styles.btnWrapper}>
      <Button
        onClick={decrementStep}
        className={styles.stepButton}
        variant="secondary"
        disabled={step === 1}
      >Prev</Button>
      <Button
        type="submit"
        className={styles.stepButton}
        variant="secondary"
      >
        {submitting && <Spinner animation="border" size="sm" />}
        {!submitting && <>{step === 5 ? "Submit" : "Next"}</>}
      </Button>
    </div>
    <div className={styles.stepWrapper}>
      <p className={styles.stepText}>Step {step} of 5</p>
    </div>
  </div>
}

export const Section1 = props => {
  const {
    state,
    setState,
    onSubmit,
    decrementStep
  } = props;

  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (e) => {
    onSubmit();
  }

  return <>
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.formContent}>
        <div className={styles.questions}>
          <div className={styles.questionSec}>
            <h4 className={styles.questionTitle}>Select all the positive aspects of your rotation:</h4>
            <FlagSelector
              flags={RotationReviewPositives}
              type="Rotation"
              selected={state.positives}
              setState={setState}
              prop='positives'
            />
          </div>
        </div>
        <Footer decrementStep={decrementStep} step={1}/>
      </div>
    </Form>
  </>
}

export const Section2 = props => {
  const {
    state,
    setState,
    onSubmit,
    decrementStep
  } = props;

  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (e) => {
    onSubmit();
  }

  return <>
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.formContent}>
        <div className={styles.questions}>
          <div className={styles.questionSec}>
            <h4 className={styles.questionTitle}>Select all the negative aspects of your rotation:</h4>
            <FlagSelector
              flags={RotationReviewNegatives}
              type="Rotation"
              selected={state.negatives}
              setState={setState}
              prop='negatives'
              color='red'
            />
          </div>
        </div>
        <Footer decrementStep={decrementStep} step={2}/>
      </div>
    </Form>
  </>
}

export const Section3 = props => {
  const {
    state, 
    setState,
    onSubmit,
    decrementStep
  } = props;

  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({
    daysPerWeek: '',
    averageHoursPerDay: ''
  })

  const validate = (prop, criteria, errorMessage) => {
    const message = criteria() ? "" : errorMessage;
    try {
      document.getElementById(prop).setCustomValidity(message);
    } catch (error) {
      console.warn("No input to set validity for.");
    }
    setErrors(prev => ({ ...prev, [prop]: message }));
    return criteria();
  };

  const validateAll = () => {
    validate('daysPerWeek', () => state.daysPerWeek !== '0', 'This question is required');
    validate('averageHoursPerDay', () => state.averageHoursPerDay !== '0', 'This question is required');
    validate('applyForResidency', () => state.applyForResidency !== '0', 'This question is required.')
    validate('wasRequired', () => state.wasRequired !== '0', 'This question is required.')
    validate('singleSite', () => state.singleSite !== '0', 'This question is required.')
    validate('isCompetitive', () => state.isCompetitive !== '0', 'This question is required.')
    validate('menialWork', () => state.menialWork !== '0', 'This question is required.')
  }

  const checkValidity = () => {
    return (
      state.applyForResidency !== '0' &&
      state.wasRequired !== '0' &&
      state.singleSite !== '0' &&
      state.isCompetitive !== '0'
    )
  }

  const handleChange = prop => {
    return e => {
      validate(prop, () => e.target.value !== '0', 'This question is required.');
      setState(prev => ({...prev, [prop]: e.target.value}))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    setValidated(true);
    validateAll();
    const valid = form.checkValidity() && checkValidity();
    if (valid) {
      onSubmit();
    } else {
      e.stopPropagation();
    }
  }

  useEffect(() => {
    console.log("STATE", state);
  }, [state])

  return <>
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.formContent}>
        <div className={styles.questions}>
          <div className={styles.questionSec}>
            <Form.Group className="mb-3">
              <Form.Label>Days Per Week:</Form.Label>
              <Form.Select
                value={state.daysPerWeek}
                onChange={handleChange('daysPerWeek')}
                required
                id="daysPerWeek"
              >
                <option value={0}>Days</option>
                {Array.from({length: 7}, (_, i) => i + 1).map(index => (
                  <option key={index} value={index.toString()}>{index}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.daysPerWeek}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Average Hours Per Day:</Form.Label>
              <Form.Select
                value={state.averageHoursPerDay}
                onChange={handleChange('averageHoursPerDay')}
                required
                id="averageHoursPerDay"
              >
                <option value={0}>Hours</option>
                {Array.from({length: 24}, (_, i) => i + 1).map(index => (
                  <option key={index} value={index.toString()}>{index}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.averageHoursPerDay}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Was this rotation required?</Form.Label>
              <BinaryInput
                value={state.wasRequired}
                onChange={handleChange('wasRequired')}
                options={[
                  {
                    label: "Yes",
                    value: 'yes',
                    color: '#3f8070',
                    textColor: 'white'
                  },
                  {
                    label: "No",
                    value: 'no',
                    color: '#d32e2e',
                    textColor: 'white'
                  }
                ]}
                id="wasRequired"
              />
              {validated && <Form.Text className={styles.errorText}>
                {errors.wasRequired}
              </Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Is your rotation only at one site?</Form.Label>
              <BinaryInput
                value={state.singleSite}
                onChange={handleChange('singleSite')}
                options={[
                  {
                    label: "Yes",
                    value: 'yes',
                    color: '#3f8070',
                    textColor: 'white'
                  },
                  {
                    label: "No",
                    value: 'no',
                    color: '#d32e2e',
                    textColor: 'white'
                  }
                ]}
                id="singleSite"
              />
              {validated && <Form.Text className={styles.errorText}>
                {errors.singleSite}
              </Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Would you consider this program competitive?</Form.Label>
              <BinaryInput
                value={state.isCompetitive}
                onChange={handleChange('isCompetitive')}
                options={[
                  {
                    label: "Yes",
                    value: 'yes',
                    color: '#3f8070',
                    textColor: 'white'
                  },
                  {
                    label: "No",
                    value: 'no',
                    color: '#d32e2e',
                    textColor: 'white'
                  }
                ]}
                id="isCompetitive"
              />
              {validated && <Form.Text className={styles.errorText}>
                {errors.isCompetitive}
              </Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>How much menial work was required?</Form.Label>
              <Form.Select
                value={state.menialWork}
                onChange={handleChange('menialWork')}
                required
                id="menialWork"
              >
                <option value={0}>Select</option>
                <option value={1}>1 - {frequencies[1]}</option>
                <option value={2}>2 - {frequencies[2]}</option>
                <option value={3}>3 - {frequencies[3]}</option>
                <option value={4}>4 - {frequencies[4]}</option>
                <option value={5}>5 - {frequencies[5]}</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.menialWork}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <Footer decrementStep={decrementStep} step={3}/>
      </div>
    </Form>
  </>
}

export const Section4 = props => {
  const {
    state,
    setState,
    onSubmit,
    decrementStep
  } = props;
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    onSubmit();
  }

  return <>
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.formContent}>
        <div className={styles.questions}>
          <div className={styles.questionSec}>
            <Form.Group className="mb-3">
              <Form.Label>Please give a general review:</Form.Label>
              <Form.Control
                as="textarea"
                rows={9}
                value={state.review}
                onChange={e => setState(prev => ({...prev, review: e.target.value}))}
                placeholder={placeholders.rotationReview}
              />
            </Form.Group>
          </div>
        </div>
        <Footer decrementStep={decrementStep} step={4} />
      </div>
    </Form>
  </>
}

export const Section5 = props => {
  const {
    state,
    setState,
    onSubmit,
    decrementStep,
    submitting
  } = props;

  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({
    overallSatisfaction: '',
    clinicalTraining: '',
    recommend: ''
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const valid = form.checkValidity() && formIsValid();
    setValidated(true);
    if (valid) {
      onSubmit(true);
    } else {
      validateAll();
    }
  }

  const formIsValid = () => {
    let valid = state.overallSatisfaction !== 0 && state.clinicalTraining !== 0 && state.recommend !== 0;
    return valid;
  }

  const handleStarChange = prop => {
    return (val) => {
      validate(prop, () => 0 < val && val <= 5, 'Must select a rating')
      setState(prev => ({...prev, [prop]: val}))
    };
  }

  const handleChange = prop => {
    return e => {
      validate(prop, () => e.target.value !== '0', 'This question is required.');
      setState(prev => ({...prev, [prop]: e.target.value}))
    }
  }

  const validateAll = () => {
    validate('overallSatisfaction', () => state.overallSatisfaction > 0, 'Must select a rating');
    validate('clinicalTraining', () => state.clinicalTraining > 0, 'Must select a rating');
    validate('recommend', () => state.recommend > 0, 'Must select a rating');
  }

  const validate = (prop, criteria, errorMessage) => {
    const message = criteria() ? "" : errorMessage;
    // document.getElementById(prop).setCustomValidity(message);
    setErrors(prev => ({ ...prev, [prop]: message }));
    return criteria();
  };

  return <>
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.formContent}>
        <div className={styles.questions}>
          <div>
            <h4 className={styles.questionTitle}>Rate the following out of 5:</h4>
            <Form.Group className="mb-3">
              <Form.Label>Overall Satisfaction:</Form.Label>
              <StarsForm
                value={state.overallSatisfaction}
                onChange={handleStarChange('overallSatisfaction')}
              />
              {validated && <Form.Text className={styles.errorText}>
                {errors.overallSatisfaction}
              </Form.Text>}
              <Form.Text>The overall rating for this rotation is based on this question.</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Clinical Training:</Form.Label>
              <StarsForm
                value={state.clinicalTraining}
                onChange={handleStarChange('clinicalTraining')}
                id='clinicalTraining'
              />
              {validated && <Form.Text className={styles.errorText}>
                {errors.clinicalTraining}
              </Form.Text>}
              <Form.Control.Feedback type="invalid">
                {errors.clinicalTraining}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>How caring and collaborative was the culture:</Form.Label>
              <StarsForm
                value={state.culture}
                onChange={handleStarChange('culture')}
                id='culture'
              />
              {validated && <Form.Text className={styles.errorText}>
                {errors.culture}
              </Form.Text>}
              <Form.Control.Feedback type="invalid">
                {errors.culture}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Likelihood to Recommend:</Form.Label>
              <StarsForm
                value={state.recommend}
                onChange={handleStarChange('recommend')}
                id='recommend'
              />
              {validated && <Form.Text className={styles.errorText}>
                {errors.recommend}
              </Form.Text>}
              <Form.Control.Feedback type="invalid">
                {errors.recommend}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Post Review:</Form.Label>
              <Form.Select
                value={state.whenToPost}
                onChange={handleChange('whenToPost')}
                id="whenToPost"
              >
                <option value={0}>Immediately</option>
                <option value={1}>1 month from now</option>
                <option value={2}>2 months from now</option>
                <option value={3}>3 months from now</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>
        <Footer decrementStep={decrementStep} step={5} submitting={submitting}/>
      </div>
    </Form>
  </>
}