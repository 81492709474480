/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  FaqModal.js
*/
import React, { useEffect, useState } from "react";
import { Modal, } from "react-bootstrap";
import { sleep } from "../../resources/Functions";
import { faqs } from "./FaqContent";
import styles from './FaqModal.module.css';

export default function FaqModal(props) {
  const { show, onHide } = props;
  const [intShow, setIntShow] = useState(show);

  useEffect(() => {
    setIntShow(show);
  }, [show]);

  const handleHide = () => {
    setIntShow(false);
    sleep(500).then(() => onHide());
  };

  return <>
    <Modal
      show={intShow}
      onHide={handleHide}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div><img className={styles.brand} src="/branding/brand.svg" /></div> 
          Frequently Asked Questions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {faqs.map(f => (
          <div key={f.question} className={styles.group}>
            <h3 className={styles.question}>{f.question}</h3>
            <p className={styles.answer}>{f.answer}</p>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  </>
};