import appStyles from './../../AppStyles.module.css';

export const privacyPolicy = [
  {
    title: <>Information We Collect</>,
    content: [
      <>When you register for an inClinicals account, we collect your email address and require you to create a username. We also provide optional fields for you to add your gender, grad year, and date of birth.</>,
      <>We may also collect information about your device and browser, including your IP address, operating system, and browser type.</>
    ]
  },
  {
    title: <>How We Use Your Information</>,
    content: [
      <>We use your email address to create and manage your account, to communicate with you about your account, and to provide you with customer support. We may also use your email address to send you promotional materials or newsletters, but you can opt-out of these communications at any time.</>,
      <>We use your username and any additional information you provide to personalize your inClinicals experience and to help you connect with other users.</>,
      <>We may use your device and browser information to improve our web app's performance, to diagnose and fix technical issues, and to protect our systems from fraud and abuse.</>
    ]
  },
  {
    title: <>How We Share Your Information</>,
    content: [
      <>We will never sell your personal information to third parties. However, we may share your information with third-party service providers who help us to provide our web app. For example, we may share your information with a customer support platform or an email marketing tool.</>,
      <>We may also disclose your information if required by law, such as in response to a court order or subpoena.</>
    ]
  },
  {
    title: <>Security</>,
    content: [
      <>We take the security of your information seriously and use industry-standard security measures to protect it from unauthorized access, disclosure, or misuse. However, no security system is perfect, and we cannot guarantee that your information will always be secure.</>
    ]
  },
  {
    title: <>Data Retention</>,
    content: [
      <>We will retain your information as long as necessary to provide our web app to you and to comply with our legal obligations. If you delete your account, we will delete your information within a reasonable time period, unless we are required by law to retain it.</>
    ]
  },
  {
    title: <>Children's Privacy</>,
    content: [
      <>Our web app is not intended for children under the age of 13. We do not knowingly collect information from children under the age of 13, and if we become aware that we have collected information from a child under the age of 13, we will delete it immediately.</>
    ]
  },
  {
    title: <>Changes to This Privacy Policy</>,
    content: [
      <>We may update this privacy policy from time to time, and we will notify you of any material changes by posting the new policy on our web app or by email.</>
    ]
  },
  {
    title: <>Contact Us</>,
    content: [
      <>If you have any questions or concerns about this privacy policy, please contact us at <a className={appStyles.link} href="mailto:support@inclinicals.com">support@inclinicals.com</a>.</>
    ]
  }
]