/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  RotationsView.js
*/

import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Form, Button, Spinner } from 'react-bootstrap';
import { fireStore } from '../../../../../../Fire';
import { stateAbrvs } from '../../../../../../resources/StateAbrvs';
import SmallStars from './../../../../../stars/small-stars/SmallStars';
import { roundAccurately, roundAccuratelyString } from './../../../../../../resources/Functions';
import styles from './DbView.module.css';
import { institutions } from '../../../../../../resources/InstitutionData';

const Rotation = props => {
  const {
    key,
    doc
  } = props;

  const [redirect, setRedirect] = useState({
    go: false,
    path: ""
  })

  const handleClick = () => {
    setRedirect({
      go: true,
      path: "/rotations/" + doc.id
    })
  }

  if (redirect.go) return <Navigate to={redirect.path} />

  return <>
    <div key={key} className={styles.docContainer}>
      <button
        className={styles.doc}
        onClick={handleClick}
      >
        <div className={styles.docText}>
          <h1 className={styles.h1}>{doc.discipline}</h1>
          <h2 className={styles.h2}>{institutions[doc.institutionID].name}</h2>
          <h3 className={styles.h3}>{doc.location.city}, {stateAbrvs[doc.location.state]}</h3>
        </div>
        <div className={styles.starsContainer}>
          {doc.stats && <SmallStars val={roundAccuratelyString((doc.stats.ratings || {}).overallSatisfaction, 1)} variant="single"/>}
        </div>
      </button>
    </div>
  </>
}

export default function RotationsView(props) {
  const {
    discs,
    states,
    inst
  } = props;

  const [rotations, setRotations] = useState(null);

  // TECH DEBT - these arrays should be changed to single value variables to increase readability
  useEffect(() => {
    (async () => {
      const rotsRef = collection(fireStore, 'rotations');
      let q;
      let wheres = [];
      if (discs.length > 0) wheres.push(where('discipline', '==', discs[0]));
      if (states.length > 0) wheres.push(where('location.state', '==', states[0]));
      if (inst.length > 0) wheres.push(where('institution', '==', inst));
      q = query(rotsRef, ...wheres);
      const querySnapshot = await getDocs(q);
      setRotations(querySnapshot.docs);
    })();
  }, [discs, states, inst]);

  return <>
    <div className={styles.container}>
      {!rotations && <div className={styles.loadingWrapper}>
        <Spinner animation="grow" variant="secondary" size="sm"/>
        <Spinner animation="grow" variant="secondary" size="sm"/>
        <Spinner animation="grow" variant="secondary" size="sm"/>  
      </div>}
      {rotations && <div className={styles.docsContainer}>
        {rotations.map((doc) => (
          <Rotation key={doc.id} doc={doc.data()} />
        ))}  
      </div>}
      {(rotations && (rotations || []).length === 0) && <div>
        {props.children}
      </div>}
    </div>
  </>
};