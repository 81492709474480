/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  NpiLookup.js
*/

import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { httpsCallable } from 'firebase/functions';
import { fireFuncs } from '../../Fire';

import styles from './NpiLookup.module.css';
import appStyles from './../../AppStyles.module.css';
import { capitalizeEachLetter } from '../../resources/Functions';
import { stateAbrvs } from '../../resources/StateAbrvs';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

export default function NpiLookup(props) {
  const {
    state,
    handleChange,
    errors,
    changeData,
    addPrecep,
    removePrecep,
    isLast,
    i,
    npi
  } = props;

  const [data, setData] = useState(null);
  const [badRequest, setBadRequest] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (npi) {
      handleFetch();
    }
  }, [npi])

  useEffect(() => {
    if (data) {
      changeData(data);
    }
  }, [data])

  useEffect(() => {
    if (badRequest) {
      changeData(null);
    }
  }, [badRequest])

  const handleFetch = () => {
    const fetchUsingNPI = httpsCallable(fireFuncs, 'fetchUsingNPI');
    setLoading(true);
    fetchUsingNPI({number: state.npi}).then((result) => {
      console.log("From functions", result.data.data);
      setData(result.data.data);
      if (result.data.data) {
        setBadRequest(null);
      } else {
        setBadRequest("Sorry, there are no preceptors with this NPI number.")
        setData(null);
      }
      setLoading(false);
    }).catch(error => {
      console.error("Error with firebase fetch", error);
      setLoading(false);
      setBadRequest("Sorry, there are no preceptors with this NPI number.")
      setData(null);
    })
  }

  return <>
    <Form.Group className="mb-3" controlId="formBasicEmail" key={i}>
      <div className={styles.titleWrapper}>
        <Form.Label>Preceptor's NPI</Form.Label>
        <button 
          className={styles.xBtn} 
          type="button"
          onClick={removePrecep}
        >
          <CloseIcon/>
        </button>
      </div>
      <Form.Control
        required
        value={state.npi}
        onChange={handleChange}
        type="text"
        id={`npi_${i}`}
        placeholder="0000000000"
      />
      {isLast && <Form.Text>Find your preceptor's NPI <a className={appStyles.link} target="_blank" href="https://npiregistry.cms.hhs.gov/search" >here</a></Form.Text>}
      <Form.Control.Feedback type='invalid'>{errors[`npi_${i}`]}</Form.Control.Feedback>
      {badRequest && <div className={styles.pData}>
        <h3 className={styles.name}>{badRequest}</h3>  
      </div>}
      {data && <div className={styles.pData}>
        <h3 className={styles.name}>{capitalizeEachLetter(data.basic.first_name.toLowerCase())} {capitalizeEachLetter(data.basic.last_name.toLowerCase())}</h3>
        <div className={styles.info}>
          {data.taxonomies.map(item => (
            <p className={styles.infoItem}>{item.desc} - <span className={styles.infoState}>{stateAbrvs[item.state]}</span></p>
          ))}
        </div>
      </div>}
      <div className={styles.btnContainer}>
        <Button
          type='button'
          variant="secondary"
          onClick={handleFetch}
          className={appStyles.primaryBtn}
          disabled={loading}
        >
          {loading ? <Spinner animation="border" size="sm" /> : "Find"}
        </Button>
        {isLast && <Button
          variant="secondary"
          type='button'
          onClick={addPrecep}
          className={appStyles.primaryBtn+" "+appStyles.precep}
        >
          <AddIcon />
        </Button>}
      </div>
      {isLast && <Form.Text>We'd prefer you add all preceptors you worked with on this rotation, but if you'd prefer not to, remove the preceptor npi field with the X above.</Form.Text>}
    </Form.Group>
  </>
};