/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  GuidelinesModal.js
*/
import React, { useEffect, useState } from "react";
import { Modal, } from "react-bootstrap";
import { sleep } from "../../../../resources/Functions";

import styles from './GuidelinesModal.module.css';
import appStyles from './../../../../AppStyles.module.css';

export default function GuidelinesModal(props) {
  const { show, onHide } = props;
  const [intShow, setIntShow] = useState(show);

  useEffect(() => {
    setIntShow(show);
  }, [show]);

  const handleHide = () => {
    setIntShow(false);
    sleep(500).then(() => onHide());
  };

  return <>
    <Modal
      show={intShow}
      onHide={handleHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className={styles.header}>
            <img className={styles.brand} src="/branding/brand.svg" />
            <h1 className={appStyles.h1}>Review Guidelines</h1>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ol className={styles.list}>
          {/* <li className={styles.li}>
            <span className={styles.bold}>Be factual:</span> Provide specific details about your experience, including the length of the rotation, the types of patients you encountered, and the tasks you were assigned. Avoid vague or generalized statements.
          </li> */}
          <li className={styles.li}>
            <span className={styles.bold}>Be honest:</span> Write about your experience in a truthful and accurate manner. Do not exaggerate or make false statements.
          </li>
          <li className={styles.li}>
            <span className={styles.bold}>Be respectful:</span> Avoid using offensive language or making personal attacks against the preceptor or staff. Provide constructive criticism without being disrespectful.
          </li>
          <li className={styles.li}>
            <span className={styles.bold}>Be thorough:</span> Include both positive and negative aspects of your experience, and provide details about what worked well and what could be improved.
          </li>
          <li className={styles.li}>
            <span className={styles.bold}>Be relevant:</span> Keep your review focused on the clinical rotation and preceptor, and avoid discussing unrelated topics.
          </li>
          <li className={styles.li}>
            <span className={styles.bold}>Be fair:</span> Consider the preceptor's experience level and resources available when writing your review. If there were extenuating circumstances that affected your experience, consider mentioning them.
          </li>
          <li className={styles.li}>
            <span className={styles.bold}>Be mindful of confidentiality:</span> Protect patient privacy by not sharing any identifying information about patients encountered during your rotation.
          </li>
          <li className={styles.li}>
            <span className={styles.bold}>Be clear:</span> Make your review easy to read and understand by organizing your thoughts into clear and concise sentences.
          </li>
        </ol>
        <p>By following these guidelines, you can write a helpful review that will benefit future students and improve the quality of clinical rotations and preceptors.</p>
      </Modal.Body>
    </Modal>
  </>
};