/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  ReviewModal.js
*/
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button} from "react-bootstrap";
import { makeTitleFromCamel, sleep } from "../../resources/Functions";
import { RecordVoiceOver, ContentPasteSearch, ThumbUp, AccountCircle } from '@mui/icons-material';
import SmallStars from "../stars/small-stars/SmallStars";
import styles from './ReviewModal.module.css';
import { doc, getDoc } from "firebase/firestore";
import { fireStore } from "../../Fire";
import { AuthContext } from "../../Auth";
import appStyles from './../../AppStyles.module.css';
import { institutions } from "../../resources/InstitutionData";

export default function RotationReviewModal(props) {
  const { 
    show, 
    onHide,
    rev,
    preceptor,
    targetId,
    onProfile,
    handleDelete
  } = props;
  const user = useContext(AuthContext)
  const [intShow, setIntShow] = useState(show);

  const [target, setTarget] = useState(preceptor);

  useEffect(() => {
    if (!preceptor) {
      (async () => {
        const docRef = doc(fireStore, 'preceptors', targetId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) setTarget(docSnap.data());
      })();
    }
  })

  useEffect(() => {
    setIntShow(show);
  }, [show]);

  const handleHide = () => {
    setIntShow(false);
    sleep(500).then(() => onHide());
  };

  return <>
    <Modal
      show={intShow}
      onHide={handleHide}
      centered
    >
      <Modal.Header className={styles.header} closeButton>
        {target && <div>
          <h1 className={styles.h1}>{target.fName} {target.lName}</h1>
          {rev.data().rotation && <h2 className={styles.h2}>{rev.data().rotation.discipline}</h2>}
          {rev.data().rotation && <h3 className={styles.h3}>{(institutions[rev.data().rotation.institutionID] || {}).name}</h3>}
        </div>}
      </Modal.Header>
      <Modal.Body>
        <div className={styles.sec1}>
          <div className={styles.col1}>
            <div className={styles.authorWrapper}>
              <AccountCircle className={styles.authorIcon} sx={{fontSize: 20}}/>
              <h3 className={styles.author}>{rev.data().authorUsername}</h3>
            </div>
            <p className={styles.date}>{new Date(rev.data().date).toLocaleDateString()}</p>
          </div>
          <div className={styles.col2}>
            <div className={styles.reviewRating}>
              <ThumbUp className={styles.labelIcon} sx={{fontSize: 16}}/>
              <SmallStars val={rev.data().ratings.overallSatisfaction} />
            </div>
            <div className={styles.reviewRating}>
              <ContentPasteSearch className={styles.labelIcon} sx={{fontSize: 16}}/>
              <SmallStars val={rev.data().ratings.difficultyOfExpectations} />
            </div>
            <div className={styles.reviewRating}>
              <RecordVoiceOver className={styles.labelIcon} sx={{fontSize: 16}}/>
              <SmallStars val={rev.data().ratings.recLikelihood} />
            </div>
          </div>
        </div>
        <div className={styles.sec2}>
          {rev.data().flags && <div className={styles.flagsContainer}>
            <h4 className={styles.statLabel}>Tags:</h4>
            <div className={styles.flags}>
              {rev.data().flags.map(flag => (
                <div key={flag} className={styles.flag}>{makeTitleFromCamel(flag)}</div>
              ))}
            </div>
          </div>}
          {rev.data().strengths && <div className={styles.flagsContainer}>
            <h4 className={styles.statLabel}>Strengths:</h4>
            <div className={styles.flags}>
              {rev.data().strengths.map(strength => (
                <div key={strength} className={styles.flag+" "+styles.strength}>{makeTitleFromCamel(strength)}</div>
              ))}
            </div>
          </div>}
          {rev.data().weaknesses && <div className={styles.flagsContainer}>
            <h4 className={styles.statLabel}>Weaknesses:</h4>
            <div className={styles.flags}>
              {rev.data().weaknesses.map(weakness => (
                <div key={weakness} className={styles.flag+" "+styles.weakness}>{makeTitleFromCamel(weakness)}</div>
              ))}
            </div>
          </div>}
          <div className={styles.stat}>
            <h4 className={styles.statLabel}>Would choose again:</h4>
            <p className={styles.statVal}>{rev.data().chooseAgain}</p>
          </div>
        </div>
        <div className={styles.sec3}>
          <h2 className={styles.longFormLabel}>How could this preceptor improve?</h2>
          <p className={styles.review}>{rev.data().improvement}</p>
          <h2 className={styles.longFormLabel}>What are this preceptor's general expectations?</h2>
          <p className={styles.review}>{rev.data().expectations}</p>
          <h2 className={styles.longFormLabel}>General Thoughts</h2>
          <p className={styles.review}>{rev.data().review}</p>
        </div>
      </Modal.Body>
      {(onProfile || rev.data().author === user.uid) && <Modal.Footer>
        {onProfile && <Button
          variant="link"
          className={appStyles.link}
          as={Link}
          to={`/preceptors/${targetId}`}
        >See preceptor page</Button>}
        <Button
          variant="danger"
          onClick={async () => {
            handleHide();
            await sleep(100);
            handleDelete('preceptors', targetId, rev.id);
          }}
        >Delete</Button>
      </Modal.Footer>}
    </Modal>
  </>
};