/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  ForgotPassword.js
*/
import React, { useState } from "react";
import { fireAuth } from "../../../../../../Fire";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import "./ForgotPassword.css";
import appStyles from './../../../../../../AppStyles.module.css';
import { sendPasswordResetEmail } from "firebase/auth";

const DEV = window.location.hostname === 'localhost';

/*
  This renders a modal to handle resetting a password with an email address
*/
const ForgotPassword = (props) => {
  const { 
    show,             // boolean - show modal controlled by parent
    handleClose,      // func - called when cancelling
    handleDone        // func - called when email sent
  } = props;
  // boolean - manages showing result from validation
  const [validated, setValidated] = useState(false);
  // obj - holds values for input fields
  const [state, setState] = useState({
    forgotPwEmail: "",
  });
  // obj - holds values for errors from validation
  const [errors, setErrors] = useState({
    forgotPwEmail: "Please enter a valid email.",
  });
  const [submitting, setSubmitting] = useState(false);

  /* 
    used to validate all form fields and handle error messaging
    prop: str - state/field prop to validate
    criteria: func - called to check prop
    errorMessage: str - error to be shown if !criteria()
  */
  const validate = (prop, criteria, errorMessage) => {
    const message = criteria() ? "" : errorMessage;
    document.getElementById(prop).setCustomValidity(message);
    setErrors({ ...errors, [prop]: message });
  };

  // submit email and send reset email
  const handleSubmit = (event) => {
    // prevent default submit behavior and start our own
    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.stopPropagation();
    } else if (!DEV) {
      // send the email
      setSubmitting(true);
      sendPasswordResetEmail(fireAuth, state.forgotPwEmail)
        .then(() => {
          handleDone();
          setSubmitting(false);
        })
        .catch((error) => {
          if (error.code === 'auth/user-not-found') {
            validate(
              'forgotPwEmail',
              () => false,
              'A user with this email does not exist.'
            );
            setSubmitting(false);
          }
        })
    } else {
      validate(
        'forgotPwEmail',
        () => false,
        "Can't send password reset email in dev mode."
      )
    }
    setValidated(true);
  };

  // handles changes for input fields
  const handleChange = (prop) => {
    return (event) => {
      if (prop === "forgotPwEmail") {
        validate(
          prop,
          () => /.+@.+\..+/gm.test(event.target.value),
          "Please enter a valid email"
        );
      }
      setState({ ...state, [prop]: event.target.value });
    };
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="app-modal forgot-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>
                Enter the email associated with your account.
              </Form.Label>
              <Form.Control
                id="forgotPwEmail"
                required
                type="email"
                placeholder="Email"
                value={state.forgotPwEmail}
                onChange={handleChange("forgotPwEmail")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.forgotPwEmail}
              </Form.Control.Feedback>
            </Form.Group>
            <Button 
              type="submit"
              className={appStyles.primaryBtn}
              variant="secondary"
            >
              {submitting ? <Spinner animation="border" size="sm" /> : "Send Password Reset Email"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ForgotPassword;
