/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Faq.js
*/

import React, { useEffect, useState } from 'react';
import { faqs } from '../../../faq-modal/FaqContent';
import styles from './Faq.module.css';

// Generated by ChatGPT - not used, but good inspo
const content = [
  {
    question: <>
      What is inClinicals?
    </>,
    answer: <>
      inClinicals is a revolutionary app designed for medical students to rate and review their clinical rotations and preceptors. It provides a platform for sharing experiences, insights, and feedback to enhance the learning journey.
    </>
  },
  {
    question: <>
      How does inClinicals work?
    </>,
    answer: <>
      With inClinicals, you can rate your rotations and preceptors based on various criteria and select relevant tags to provide a comprehensive assessment. Your reviews, completely anonymous, contribute to aggregated data that helps potential students make informed decisions.
    </>
  },
  {
    question: <>
      Is my anonymity protected?
    </>,
    answer: <>
      Absolutely! inClinicals values your privacy. All reviews are completely anonymous, allowing you to speak freely and share your experiences without any fear of identification.
    </>
  },
  {
    question: <>
      Can I provide detailed feedback in addition to ratings?
    </>,
    answer: <>
      Yes! inClinicals not only allows you to rate rotations but also provides the option to write long-form reviews. You can express your thoughts, provide specific examples, and offer detailed feedback on your experiences.
    </>
  },
  {
    question: <>
      How can inClinicals help me make a good impression during my rotations?
    </>,
    answer: <>
      By using inClinicals, you can access valuable insights from previous students' reviews. These reviews help you gain a better understanding of what to expect, allowing you to prepare, make informed decisions, and leave a positive and lasting impression during your clinical rotations.
    </>
  },
  {
    question: <>
      Can I control when my review is posted?
    </>,
    answer: <>
      Absolutely! inClinicals offers a delayed posting feature, allowing you to choose the timing of your review. This way, you can be less conspicuous and maintain discretion while still contributing to the pool of valuable insights.
    </>
  },
  {
    question: <>
      Can I trust the information from aggregated reviews?
    </>,
    answer: <>
      Yes! The aggregated reviews on inClinicals provide a comprehensive overview of rotations and preceptors. By considering the collective wisdom of your peers, you can make well-informed decisions based on reliable and relevant information.
    </>
  },
  {
    question: <>
      Is inClinicals available for all medical schools?
    </>,
    answer: <>
      inClinicals strives to be accessible to as many medical schools as possible. However, the availability may vary depending on your institution. We are continuously expanding our reach to serve more students in their clinical education journey.
    </>
  },
  {
    question: <>
      How can I get started with inClinicals?
    </>,
    answer: <>
      Getting started is simple! Just download the inClinicals app, create an account, and start rating and reviewing your clinical rotations. Join our growing community of medical students who are transforming clinical education.
    </>
  },
]

export default function Faq(props) {
  const {

  } = props;

  return <>
    <div className={styles.container}>
      <div className={styles.main}>
        <h2 className={styles.title}>inClinicals FAQ</h2>
        {faqs.map(item => (
          <div key={item.question} className={styles.item}>
            <h3 className={styles.question}>{item.question}</h3>
            <p className={styles.answer}>{item.answer}</p>
          </div>
        ))}
      </div>
    </div>
  </>
};