/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  ContactForm.js
*/

import React, { useContext, useEffect, useState } from 'react';
import styles from './../ContactPage.module.css';
import appStyles from './../../../../AppStyles.module.css';
import { Form, Button, Spinner } from 'react-bootstrap';
import uuid from 'react-uuid';
import { AuthContext } from '../../../../Auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { fireStore } from '../../../../Fire';

export default function ContactForm(props) {
  const {
    setSubmitted
  } = props;

  const user = useContext(AuthContext);

  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [errors, setErrors] = useState({
    name: "Please enter your name",
    email: "Please enter your email address",
    subject: "Please enter a subject for your request.",
    message: "Please explain your request."
  });

  /* 
    used to validate all form fields and handle error messaging
    prop: str - state/field prop to validate
    criteria: func - called to check prop
    errorMessage: str - error to be shown if !criteria()
  */
  const validate = (prop, criteria, errorMessage) => {
    const message = criteria() ? '' : errorMessage;
    document.getElementById(prop).setCustomValidity(message);
    setErrors({...errors, [prop]: message});
  }

  const handleChange = prop => {
    return e => {
      if (prop === 'text') {
        if (/^.*$/g.test(e.target.value)) {
          validate(
            prop,
            () => /^[a-zA-Z]*$/g.test(e.target.value),
            "Text must have only letters"
          );
          setState(prev => ({...prev, [prop]: e.target.value}))
        }
      } else {
        setState(prev => ({...prev, [prop]: e.target.value}))
      }
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    } else {
      // submitting
      setSubmitting(true);
      const id = uuid();
      let obj = {
        name: state.name,
        email: state.email,
        subject: state.subject,
        message: state.message,
        id: id,
        date: new Date(Date.now()).toLocaleString(),
        authenticated: user ? true : false,
      }
      if (user) {
        const docRef = doc(fireStore, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          obj.userData = {
            username: data.username || "undefined",
            email: data.email || "undefined",
            school: data.school || "undefined",
            dateAdded: new Date(data.dateAdded).toLocaleDateString() || "undefined"
          }
        } else {
          obj.userData = "Error pulling up user data for authenticated user."
        }
      }
      setDoc(doc(fireStore, "contactRequests", id), obj).then(() => {
        setSubmitting(false);
        setSubmitted(true);
      }).catch((error) => {
        console.error("Error saving contact request", error);
        setSubmitting(false);
      })
    }
    setValidated(true);
  }

  return <>
    <Form
      noValidate
      onSubmit={handleSubmit}
      validated={validated}
    >
      <Form.Group className="mb-3">
        <Form.Label>Full Name</Form.Label>
        <Form.Control
          required
          value={state.name}
          onChange={handleChange('name')}
          type="name"
          id="name"
        />
        <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          required
          value={state.email}
          onChange={handleChange('email')}
          type="email"
          id="email"
        />
        <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Subject</Form.Label>
        <Form.Control
          required
          value={state.subject}
          onChange={handleChange('subject')}
          type="text"
          id="subject"
        />
        <Form.Control.Feedback type='invalid'>{errors.subject}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Message</Form.Label>
        <Form.Control
          required
          value={state.message}
          onChange={handleChange('message')}
          type="text"
          as="textarea"
          rows={3}
          id="message"
        />
        <Form.Control.Feedback type='invalid'>{errors.message}</Form.Control.Feedback>
      </Form.Group>
      <div className={styles.btnWrapper}>
        <Button 
          className={appStyles.primaryBtn} 
          variant="secondary" 
          type="submit"
          disabled={submitting}
        >
          {submitting ? <Spinner animation="border" size="sm" /> : 'Submit'}
        </Button>
      </div>
    </Form>
  </>
};