/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  ReviewPage.module.css
*/
import React, { useState, useEffect, useContext } from 'react';
import { collection, doc, getDoc, getDocs, setDoc, updateDoc, query, where } from 'firebase/firestore';
import { fireStore } from "./../../../Fire";
import { Spinner, Button, Form } from 'react-bootstrap';
import NavBar from '../../nav-bar/NavBar';
import FlagSelector from '../../flag-selector/FlagSelector';
import { RotationReviewFlags } from '../../../resources/ReviewData';
import uuid from 'react-uuid';
import { AuthContext } from '../../../Auth';
import { Navigate, useLoaderData, useLocation } from 'react-router-dom';
import RotationReview from './components/RotationReview';
import PreceptorReview from './components/PreceptorReview';
import GuidelinesModal from './components/GuidelinesModal';

import styles from './ReviewPage.module.css';

const DEV = window.location.hostname === 'localhost';

export async function reviewPageLoader({ params }) {
  const {docID} = params;
  return {
    docID: docID
  }
}

export default function ReviewPage(props) {
  const data = useLoaderData();
  const docID = data.docID;
  const location = useLocation();
  const type = (location.state || {}).type || "rotations";
  const ids = (location.state || {}).ids;
  
  const [redirect, setRedirect] = useState({go: false, path: "", state: {}});
  const [modal, setModal] = useState({
    guidelines: {show: false}
  })
  const user = useContext(AuthContext);

  useEffect(() => {
    if (!user) {
      setRedirect({
        go: true,
        path: '/auth',
        state: {donePath: window.location.pathname, type: type}
      })
    } else if (!user.emailVerified && !DEV) {
      // uncomment this when doing email verification
      // setRedirect({
      //   go: true,
      //   path: '/auth',
      //   state: {donePath: window.location.pathname, mode: 'verify'}
      // })
    }
  }, [user])

  const showGuidelines = () => {
    setModal(prev => ({...prev, guidelines: {show: true}}))
  }

  if (redirect.go) return <Navigate to={redirect.path} state={redirect.state} />

  return <>
    <div className={styles.page}>
      <NavBar />
      {!document && <div className={styles.spinnerWrapper}>
        <Spinner animation="border" variant="secondary" role="status"/>
      </div>}
      {type === "rotations" && <RotationReview
        docID={docID}
        showGuidelines={showGuidelines}
        afterSubmit={() => setRedirect({
          go: true,
          path: `/create/prompt`,
          state: {
            ids: ids || {
              rotation: {
                id: docID,
                reviewed: true
              }
            },
            lastReview: {
              id: docID,
              type: type
            }
          }
        })}
      />}
      {type === "preceptors" && <PreceptorReview
        docID={docID}
        showGuidelines={showGuidelines}
        afterSubmit={() => setRedirect({
          go: true,
          path: `/create/prompt`,
          state: {
            ids: ids,
            lastReview: {
              id: docID,
              type: type
            }
          }
        })}
      />}
      {/* <div className={styles.testFooter}></div> */}
    </div>
    {modal.guidelines.show && <GuidelinesModal
      show={modal.guidelines.show}
      onHide={() => setModal(prev => ({...prev, guidelines: {show: false}}))}
    />}
  </>
}