/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  CreateTarget.js
*/

import React, { useContext, useEffect, useState } from 'react';
import NavBar from '../../nav-bar/NavBar';
import CreateForm from './components/CreateForm';
import appStyles from './../../../AppStyles.module.css';
import styles from './CreateTarget.module.css';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../Auth';

// const DEV = window.location.hostname === 'localhost';
const DEV = false;

export default function CreateTarget() {
  const user = useContext(AuthContext);
  const location = useLocation();
  const type = (location.state || {}).type;
  const npi = (location.state || {}).npi;
  const [redirect, setRedirect] = useState({
    go: false,
    path: '',
    state: {}
  });

  useEffect(() => {
    if (!user) {
      setRedirect({
        go: true,
        path: '/auth',
        state: {donePath: window.location.pathname, mode: 'create'}
      })
    } else if (!user.emailVerified && !DEV) {
      // send to auth page to verify email if email isn't verified yet
      // setRedirect({
      //   go: true,
      //   path: '/auth',
      //   state: {donePath: window.location.pathname, mode: 'verify'}
      // })
    }
  }, [user]);

  if (redirect.go) return <Navigate to={redirect.path} state={redirect.state} />;

  return <>
    <div className={styles.container}>
      <NavBar />
      <div className={styles.page}>
        <div className={styles.content}>
          <div>
            {type !== 'write' && <h1 className={appStyles.h1}>Add {type === 'rotations' ? "Rotation" : "Preceptor"}</h1>}
            {type === 'write' && <h1 className={appStyles.h1}>Write Review</h1>}
            <div className={styles.formWrapper}>
              <CreateForm
                type={type}
                npi={npi}
                onSubmit={(rotId, precepIds) => {
                  setRedirect({
                    go: true,
                    path: '/create/prompt',
                    state: {
                      ids: {
                        rotation: {
                          id: rotId,
                          reviewed: false
                        },
                        // preceptors: (precepIds.length === 0 ? undefined : precepIds)
                      }
                    }
                  })
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
};