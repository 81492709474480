/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  DisciplinesModal.js
*/
import React, { useEffect, useState } from "react";
import { Modal, } from "react-bootstrap";
import { sleep } from "../../../../resources/Functions";
import { disciplines } from "../../../../resources/InstitutionData";
import styles from './Modal.module.css';
import appStyles from './../../../../AppStyles.module.css';

const discArray = Object.values(disciplines).sort((a, b) => {
  if (a.name > b.name) {
    return 1;
  } else if (a.name < b.name) {
    return -1;
  } else return 0;
});

export default function DisciplinesModal(props) {
  const { 
    show, 
    onHide,
    openForm
  } = props;
  const [intShow, setIntShow] = useState(show);

  useEffect(() => {
    setIntShow(show);
  }, [show]);

  const handleHide = () => {
    setIntShow(false);
    sleep(500).then(() => onHide());
  };

  const handleOpenForm = () => {
    setIntShow(false);
    openForm();
  }

  let elementArray = [];
  discArray.forEach(s => {
    elementArray.push(
      <div key={s.id+"a"} className={styles.item+" "+styles.name}>
        <div className={styles.itemContent}>{s.name}</div>
      </div>
    );
    elementArray.push(
      <div key={s.id+"b"} className={styles.item+" "+styles.email}>
        <div className={styles.itemContent}>{s.abbrv}</div>
      </div>
    )
  })

  return <>
    <Modal
      show={intShow}
      onHide={handleHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Supported Clinical Disciplines</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={styles.p}>Don't see your discipline? Submit a <a className={styles.a+" "+appStyles.link} onClick={handleOpenForm}>discipline support request</a></p>
        <div className={styles.grid}>
          {elementArray}
        </div>
      </Modal.Body>
    </Modal>
  </>
};