import React, { useState, useEffect, useContext } from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Auth";
import { fireAuth, fireStore } from "../../Fire";
import { signOut } from "firebase/auth";
// import { signOut } from "firebase/auth";
import AuthModal from "./components/AuthModal";
import "./NavBar.css";
import BetaModal from "./components/BetaModal";
import { doc } from "firebase/firestore";

const NavBar = (props) => {
  const {
    showBetaModal
  } = props;
  const user = useContext(AuthContext);
  const [userName, setUserName] = useState("");
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [modal, setModal] = useState({
    beta: {show: false}
  })

  /*
        Okay it seems like this isn't necessary. Like it seems like
        you could just hardcode these values into the states themselves,
        but for some reason when you do that, the render enters a loop
        and never loads. It makes no sense, but for some reason this
        useEffect is necessary.
    */
  useEffect(() => {
    if (user) {
      const uName = user.displayName;
      setUserName(uName);
      setUserLoggedIn(true);
    } else {
      setUserLoggedIn(false);
    }
  }, [user]);

  useEffect(() => {
    if (showBetaModal) {
      setModal(prev => ({...prev, beta: {show: true, prompted: false}}));
    }
  }, [showBetaModal])

  const handleSignOut = (event) => {
    signOut(fireAuth)
      .then(() => {
        setUserLoggedIn(false);
        setUserName("");
        // window.location.reload();
      })
      .catch((error) => {
        console.log("Signout failed");
        console.log("error code: " + error.code);
        console.log("error message: " + error.message);
      });
  };

  const navLinks = userLoggedIn ? (
    <>
      <Nav className="mr-auto">
        <Link className="nav-link" to="/about">About Us</Link>
        <Link className="nav-link" to="/contact">Contact</Link>
        {/* <button className="nav-link button" onClick={() => setModal(prev => ({...prev, beta: {show: true, prompted: true}}))}>Beta Review</button> */}
      </Nav>
      <Nav>
        <NavDropdown menualign="right" title="Profile" id="basic-nav-dropdown">
          {/* <Link href="/profile">View Profile(!!)</Link> */}
          <NavDropdown.Item
            className="nav-dropdown-item"
            as={Link}
            to="/account"
          >
            Account
          </NavDropdown.Item>
          <NavDropdown.Item
            className="nav-dropdown-item"
            onClick={handleSignOut}
          >
            Sign out
          </NavDropdown.Item>
        </NavDropdown>
        <Navbar.Text className="navbar-profile-name">{userName}</Navbar.Text>
      </Nav>
    </>
  ) : (
    <>
      <Nav className="mr-auto">
        <Link className="nav-link" to="/about">About Us</Link>
        <Link className="nav-link" to="/contact">Contact</Link>
        {/* <button className="nav-link button" onClick={() => setModal(prev => ({...prev, beta: {show: true, prompted: true}}))}>Beta Review</button> */}
      </Nav>
      <Nav>
        <Link className="nav-link" to="/auth" state={{ mode: 'login' }}>Sign in</Link>
      </Nav>
    </>
  );

  return (
    <div>
      <Navbar 
        collapseOnSelect 
        expand="lg" 
        variant="light"
        onToggle={() => setExpanded(prev => !prev)}
        className={expanded ? "expanded" : ""}
      >
        <Link className="navbar-brand" to="/">
          <img
            src="/branding/brand.svg"
            className="navbar-brand-img"
          />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {navLinks}
        </Navbar.Collapse>
      </Navbar>
      {modal.beta.show && <BetaModal
        show={modal.beta.show}
        onHide={() => setModal(prev => ({...prev, beta: {show: false}}))}
        prompted={modal.beta.prompted}
      />}
    </div>
  );
};

export default NavBar;
