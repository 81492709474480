/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  BinaryInput.js
*/

import React, { useEffect, useState } from 'react';
import styles from './BinaryInput.module.css';

export default function BinaryInput(props) {
  const {
    value,
    onChange,
    // array of two option objects
    /*{
      label,
      value,
      color,
      textColor
    }*/
    options,
    id
  } = props;

  const blankColor = '#dee8e9';

  return <>
    <div className={styles.container} id={id}>
      <button 
        type="button"
        className={styles.option}
        style={{
          backgroundColor: value === options[0].value ? options[0].color : blankColor,
          color: value === options[0].value ? options[0].textColor || 'black' : 'black',
        }}
        onClick={() => onChange({
          target: {value: options[0].value}
        })}
      >{options[0].label}</button>
      <button 
        type="button"
        className={styles.option}
        style={{
          backgroundColor: value === options[1].value ? options[1].color : blankColor,
          color: value === options[1].value ? options[1].textColor || 'black' : 'black',
        }}
        onClick={() => onChange({
          target: {value: options[1].value}
        })}
      >{options[1].label}</button>
    </div>
  </>
};