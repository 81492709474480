import appStyles from './../../AppStyles.module.css';

export const termsConditions = [
  {
    title: <>Acceptance of Terms</>,
    content: [
      <>By using our web app, you agree to these Terms. If you do not agree to these Terms, you may not use our web app.</>
    ]
  },
  {
    title: <>Use of our Web App</>,
    content: [
      <>inClinicals is a web app that allows medical students to rate and review their clinical rotations and preceptors. You may use our web app only for lawful purposes and in accordance with these Terms.</>,
      <>You may not use our web app in any way that:</>,
      <ul>
        <li>violates any applicable federal, state, local, or international law or regulation</li>
        <li>is fraudulent, false, misleading, or deceptive</li>
        <li>is threatening, abusive, harassing, defamatory, libelous, obscene, or pornographic</li>
        <li>infringes on the intellectual property rights of others</li>
        <li>introduces viruses, Trojan horses, worms, or other harmful code</li>
      </ul>
    ]
  },
  {
    title: <>Your Account</>,
    content: [
      <>To use certain features of our web app, you may be required to create an account. When you create an account, you agree to provide accurate and complete information.</>,
      <>You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to immediately notify us of any unauthorized use of your account.</>
    ]
  },
  {
    title: <>Content</>,
    content: [
      <>You retain all rights to any content you submit to our web app. However, by submitting content to our web app, you grant us a non-exclusive, worldwide, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, and display your content in any manner we see fit.</>,
      <>You agree that you will not submit any content that infringes on the intellectual property rights of others or violates any applicable law.</>
    ]
  },
  {
    title: <>Limitation of Liability</>,
    content: [
      <>Our web app is provided "as is" and "as available" without any warranty of any kind, whether express or implied. We do not warrant that our web app will be uninterrupted, error-free, or free of viruses or other harmful components.</>
    ]
  },
  {
    title: <>Indemnification</>,
    content: [
      <>You agree to indemnify and hold us harmless from any claims, damages, liabilities, and expenses (including reasonable attorneys' fees) arising out of or relating to your use of our web app or your breach of these Terms.</>
    ]
  },
  {
    title: <>Termination</>,
    content: [
      <>We reserve the right to terminate or suspend your account and your access to our web app at any time, with or without notice.</>
    ]
  },
  {
    title: <>Changes to These Terms</>,
    content: [
      <>We may update these Terms from time to time, and we will notify you of any material changes by posting the new Terms on our web app or by email.</>
    ]
  },
  {
    title: <>Governing Law</>,
    content: [
      <>These Terms are governed by and construed in accordance with the laws of the State of Washington, without giving effect to any principles of conflicts of law.</>
    ]
  },
  {
    title: <>Contact Us</>,
    content: [
      <>If you have any questions or concerns about these Terms, please contact us at <a className={appStyles.link} href="mailto:support@inclinicals.com">support@inclinicals.com</a>.</>
    ]
  }
]