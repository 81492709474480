export const faqs = [
  {
    question: <>What is inClinical's purpose?</>,
    answer: <>
      inClinicals is designed to allow medical students to rate and 
      review their clinical rotations and preceptors. This allows them to 
      share their experiences and provide valuable feedback to the medical 
      school and future students.
    </>
  },
  {
    question: <>Is my personal information kept confidential?</>,
    answer: <>
      Yes, we take privacy seriously and your personal information is kept 
      confidential. We do not share your information with anyone without your 
      consent. The only information seen on our platform is the username you choose 
      and the information you put in your reviews.
    </>
  },
  {
    question: <>Can I edit or delete my review?</>,
    answer: <>
      While you're not able to edit your review, you may delete it at any 
      time. Simply login to your account and go to your profile to delete 
      your reviews.
    </>
  },
  {
    question: <>Can I rate both my rotation and preceptor separately?</>,
    answer: <>
      Yes, you can! There are separate pages for rotations and preceptors, allowing you to give 
      specific feedback for each. 
    </>
  },
  {
    question: <>How are the reviews moderated?</>,
    answer: <>
      The reviews are moderated by our team to ensure that they are 
      appropriate and follow our guidelines. Reviews that violate our 
      guidelines will be removed.
    </>
  },
  {
    question: <>Can preceptors respond to reviews?</>,
    answer: <>
      Currently, preceptors are unable to respond to your reviews, but we plan 
      on giving them that capability in a later release so they will be able to address 
      any concerns and provide feedback. They will only be able to see the username you 
      create, so you will still be as anonymous as you want to be.
    </>
  },
  {
    question: <>Is inClinicals free to use?</>,
    answer: <>
      Yes, the web app is free to use for medical students. We believe that 
      providing this service is important for improving medical education 
      and the quality of clinical rotations.
    </>
  },
]