/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  ModalFormTemplate.module.css
*/
import { doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import uuid from "react-uuid";
import { fireStore } from "../../../../../../Fire";
import { sleep } from "../../../../../../resources/Functions";

export default function AddSchoolForm(props) {
  const { show, onHide, docID } = props;
  const [intShow, setIntShow] = useState(show);
  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState({
    name: '',
    url: '',
    suffix: '',
    email: ''
  });
  const [errors, setErrors] = useState({
    name: 'This field is required',
    url: 'This field is required',
    suffix: 'This field is required',
  });

  useEffect(() => {
    setIntShow(show);
  }, [show]);

  const handleHide = () => {
    setIntShow(false);
    sleep(500).then(() => onHide());
  };

  /* 
    used to validate all form fields and handle error messaging
    prop: str - state/field prop to validate
    criteria: func - called to check prop
    errorMessage: str - error to be shown if !criteria()
  */
  const validate = (prop, criteria, errorMessage) => {
    const message = criteria() ? '' : errorMessage;
    document.getElementById(prop).setCustomValidity(message);
    setErrors({...errors, [prop]: message});
  }

  const handleChange = prop => {
    return e => {
      setState(prev => ({...prev, [prop]: e.target.value}))
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    } else {
      // submitting
      setSubmitting(true);
      // 
      const id = uuid();
      await setDoc(doc(fireStore, 'medSchoolRequests', id), {
        id: id,
        name: state.name,
        url: state.url,
        suffix: state.suffix,
        email: state.email,
        date: new Date(Date.now()).toLocaleDateString()
      }).then(() => {
        handleHide();
      }).catch(error => {
        console.error("Error submitting form");
        console.error(error);
      })
    }
    setValidated(true);
  }

  return <>
    <Modal
      show={intShow}
      onHide={handleHide}
      centered
      style={{color: 'black'}}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Med school support request</Modal.Title>
      </Modal.Header>
      <Form
        noValidate
        onSubmit={handleSubmit}
        validated={validated}
      >
        <Modal.Body>
          <p style={{
            fontSize: "1rem",
            color: "#00000088",
          }}>
            Fill out the following information and we'll add support for your school as soon as we can. 
          </p>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>School name</Form.Label>
            <Form.Control 
              required
              value={state.name}
              onChange={handleChange('name')}
              type="text" 
              id="name"
              placeholder="University of California Riverside School of Medicine"
            />
            <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>School website</Form.Label>
            <Form.Control 
              required
              value={state.url}
              onChange={handleChange('url')}
              type="text" 
              id="url"
              placeholder="https://medschool.ucr.edu"
            />
            <Form.Control.Feedback type='invalid'>{errors.url}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Student email suffix</Form.Label>
            <Form.Control 
              required
              value={state.suffix}
              onChange={handleChange('suffix')}
              type="text"
              id="suffix"
              placeholder="@medsch.ucr.edu" 
            />
            <Form.Control.Feedback type='invalid'>{errors.suffix}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Notification email</Form.Label>
            <Form.Control 
              value={state.email}
              onChange={handleChange('email')}
              type="text"
              id="email"
              placeholder="jane.doe@gmail.com" 
            />
            <Form.Text muted>Enter your own email if you would like to be notified when we add support for this school.</Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            style={{
              backgroundColor: "#3f8070",
              borderColor: '#367162'
            }}
            variant="secondary" 
            type="submit"
          >
            {submitting ? <Spinner animation="border" size="sm" /> : 'Submit'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  </>
};