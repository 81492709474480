/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  RotationReview.js
*/

import React, { useContext, useEffect, useState } from 'react';
import { doc, getDoc, writeBatch } from 'firebase/firestore';
import { fireStore } from '../../../../Fire';
import uuid from 'react-uuid';
import { AuthContext } from '../../../../Auth';
import styles from './../ReviewPage.module.css';
import appStyles from './../../../../AppStyles.module.css';
import { Section1, Section2, Section3, Section4, Section5 } from './RotationForms';
import { institutions } from '../../../../resources/InstitutionData';
import { getPostDate } from '../../../../resources/Functions';

const DEBUG = false;

export default function RotationReview(props) {
  const {
    docID,
    showGuidelines,
    afterSubmit
  } = props;
  const [submitting, setSubmitting] = useState(false);
  const [step, setStep] = useState(1);
  const [state, setState] = useState(DEBUG ? {
    positives: [],
    negatives: [],
    daysPerWeek: "3",
    averageHoursPerDay: "2",
    applyForResidency: 'yes',
    wasRequired: 'no',
    singleSite: 'no',
    isCompetitive: 'yes',
    menialWork: '3',
    review: '',
    overallSatisfaction: 4,
    clinicalTraining: 3,
    recommend: 2,
    culture: 1,
    whenToPost: 1,
  } : {
    positives: [],
    negatives: [],
    daysPerWeek: "0",
    averageHoursPerDay: "0",
    applyForResidency: '0',
    wasRequired: '0',
    singleSite: '0',
    isCompetitive: '0',
    menialWork: '0',
    review: '',
    overallSatisfaction: 0,
    clinicalTraining: 0,
    recommend: 0,
    culture: 0,
    whenToPost: 0,
  });
  const user = useContext(AuthContext);

  const [document, setDocument] = useState(null);

  useEffect(() => {
    (async () => {
      const docRef = doc(fireStore, "rotations", docID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setDocument(docSnap.data());
      } else {
        console.error("This rotation id does not exist");
      }
    })()
  }, [])

  const incrementStep = e => {
    setStep(prev => prev + 1);
  }

  const decrementStep = () => {
    if (step > 1) setStep(prev => prev - 1);
  }

  const handleSubmit = async (publish = false) => {
    setSubmitting(true);
    const id = uuid();
    const date = Date.now();
    try {
      const batch = writeBatch(fireStore);
      const revRef = doc(fireStore, `rotations/${docID}/reviews`, id);
      batch.set(revRef, {
        applyForResidency: state.applyForResidency,
        author: user.uid,
        authorUsername: user.displayName,
        averageHoursPerDay: state.averageHoursPerDay,
        date: date,
        daysPerWeek: state.daysPerWeek,
        isCompetitive: state.isCompetitive,
        menialWork: state.menialWork,
        positives: state.positives,
        negatives: state.negatives,
        published: publish,
        ratings: {
          clinicalTraining: state.clinicalTraining,
          culture: state.culture,
          overallSatisfaction: state.overallSatisfaction,
          recLikelihood: state.recommend
        },
        review: state.review,
        singleSite: state.singleSite,
        type: 'rotation',
        targetId: docID,
        wasRequired: state.wasRequired,
        postOnDate: state.whenToPost === 0 ? date : getPostDate(state.whenToPost).getTime(),
      });
      await batch.commit();
      setSubmitting(false);
      afterSubmit();
    } catch (error) {
      console.error("ERROR SAVING REVIEW");
      console.error(error);
    }
  }

  return <>
    {document && <div className={styles.main}>
      <div className={styles.header}>
        <h1 className={styles.h1}>{document.discipline}</h1>
        <h2 className={styles.h2}>{institutions[document.institutionID].name}</h2>
        <h3 className={styles.h3}>{document.location.city}, {document.location.state}</h3>
        <a 
          variant="link" 
          className={appStyles.link}
          onClick={showGuidelines}
        >See our review guidelines</a>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <div className={styles.forms}>
            {step === 1 && <Section1
              state={state}
              setState={setState}
              onSubmit={incrementStep}
              decrementStep={decrementStep}
              showGuidelines={showGuidelines}
            />}
            {step === 2 && <Section2
              state={state}
              setState={setState}
              onSubmit={incrementStep}
              decrementStep={decrementStep}
              showGuidelines={showGuidelines}
            />}
            {step === 3 && <Section3
              state={state}
              setState={setState}
              onSubmit={incrementStep}
              decrementStep={decrementStep}
              showGuidelines={showGuidelines}
            />}
            {step === 4 && <Section4
              state={state}
              setState={setState}
              onSubmit={incrementStep}
              decrementStep={decrementStep}
              showGuidelines={showGuidelines}
            />}
            {step === 5 && <Section5
              state={state}
              setState={setState}
              onSubmit={handleSubmit}
              decrementStep={decrementStep}
              submitting={submitting}
              showGuidelines={showGuidelines}
            />}
          </div>
        </div>
      </div>
    </div>}
  </>
};