/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  BetaModal.js
*/
import { doc, updateDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, } from "react-bootstrap";
import { AuthContext } from "../../../Auth";
import { fireStore } from "../../../Fire";
import { sleep } from "../../../resources/Functions";
import appStyles from './../../../AppStyles.module.css';
import styles from './BetaModal.module.css';

export default function BetaModal(props) {
  const { 
    show, 
    onHide,
    prompted
  } = props;
  const user = useContext(AuthContext);
  const [intShow, setIntShow] = useState(show);

  useEffect(() => {
    setIntShow(show);
  }, [show]);

  const handleHide = () => {
    setIntShow(false);
    sleep(500).then(() => onHide());
  };

  const handleClick = async () => {
    if (user) {
      const userRef = doc(fireStore, 'userVisitCounts', user.uid);
      await updateDoc(userRef, {
        formVisited: true
      });
    }
  }

  return <>
    <Modal
      show={intShow}
      onHide={handleHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className={styles.title}>
          <img className={styles.brand} src="/branding/brand.svg" />
          Beta Review
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Thanks for being a part of the beta for inClinicals! Your help and feedback have been amazing in getting 
          the app to where it is today.
        </p>
        <p>
          We'd love to hear your thoughts on the app so far! We put together a little Google form where you can tell 
          us what you liked, didn't like, and what you think we can do better. Your feedback is crucial in making 
          the app even better for everyone else.
        </p>
        <div className={styles.linkContainer}>
          <div className={styles.linkWrapper}>
            <Button onClick={handleClick} variant="dark" className={appStyles.primaryBtn+" "+styles.link} target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdLby-ERhp1aAgHESiHXqdYeaXZjhtLElBf22MlWIaVh4MHMA/viewform">
              Give Feedback <i className="bi-box-arrow-up-right" />
            </Button>
            {!prompted && <p className={styles.caption}>Access this link again from the navbar.</p>}
          </div>
        </div>
        <p>
          Thanks again for being part of the beta, we can't wait to hear what you have to say!
        </p>
      </Modal.Body>
    </Modal>
  </>
};