/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  CreateForm.js
*/

import React, { useEffect, useState } from 'react';
import SuggestionSearch from '../../../suggestion-search/SuggestionSearch';
import { httpsCallable } from 'firebase/functions';
import { fireFuncs } from '../../../../Fire';
import NpiLookup from '../../../npi-lookup/NpiLookup';
import { capitalizeEachLetter, sleep } from '../../../../resources/Functions';
import { Button, Form, Spinner } from 'react-bootstrap';
import { institutions, disciplines } from '../../../../resources/InstitutionData';
import AddIcon from '@mui/icons-material/Add';

import styles from './CreateForm.module.css';
import appStyles from './../../../../AppStyles.module.css';
import InstitutionsModal from './InstitutionsModal';
import InstFormModal from './InstFormModal';
import DisciplinesModal from './DisciplinesModal';
import DiscFormModal from './DiscFormModal';

const sortedInst = Object.values(institutions).sort((a, b) => {
  if (a.name > b.name) {
    return 1;
  } else if (a.name < b.name) {
    return -1;
  } else return 0;
})
const sortedDisc = Object.values(disciplines).sort((a, b) => {
  if (a.name > b.name) {
    return 1;
  } else if (a.name < b.name) {
    return -1;
  } else return 0;
})

export default function CreateForm(props) {
  const {
    onSubmit,
    type,
    npi
  } = props;

  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState({
    inst: '',
    disc: '',
  });
  const [preceptors, setPreceptors] = useState([
    {
      npi: npi || '',
      data: null
    },
  ]);
  const [errors, setErrors] = useState({
    inst: "Please select a supported medical institution.",
    disc: 'Please select a discpline.',
    npi: '',
    npi_0: "Load a preceptor by pressing 'Find' or remove the preceptor"
  });
  const [modal, setModal] = useState({
    institutions: {show: false},
    instForm: {show: false},
    disciplines: {show: false},
    discForm: {show: false}
  })

  /* 
    used to validate all form fields and handle error messaging
    prop: str - state/field prop to validate
    criteria: func - called to check prop
    errorMessage: str - error to be shown if !criteria()
  */
  const validate = (prop, criteria, errorMessage) => {
    const message = criteria() ? '' : errorMessage;
    document.getElementById(prop).setCustomValidity(message);
    setErrors({...errors, [prop]: message});
  }

  const handleChange = prop => {
    return e => {
      let val = '';
      try {
        val = e.target.value;
      } catch {
        val = e;
      }
      if (prop === 'inst') {
        validate(
          prop,
          () => Object.values(institutions).map(x => x.name).includes(val),
          "Please select a supported medical institution."
        );
        setState(prev => ({...prev, [prop]: val}))
      } else if (prop === 'disc') {
        validate(
          prop,
          () => Object.values(disciplines).map(x => x.name).includes(val),
          "Please select a supported discipline."
        );
        setState(prev => ({...prev, [prop]: val}))
      } else {
        setState(prev => ({...prev, [prop]: val}))
      }
    }
  }

  const handlePrecepChange = i => {
    return (e) => {
      validate(
        `npi_${i}`,
        () => false,
        "Load a preceptor by pressing 'Find' or remove the preceptor"
      )
      setPreceptors(prev => {
        let arr = [...prev];
        arr[i] = {
          ...prev[i],
          npi: e.target.value
        }
        return arr;
      })
    }
  }

  const addPrecep = () => {
    setValidated(false);
    setErrors(prev => ({
      ...prev,
      [`npi_${preceptors.length}`]: "Load a preceptor by pressing 'Find' or remove the preceptor"
    }))
    setPreceptors(prev => {
      let arr = [...prev];
      arr.push({
        npi: '',
        data: null
      })
      return arr;
    })
  }

  const removePrecep = (i) => {
    return () => {
      setValidated(false);
      setPreceptors(prev => {
        let arr = [...prev];
        arr.splice(i, 1);
        return arr;
      })
    }
  } 

  const handlePrecepDataChange = i => {
    return data => {
      validate(
        `npi_${i}`,
        () => data !== null,
        "Load a preceptor by pressing 'Find' or remove the preceptor"
      )
      setPreceptors(prev => {
        let arr = [...prev];
        arr[i] = {...prev[i], data: data}
        return arr;
      })
    }
  }

  const checkValidity = () => {
    let valid = true;
    const instList = Object.values(institutions).map(x => x.name);
    if (!instList.includes(state.inst)) {
      valid = false;
      validate('inst', () => false, "Please select a supported medical institution.")
    }
    const discList = Object.values(disciplines).map(x => x.name);
    if (!discList.includes(state.disc)) {
      valid = false;
      validate('disc', () => false, "Please select a discipline.")
    }

    preceptors.forEach((prec, i) => {
      if (!prec.data) {
        valid = false;
        validate(`npi_${i}`, () => false, "Load a preceptor by pressing 'Find' or remove the preceptor");
      }
    })

    // make sure there are no duplicate preceptors entered
    let npis = [];
    let hasDuplicates = false;
    let errorIndex = 0;
    console.log("THE HECK")
    preceptors.forEach((prec, i) => {
      if (npis.includes(prec.npi)) {
        console.log("has duplicates");
        hasDuplicates = true;
        errorIndex = i;
      }
      npis.push(prec.npi);
      console.log("NPIS", npis);
    })
    if (hasDuplicates) {
      valid = false;
      validate(`npi_${errorIndex}`, () => false, "Make sure you add each preceptor only once.")
    }

    return valid;
  }

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.currentTarget;
    const valid = form.checkValidity() && checkValidity();
    if (!valid) {
      e.stopPropagation();
    } else {
      console.log("SUBMITTING");
      // submitting
      setSubmitting(true);
      // 
      const addNewRotation = httpsCallable(fireFuncs, 'addNewRotation');
      addNewRotation({
        inst: state.inst,
        disc: state.disc,
        preceps: preceptors
      }).then((result) => {
        console.log("RESULT", result.data);
        onSubmit(result.data.rotId, result.data.precepIds);
        setSubmitting(false);
      }).catch(error => {
        setSubmitting(false);
        console.error("Error adding new rotation", error)
      });
    }
    setValidated(true);
  }

  const openModal = (modal) => {
    return () => {
      setModal(prev => ({...prev, [modal]: {show: true}}));
    }
  }

  return <>
    <Form
      noValidate
      onSubmit={handleSubmit}
      validated={validated}
    >
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Institution</Form.Label>
          <div style={{position: 'relative'}}>
            <SuggestionSearch
              required
              options={sortedInst}
              value={state.inst}
              onChange={handleChange('inst')}
              noOptionsText={
                <>Sorry, we don't support this institution. <a className={appStyles.link} onMouseDown={openModal('instForm')}>Submit an add request</a></>
              }
              placeholder="Loma Linda Medical Center"
              id='inst'
            />
          </div>
          <Form.Control.Feedback type='invalid'>{errors.inst}</Form.Control.Feedback>
          <Form.Text>See our supported <a className={appStyles.link} onClick={openModal('institutions')}>institutions.</a></Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Discipline</Form.Label>
          <div style={{position: 'relative'}}>
            <SuggestionSearch
              required
              options={sortedDisc}
              value={state.disc}
              onChange={handleChange('disc')}
              noOptionsText={
                <>Sorry, we don't support this discipline. <a className={appStyles.link} onMouseDown={openModal('discForm')}>Submit an add request</a></>
              }
              placeholder="Ophthalmology"
              id='disc'
            />
          </div>
        <Form.Control.Feedback type='invalid'>{errors.disc}</Form.Control.Feedback>
        <Form.Text>See our supported <a className={appStyles.link} onClick={openModal('disciplines')}>disciplines.</a></Form.Text>
      </Form.Group>
      {<div>
        {(preceptors).map((pState, i) => (
          <div key={i}>
            <NpiLookup
              state={pState}
              handleChange={handlePrecepChange(i)}
              errors={errors}
              changeData={handlePrecepDataChange(i)}
              removePrecep={removePrecep(i)}
              addPrecep={addPrecep}
              isLast={i === preceptors.length - 1}
              i={i}
              npi={i === 0 ? npi : undefined}
            />
          </div>
        ))}
      </div>}
      {preceptors.length === 0 && <Button
        variant="secondary"
        className={styles.addPreceptor}
        onClick={addPrecep}
      >
        Add Preceptor
      </Button>}
      <div className={styles.btnWrapper}>
        <Button
          variant="secondary"
          type="submit"
          className={appStyles.primaryBtn}
        >
          {submitting ? <Spinner animation="border" size='sm' /> : "Submit"}
        </Button>
      </div>
    </Form>
    {modal.institutions.show && <InstitutionsModal
      show={modal.institutions.show}
      onHide={() => setModal(prev => ({...prev, institutions: {show: false}}))}
      openForm={() => setModal(prev => ({...prev, institutions: {show: false}, instForm: {show: true}}))}
    />}
    {modal.instForm.show && <InstFormModal
      show={modal.instForm.show}
      onHide={() => setModal(prev => ({...prev, instForm: {show: false}}))}
    />}
    {modal.disciplines.show && <DisciplinesModal
      show={modal.disciplines.show}
      onHide={() => setModal(prev => ({...prev, disciplines: {show: false}}))}
      openForm={() => setModal(prev => ({...prev, disciplines: {show: false}, discForm: {show: true}}))}
    />}
    {modal.discForm.show && <DiscFormModal
      show={modal.discForm.show}
      onHide={() => setModal(prev => ({...prev, discForm: {show: false}}))}
    />}
  </>
};