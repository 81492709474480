/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  InstFormModal.module.css
*/
import { doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import uuid from "react-uuid";
import { fireStore } from "../../../../Fire";
import { sleep } from "../../../../resources/Functions";
import appStyles from './../../../../AppStyles.module.css';

export default function InstFormModal(props) {
  const { show, onHide, docID } = props;
  const [intShow, setIntShow] = useState(show);
  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState({
    name: '',
    url: '',
    email: '',
  });
  const [errors, setErrors] = useState({
    name: "Must enter institution name",
    url: "Must enter website for institution",
  });

  useEffect(() => {
    setIntShow(show);
  }, [show]);

  const handleHide = () => {
    setIntShow(false);
    sleep(500).then(() => onHide());
  };

  /* 
    used to validate all form fields and handle error messaging
    prop: str - state/field prop to validate
    criteria: func - called to check prop
    errorMessage: str - error to be shown if !criteria()
  */
  const validate = (prop, criteria, errorMessage) => {
    const message = criteria() ? '' : errorMessage;
    document.getElementById(prop).setCustomValidity(message);
    setErrors({...errors, [prop]: message});
  }

  const handleChange = prop => {
    return e => {
      if (prop === 'text') {
        if (/^.*$/g.test(e.target.value)) {
          validate(
            prop,
            () => /^[a-zA-Z]*$/g.test(e.target.value),
            "Text must have only letters"
          );
          setState(prev => ({...prev, [prop]: e.target.value}))
        }
      } else {
        setState(prev => ({...prev, [prop]: e.target.value}))
      }
    }
  }

  const handleCheckChange = (prop) => {
    setState({ ...state, [prop]: !state[prop] });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    } else {
      // submitting
      setSubmitting(true);
      const id = uuid();
      let obj = {
        name: state.name,
        url: state.url,
        id: id,
        date: new Date(Date.now()).toLocaleDateString()
      };
      if (state.email.length > 0) obj.email = state.email;
      setDoc(doc(fireStore, 'institutionRequests', id), obj).then(() => {
        setSubmitting(false);
        handleHide();
      })
    }
    setValidated(true);
  }

  return <>
    <Modal
      show={intShow}
      onHide={handleHide}
      centered
      style={{color: 'black'}}
    >
      <Modal.Header closeButton>
        <Modal.Title>Institution Support Request</Modal.Title>
      </Modal.Header>
      <Form
        noValidate
        onSubmit={handleSubmit}
        validated={validated}
      >
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Institution Name</Form.Label>
            <Form.Control 
              required
              value={state.text}
              onChange={handleChange('name')}
              type="text" 
              id="name"
              placeholder="Loma Linda University Medical Center" 
            />
            <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Institution Website</Form.Label>
            <Form.Control 
              required
              value={state.url}
              onChange={handleChange('url')}
              type="text" 
              id="url"
              placeholder="https://lluh.org/" 
            />
            <Form.Control.Feedback type='invalid'>{errors.url}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Notification email</Form.Label>
            <Form.Control 
              value={state.email}
              onChange={handleChange('email')}
              type="text"
              id="email"
              placeholder="jane.doe@gmail.com" 
            />
            <Form.Text muted>Enter your own email if you would like to be notified when we add support for this institution.</Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="submit" className={appStyles.primaryBtn}>
            {submitting ? <Spinner animation="border" size="sm" /> : 'Submit'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  </>
};