/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  AuthPage.js
*/

import React, { useContext, useEffect, useState } from 'react';
import styles from './AuthPage.module.css';
import LogIn from './components/log-in/LogIn';
import CreateAccount from './components/create-account/CreateAccount';
import NavBar from '../../nav-bar/NavBar';
import { Navigate, useLocation } from 'react-router-dom';
import VerifyEmail from './components/verifyEmail/VerifyEmail';
import { AuthContext } from '../../../Auth';

const DEV = window.location.hostname === 'localhost';
// const DEV = false;

export default function AuthPage(props) {
  const user = useContext(AuthContext);
  const location = useLocation();
  const donePath = (location.state || {}).donePath;
  // 'login', 'create', or 'verify'
  const [mode, setMode] = useState((location.state || {}).mode || 'create');
  const [redirect, setRedirect] = useState({
    go: false,
    path: donePath || '/',
    state: location.state || {}
  })

  const handleDone = () => {
    setRedirect(prev => ({
      ...prev,
      go: true
    }))
  }

  useEffect(() => {
    const modeIsVerify = (location.state || {}).mode === 'verify';
    if (modeIsVerify && !user) {
      setMode('login');
    } 
    // show verify screen is email isn't verified yet
    if (user && !(user || {}).emailVerified && !DEV && mode !== 'verify') {
      // setMode('verify');
    }
    if ((user && (user || {}).emailVerified) || user && DEV) {
      setRedirect({
        go: true,
        path: '/'
      })
    }
  }, [user, mode]);

  if (redirect.go) return <Navigate to={redirect.path} state={redirect.state} />

  return <>
    <NavBar />
    <div className={styles.main}>
      <div className={styles.content}>
        <div className={styles.header}>
          <img className={styles.brand} src={'./branding/brand.svg'} />
          {mode !== 'verify' && <p className={styles.welcome}>
            Welcome! {mode === 'login' ? 'Log in' : 'Create an account'} to get started.
          </p>}
        </div>
        <div className={styles.authContainer}>
          {mode === 'login' && <LogIn
            handleSwitch={() => setMode('create')}
            handleDone={handleDone}
          />}
          {mode === 'create' && <CreateAccount
            handleSwitch={() => setMode('login')}
            handleDone={handleDone}
            setMode={setMode}
          />}
          {mode === 'verify' && <VerifyEmail 
            handleDone={handleDone}
          />}
        </div>
      </div>
    </div>
  </>
};