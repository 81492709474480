/*
  This code created by Luke Irvine Developments
  
  Copyright 2022. All Rights Reserved.
  
  Created By: Luke Irvine
  
  Loading.js
*/
import React from "react";
import Spinner from "react-bootstrap/Spinner";
import NavBar from "../../nav-bar/NavBar";
import styles from './Loading.module.css';

const Loading = (props) => {
  return (
    <>
      <div data-testid="loading" className={styles.authSpinnerContainer}>
        <div className={styles.logo}>
          <div className={styles.outer}>
            <div className={styles.semi2} />
            <div className={styles.semi1} />
            <div className={styles.cover0} />
            <div className={styles.cover2} />
            <div className={styles.cover1} />
            <div className={styles.moat}>
              <div className={styles.inner}>
                <div className={styles.target} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loading;
