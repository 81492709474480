/*
  This code created by Luke Irvine Developments
  
  Copyright 2023. All Rights Reserved.
  
  Created By: Luke Irvine
  
  StarsForm.js
*/

import React, { useEffect, useState } from 'react';
import Star from '../Star';
import styles from './StarsForm.module.css';

const ratings = [
  'Select your rating',
  'Not good',
  'Could\'ve been better',
  'OK',
  'Good',
  'Great'
];

const colors = [
  '#d0dedc',
  '#e26d19',
  '#e2b319',
  '#e2d119',
  '#a9e219',
  '#26e219'
]

export default function StarsForm(props) {
  const {
    value,
    onChange,
    id
  } = props;
  const [hoverVal, setHoverVal] = useState(value);

  const stars = [1, 2, 3, 4, 5];

  function handleMouseEnter(val) {
    return () => setHoverVal(val);
  }

  function handleClick(val) {
    return () => onChange(val);
  }

  function handleMouseLeave() {
    setHoverVal(value);
  }

  return <>
    <div className={styles.container} id={id}>
      <div 
        className={styles.starsContainer}
        onMouseLeave={handleMouseLeave}
      >
        {stars.map(index => (
          <button 
            type="button" 
            key={index} 
            className={styles.starWrapper}
            style={{backgroundColor: index <= hoverVal ? colors[hoverVal] : colors[0]}}
            onMouseEnter={handleMouseEnter(index)}
            onClick={handleClick(index)}
          >
            <Star color='white' scale={1.2}/>
          </button>
        ))}
      </div>
      <p className={styles.rating}>{ratings[hoverVal]}</p>
    </div>
  </>
};